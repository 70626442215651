import React, { useState, useEffect } from 'react';
import WalletUsersTableHeader from './WalletUsersTableHeader';
import WalletUserRow from './WalletUserRow';
import styled from "styled-components";
import { TopupsEmptyStateIcon } from 'common/EmptyStateIcons';
import EmptyState from 'common/EmptyState';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from "lodash";

const RowContainer = styled.div`
	cursor: pointer;
	user-select: none;
`;

const AnimatedRowContainer = styled.div`
  cursor: pointer;
  user-select: none;
  animation: blink 1s linear 5;
  -webkit-animation: blink 1s linear 5;

  @keyframes blink {
		25% {
			opacity: 0.5;
		} 
		50% {
			opacity: 0;
		} 
		75% {
			opacity: 0.5;
	}
  }
`;

const TableWrapper = styled.div`
	max-height: 1040px;
	overflow-y: auto;
`;

const WalletUsersTable = ({topups, selectedTopup, setSelectedTopup, incomingTopupsIds, notOpenedIncomingTopupsIds, setNotOpenedIncomingTopupsIds, setIsOpenHistory}) => {
	const {t} = useTranslation();
	const [sort, setSort] = useState(null)
	const [field, setField] = useState(null)
	const [sortedTopups, setSortedTopups] = useState([]);

	const handleSelect = (topup) => {
		const updatedNotOpenedIds = notOpenedIncomingTopupsIds.filter(n_i => n_i !== topup.last_recharge_id)
		setSelectedTopup(topup)
		setNotOpenedIncomingTopupsIds(updatedNotOpenedIds)
		setIsOpenHistory(true)
	};

	useEffect(() => {
		
		const sortTable = () => {
			if(topups && topups.length > 0){
				const topupsClone = cloneDeep(topups)

				const sortedData = topupsClone.sort((a, b) => {
					if (sort === 'ASC') {
						return a[field] - b[field];
					} else if (sort === 'DESC') {
						return b[field] - a[field];
					}
					return 0;
				});
				setSortedTopups(sortedData);
				setSelectedTopup(null)
			} else {
				setSortedTopups(topups);
			}
		};

		sortTable()

	}, [sort, field, topups])


	return (
		<div>
			<WalletUsersTableHeader sort={sort} setSort={setSort} field={field} setField={setField} opacity={Boolean(topups?.length === 0)} />
			{sortedTopups?.length > 0 ?
				<TableWrapper>
					{sortedTopups?.map(topup =>
					incomingTopupsIds.includes(topup.last_recharge_id) ?
						<AnimatedRowContainer key={'order_' + topup.last_recharge_id} onClick={() => handleSelect(topup)}>
							<WalletUserRow field={field} topup={topup} selected={selectedTopup?.last_recharge_id === topup.last_recharge_id} isNotOpened={notOpenedIncomingTopupsIds.includes(topup.last_recharge_id)}/>
						</AnimatedRowContainer>
					:
						<RowContainer key={'order_' + topup.last_recharge_id} onClick={() => handleSelect(topup)}>
							<WalletUserRow field={field} topup={topup} selected={selectedTopup?.last_recharge_id === topup.last_recharge_id} isNotOpened={notOpenedIncomingTopupsIds.includes(topup.last_recharge_id)}/>
						</RowContainer>
					)}
				</TableWrapper>
			:
				<TableWrapper>
					<EmptyState text={t('no_users_with_credit_yet')} icon={<TopupsEmptyStateIcon />} />
				</TableWrapper>
			}
		</div>
  );
};
export default WalletUsersTable;
