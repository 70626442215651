import React, { useEffect, useState } from 'react';
import { TPVS } from '../DragAndDrop/constants';
import { Trans, useTranslation } from 'react-i18next';
import { InfoTooltip } from 'common/InfoTooltip';
import { BUTTON_VARIANTS, Button } from 'common/Button';
import { PlusCircleIcon } from '@heroicons/react/solid';
import { TextInput } from 'common/Input';
import { orderAndPay } from 'logic/api';
import { Icon, IconType } from 'common/Icon';
import { DeletePopup } from './DeletePopup';
import { toast } from 'react-toastify';

export const ModifierCategories = ({ modifierCategories, modifiers, selectedRestaurantId, selectedCategory, tpv, setSelectedCategory, mutateCat }) => {
  const { t } = useTranslation();

  const [categoryToEdit, setCategoryToEdit] = useState(null);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  
  const newCat = {
    name: '',
    custom_made: true,
    restaurant: selectedRestaurantId,
    tpv_id: `yumminn_${modifierCategories.length + 1}`,
  };
  
  const isTpvYumminn = tpv.includes(TPVS.YUMMINN);
  
  const editCategory = (event, cat) => {
    event.stopPropagation();
    cat?.id && setSelectedCategory(cat.id);
    setCategoryToEdit(cat);
  };

  const changeCatName = event => setCategoryToEdit(prev => ({...prev, name: event.target.value}));

  const saveCatName = () => {
    orderAndPay[categoryToEdit.id ? 'patch' : 'post']('/modifier_category', categoryToEdit).then(res => {
      const newCategories = [...modifierCategories];

      if (categoryToEdit.id) {
        const index = newCategories.findIndex(cat => cat.id === categoryToEdit.id);
        
        newCategories[index] = categoryToEdit;
      } else {
        newCategories.push({...categoryToEdit, id: res.data.id});
      }

      mutateCat({ modifier_categories: newCategories, tpv });
      toast.success(t('modifier_category_updated'));
      setCategoryToEdit(null);
    });
  };
  
  const deleteCat = async cat => {
    if (!isTpvYumminn || modifiers.length) return;

    const data = { id: cat.id };

    const res = await orderAndPay.delete('/modifier_category', { data });

    if (res.status === 200) {
      const newCategories = [...modifierCategories];
      const index = newCategories.findIndex(c => c.id === cat.id);

      if (index !== -1) {
        newCategories.splice(index, 1);

        mutateCat({ modifier_categories: newCategories, tpv });
      } else {
        mutateCat();
      };

      setCategoryToDelete(null);
      toast.success(t('modifier_category_deleted'));
    }
  };

  useEffect(() => {
    const li = document.getElementById(`cat_name_${categoryToEdit?.id || 'new'}`);
    const input = document.getElementById(`cat_name_input_${categoryToEdit?.id || 'new'}`);

    const handleClick = event => {
      if (!li?.contains(event.target)) {
        setCategoryToEdit(null);
        window.removeEventListener('click', handleClick);
      }
    };

    if (li && input) {
      li.scrollIntoView({behavior: 'smooth', inline: 'center'});
      input.focus();

      window.addEventListener('click', handleClick);
    }

    return () => window.removeEventListener('click', handleClick);
  }, [selectedRestaurantId, categoryToEdit]);

  return (
    <section>
      <header>
        <h2>
          <span>{t('modifier_group')}</span>
          <InfoTooltip>
            <p>
              <Trans i18nKey='info_modifier_group'>
                <b>Crea grupos</b> de modificadores para <b>organizar las opciones de tus productos</b>. Por ejemplo: <i>'Punto de la carne'</i> o <i>'Extras'</i>.
              </Trans>
            </p>
          </InfoTooltip>
        </h2>
        {isTpvYumminn &&
          <Button
            label={'add_new_m'}
            onClick={e => editCategory(e, newCat)}
            leftIcon={<PlusCircleIcon/>}
            variant={BUTTON_VARIANTS.SECONDARY}
          />
        }
      </header>
      <ul>
        {isTpvYumminn && !modifierCategories.length && !categoryToEdit &&
          <Button
            rightIcon={<PlusCircleIcon/>}
            onClick={e => editCategory(e, newCat)}
          />
        }
        {modifierCategories.map(cat => {
          if (cat.id === categoryToEdit?.id) return (
            <li key={cat.id} id={`cat_name_${cat.id}`}>
              <TextInput
                name={`cat_name_input_${cat.id}`}
                value={categoryToEdit?.name}
                placeholder={categoryToEdit?.tpv_name}
                onChange={changeCatName}
              />
              <Button
                label='save'
                onClick={saveCatName}
              />
              {isTpvYumminn &&
                <Icon type={IconType.TRASH} onClick={() => setCategoryToDelete(cat)}/>
              }
            </li>
          );

          return (
            <li
              key={cat.id}
              onClick={() => setSelectedCategory(cat.id)}
              className={`${selectedCategory === cat.id ? 'selected' : ''} ${!!categoryToEdit ? 'disabled' : ''}`}
            >
              {cat.visible_name || cat.name || cat.tpv_name}
              <Icon
                type={IconType.PENCIL}
                onClick={e => editCategory(e, cat)}
                color='currentColor'
              />
            </li>
          );
        })}
        {(categoryToEdit && !categoryToEdit.id) &&
          <li id={`cat_name_new`}>
            <TextInput
              name={`cat_name_input_new`}
              value={categoryToEdit?.name}
              placeholder={categoryToEdit?.tpv_name}
              onChange={changeCatName}
            />
            <Button
              label='save'
              onClick={saveCatName}
            />
            <Icon type={IconType.TRASH} onClick={() => setCategoryToEdit(null)}/>
          </li>
        }
        <DeletePopup
          isOpen={!!categoryToDelete}
          close={() => setCategoryToDelete(null)}
          title={'delete_modifier_category'}
          message={modifiers.length ? 'delete_modifier_category_explanation' : 'confirm_delete_modifier_category'}
          onCancel={!modifiers.length && (() => setCategoryToDelete(null))}
          onConfirm={() => modifiers.length ? setCategoryToDelete(null) : deleteCat(categoryToDelete)}
        />
      </ul>
    </section>
  );
};
