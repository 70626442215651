import React, { useState, useEffect, useContext } from "react"
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ChevronUpIcon } from '@heroicons/react/outline'
import { bottomRoutes, routes } from "./AdminRoutes"
import { YumminnIconWithName } from 'common/YumminnIconWithName'
import { admin_routes } from "./SuperAdminRoutes"
import styled from "styled-components"
import { waiter_routes } from "./WaiterRoutes"
import { NavigatorContext } from 'data/navigatorContext';

const pathIsInChildren = (button) => {
	return button.children && button.children.some(element => window.location.pathname === element.path || (element.children && element.children.some(subelement => window.location.pathname === subelement.path)))
}

const NavButton = ({ button, sideBarOpen, isSubMenu, onNavButtonClick, openSubMenuIndex, index, setOpenSubMenuParent, openSubMenuParent }) => {
	const isActive = window.location.pathname === button.path || pathIsInChildren(button)
	const history = useHistory()
	const [openSubMenu, setOpenSubMenu] = useState(openSubMenuParent)
	const handleClick = () => {
		setOpenSubMenuParent && setOpenSubMenuParent(!openSubMenuParent)
		if (button.children) {
			onNavButtonClick && onNavButtonClick(index);
		} else {
			if (button.onClick) {
				button.onClick();
			} else {
				history.push(button.path);
			}
		}
	}

	const [isThisSubMenuOpen, setIsThisSubMenuOpen] = useState(openSubMenuIndex === index)

	useEffect(() => {
		setIsThisSubMenuOpen(openSubMenuIndex === index)
		if (openSubMenuIndex !== index) setOpenSubMenu(false)
	}, [openSubMenuIndex])

	useEffect(() => {
		setOpenSubMenu(openSubMenuParent)
	}, [openSubMenuParent])

	return (
		<div className={`${(!isSubMenu && index) ? 'mt-4' : ''} ${(isThisSubMenuOpen || isActive) && sideBarOpen && 'bg-[#205763] rounded-md w-full'}`}>
			<button
				onClick={handleClick}
				className={`${isActive && !isSubMenu ? 'bg-principal hover:bg-[#62bf99]' : 'hover:bg-[#416670]'
					} ${isThisSubMenuOpen && !isSubMenu && !isActive ? 'bg-[#205763]' : ''}
			  ${isSubMenu && isActive ? 'bg-[#356771] hover:bg-[#356771] rounded-md' : ''}
			  ${!isSubMenu ? ' rounded-md h-11 px-5 py-3' : 'bg-[#205763] p-2 h-9 '}
			  rounded-md group text-xs space-x-3 z-50 w-full text-white focus:outline-none
			  flex flex-row items-center justify-between cursor-pointer relative text-left font-semibold disabled:text-gray-200`}
			>
				<div className="min-h-6 min-w-6 flex justify-center items-center absolute">{button.icon}</div>
				<div className={`${!sideBarOpen ? 'hidden' : 'inherit'} flex flex-row items-center justify-between py-3 w-full`}>

					<span className={`transition-all delay-200 min-w-[140px] whitespace-normal capitalize_first`} style={{ paddingLeft: button.level ? '50px' : isSubMenu ? '40px' : '30px', whiteSpace: 'wrap' }}>
						{button.title}
					</span>
					{button.children && <ChevronUpIcon height={20} className={`${(openSubMenu && sideBarOpen) || (isThisSubMenuOpen && sideBarOpen) ? 'rotate-0' : 'rotate-180'}  transform transition-transform`} />}
				</div>
			</button>

			{button.children &&
				<div className={` z-10 ${(openSubMenu && sideBarOpen) || (isThisSubMenuOpen && sideBarOpen) ? 'h-min' : 'h-0 opacity-0 hidden'} `}>
					{button.children.map((button, childIndex) => {
						return <NavButton key={childIndex + 'sub'}
							button={button}
							sideBarOpen={sideBarOpen}
							isSubMenu={true}
							openSubMenuIndex={openSubMenuIndex}
							setOpenSubMenuParent={setOpenSubMenu}
							openSubMenuParent={openSubMenu}
							index={childIndex + 'sub'}
						/>
					})
					}
				</div>
			}
		</div>
	)
}


const NavigationComponent = ({ open, setOpen }) => {
	const { t } = useTranslation()
	const { setOpen : setOpenFromContext } = useContext(NavigatorContext);

	const history = useHistory()
	const user = JSON.parse(localStorage.getItem("yumminn-user"))
	
	const [openSubMenuIndex, setOpenSubMenuIndex] = useState(null); // State to track open submenu
	
	const handleNavButtonClick = (index) => {
		if (openSubMenuIndex === index) {
			setOpenSubMenuIndex(null)
		} else {
			setOpenSubMenuIndex(index); // Open the clicked submenu
		}
	};

	const handleLogout = () => {
		localStorage.removeItem('yumminn-user')
		localStorage.removeItem('yumminn-restaurants')
		localStorage.removeItem('yumminn-tokens')
		localStorage.removeItem('yumminn-path')
		localStorage.removeItem('yumminn-selectedRestaurant')
		localStorage.removeItem('yumminn-selectedCompany')
		sessionStorage.removeItem('yumminn-selectedYear')
		sessionStorage.removeItem('yumminn-selectedYearTo')
		sessionStorage.removeItem('yumminn-selectedMonth')
		sessionStorage.removeItem('yumminn-selectedMonthTo')
		sessionStorage.removeItem('yumminn-selectedDate')
		sessionStorage.removeItem('yumminn-selectedDateTo')
		sessionStorage.removeItem('yumminn-selectedStartTime')
		sessionStorage.removeItem('yumminn-selectedEndTime')
		localStorage.removeItem('token')
		localStorage.removeItem('tabs')
		sessionStorage.removeItem('yumminn-selectedReviewFilter')
		history.push('/')
	}


	const tabs = JSON.parse(localStorage.getItem('tabs'))

	const buttons = user.is_superuser ? admin_routes(t, tabs) : user.rol !== "waiter" ? routes(t, tabs) : waiter_routes(t, tabs)
	const lastButtons = bottomRoutes({ handleLogout: handleLogout, t: t })

	if(!user) return <></>

	return (
		<div className="flex sticky top-0 overflow-hidden ">
			<div
				onMouseOver={() => {setOpen(true); setOpenFromContext(true)}}
				onMouseOut={() => {setOpen(false); setOpenFromContext(false)}}
				className={`${open ? 'w-60' : 'w-[80px]'
					} flex flex-col h-screen bg-[#094553] duration-300 items-center pt-6`}

			>
				<div className={`flex items-center relative transform transition-all delay-[810ms] justify-center pl-3 pb-4 w-44 m-auto`}>
					<YumminnIconWithName />
				</div>
				<NoScrollBar className="no-scrollbar space-y-3 items-center w-full h-full overflow-scroll">

					<div className=" p-2 pt-0 flex flex-col justify-between h-full max-h-full overflow-hidden">

						<div >
							{buttons.map((button, index) => {
								return <NavButton key={index}
									button={button}
									sideBarOpen={open}
									openSubMenuIndex={openSubMenuIndex}
									onNavButtonClick={handleNavButtonClick}
									isParentSubMenuOpen={true}
									index={index}
								/>
							})}
						</div>

					</div>
					<div className="p-2 mb-16 sticky bottom-0 flex flex-col justify-between bg-[#094553] h-max w-full z-9999">
						<hr />
						{lastButtons.map((button, index) => {
							return <NavButton key={index + buttons.length}
								button={button}
								sideBarOpen={open}
								openSubMenuIndex={openSubMenuIndex}
								onNavButtonClick={handleNavButtonClick}
								isParentSubMenuOpen={true}
								index={index + buttons.length}
							/>
						})}
					</div>
					<div className="bg-[#094553] w-5 h-10 absolute top-2 right-0"></div>
				</NoScrollBar>
			</div>
		</div>
	)
}


const NoScrollBar = styled.div`
	::-webkit-scrollbar {
		width: 0;
		
	}
	-ms-overflow-style: none;
	scrollbar-width: none;
`

export default NavigationComponent