import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import { Context as AuthContext } from '../../data/authContext';
import Spinner from "../../common/Spinner";
import { baseAuth } from '../../logic/api';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';



const Agents = () => {  

  const {t} = useTranslation();
  const {
    state: {
      selectedAgentsRestaurants,
      selectedAgentsPeriod,
      selectedAgentsInterval
    }
  } = React.useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [agents, setAgents] = useState([]);
  const [initialHour, setInitialHour] = useState(null);
  const [initialMinute, setInitialMinute] = useState(null);

  useEffect(async () => {
    console.log(selectedAgentsRestaurants, "selectedAgentsRestaurants");
    console.log(selectedAgentsPeriod, "selectedAgentsPeriod");
    console.log(selectedAgentsInterval, "selectedAgentsInterval");
    if (selectedAgentsRestaurants == null || selectedAgentsPeriod == null || selectedAgentsInterval == null) {
      return;
    }
    setLoading(true);
    try {
      const restaurantsIds = [];
      if (selectedAgentsRestaurants?.length > 0) {
        selectedAgentsRestaurants.forEach(restaurant => {
          restaurantsIds.push(restaurant.value);
        });
      }
      const restaurantsIdsJSON = JSON.stringify(restaurantsIds);
      const url = `/tunnel/status?restaurants=${restaurantsIdsJSON}`;
      const response = await baseAuth.get(url);
      if (response?.status == 200 && response?.data) {
        setAgents(response.data.agents);
        //console.log(response.data.initial_hour, "initial_hour")
        //console.log(response.data.initial_minute, "initial_minute")
        setInitialHour(response.data.initial_hour);
        setInitialMinute(response.data.initial_minute);
      }
    } catch(e) {
      console.log(e.toString(), "<- tunnel/status exception (Agents.jsx)");
    } finally {
      setLoading(false);
    }
  }, [selectedAgentsRestaurants, selectedAgentsPeriod, selectedAgentsInterval]);

  if (loading) {
    return <Spinner />;
  }

  if (!agents || Object.keys(agents).length == 0 || initialHour == null || initialMinute == null) {
    return <p style={{textAlign: "center", marginTop: 40, fontSize: 17}}><b>{t('No agents to show')}</b></p>;
  }

  console.log(agents, "agents")

  const restaurantIds = [];
  for (let restaurantId in agents) {
    restaurantIds.push(restaurantId);
  }

  //#653d79
  const integrationToColor = {
    "BDP": "#7c6382",
    "Agora": "#3397db"
  }

  const versionNumberToText = versionNumber => {
    const versionStr = versionNumber.toString();
    const offset = versionStr.length > 7 ? 2 : 1;
    
    const major = versionStr.substring(0, offset);

    let minor = versionStr.substring(offset, offset + 2);
    minor = minor.startsWith("0") ? minor.substring(1) : minor;
    
    let patch = versionStr.substring(offset + 2, offset + 4);
    patch = patch.startsWith("0") ? patch.substring(1) : patch;
    
    let revision = versionStr.substring(offset + 4, offset + 6);
    revision = revision.startsWith("0") ? revision.substring(1) : revision;
    
    return `${major}.${minor}.${patch}`;
  }

  const agentsDivs = restaurantIds.map((restaurantId, index) => {
    const agent = agents[restaurantId];
    const bars = agent["bars"];
    const data = [];
    let currentHour = initialHour;
    let currentMinute = initialMinute;
    bars.forEach(state => {
      //currentMinute += parseInt(selectedAgentsInterval);
      currentMinute += 10;
      while (currentMinute >= 60) {
        currentMinute -= 60;
        currentHour++;
        if (currentHour >= 24) {
          currentHour -= 24;
        }
      }
      //console.log(currentHour, "currentHour")
      //console.log(currentMinute, "currentMinute")
      let currentHourStr = currentHour.toString();
      currentHourStr = currentHourStr.length < 2 ? ("0" + currentHourStr) : currentHourStr;
      let currentMinuteStr = currentMinute.toString();
      currentMinuteStr = currentMinuteStr.length < 2 ? ("0" + currentMinuteStr) : currentMinuteStr;
      const name = `${currentHourStr}:${currentMinuteStr}`;
      data.push({
        name,
        Connected: state == 1 ? 1 : 0,
        Active: state == 2 ? 1 : 0,
        "Not communicating with tunnel": state == 3 ? 1 : 0
      });
    });

    console.log(data, `data (restaurantId=${restaurantId}`);

    const integration = agent["integration"];

    return (
      <div key={index} style={{height: 100, marginBottom: 50}}>
        <div style={{display: "flex"}}>
          <p style={{marginLeft: 19}}><b>{agent["restaurant_name"]}</b></p>
          <div style={{marginLeft: 10, backgroundColor: integrationToColor[integration], border: "1px solid black", borderRadius: 8, alignSelf: "center", padding: 2}}>
            <p style={{fontSize: 13, color: "white", marginLeft: 3, marginRight: 3, fontWeight: 500}}>{integration}</p>
          </div>
          <div style={{marginLeft: 8, border: "1px solid grey", borderRadius: 8, alignSelf: "center", padding: 2}}>
            <p style={{fontSize: 12, marginLeft: 3, marginRight: 3, fontWeight: 500, color: "grey"}}>Version: {versionNumberToText(agent["agent_version"])}</p>
          </div>
        </div>
        <ResponsiveContainer width="100%" height="88%">
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <Tooltip />
            <Bar dataKey="Connected" stackId="a" fill="#5fb894" />
            <Bar dataKey="Active" stackId="a" fill="#6bc7a2" />
            <Bar dataKey="Not communicating with tunnel" stackId="a" fill="#f5b802" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  });

  return <div style={{marginLeft: 20, marginRight: 20}}>
    {agentsDivs}
  </div>
}

export default Agents;
