import Select, { components } from 'react-select';
import React, { useState, useEffect } from "react";
import { categories_select_styles } from 'logic/defaults';
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { Context as AuthContext } from '../../data/authContext';
import { baseAuth } from "logic/api";

export const CategorySelect = () => {
    const {
		state: { selectedRestaurantId, selectedDate, selectedDateTo },
    setTopProducts,
    setSelectedProductCategories,
	} = React.useContext(AuthContext);

	const { t } = useTranslation();

  const [productCategories, setProductCategories] = useState([]);
	const [selectedCategories, setSelectedCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
	
    const handleChangeOption = (value) => {
        const productCategoryId = value[0].value
        const foundCategory = selectedCategories.find((p_c) => p_c.id === productCategoryId)
        let result = null
        if (foundCategory) {
          result = [...selectedCategories];
          const index = result.indexOf(foundCategory);
          result.splice(index, 1);
        } else {
            result = [...selectedCategories];
            let newCategory = {...value[0]}
            delete newCategory["value"]
            delete newCategory["label"]
            result.push(newCategory)
        }
        if (result) {
            setSelectedCategories(result)
            setSelectedProductCategories(result)
        }
    }

    const selectorOptions = productCategories.map((product_category) =>
    ({
        "label": product_category.name ? product_category.name : product_category.tpv_name,
        "value": product_category.id,
        "name": product_category.name,
        "tpv_name": product_category.tpv_name,
        "id": product_category.id,
    }))
    
    const Option = (props) => {
        return (
          <div>
            <components.Option {...props}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <label>{props.label}</label>
              <input
                className="checkboxRestaurantSelect"
                type="checkbox"
                checked={selectedCategories.find((s_c) => s_c.id === props.value)}
                onChange={() => null()}
              />{" "}
              <span></span>
              </div>
            </components.Option>
          </div>
        );
      };

    useEffect(() => {
		const isCorrectDeployVersion = JSON.parse(sessionStorage.getItem('correct_deploy_version'))

		if (isCorrectDeployVersion != null && !isCorrectDeployVersion) {
			toast.error(`${t('Error_deploy_version')}`);
			sessionStorage.setItem('correct_deploy_version', true)
		}
		setProductCategories([])
		if (selectedRestaurantId && selectedDate, selectedDateTo, !isLoading) {
			getData();
		}
	}, [selectedRestaurantId, selectedDate, selectedDateTo])

	const getData = async () => {
    setIsLoading(true);
    let startDate = new Date(selectedDate);
    startDate.setHours( 0,0,0,0 );
    startDate = startDate.getTime();
    let endDate = new Date(selectedDateTo);
    endDate.setHours( 23,59,59,0 );
    endDate = endDate.getTime();

		await baseAuth.get(`/restaurant/top-products?restaurant=${selectedRestaurantId}&from=${startDate}&to=${endDate}`)
			.then((response) => {
				const product_categories = response.data.product_categories
				setProductCategories(product_categories)
        setSelectedProductCategories(product_categories)
        setTopProducts(response.data)
			})
			.catch((e) => {
        console.log(`CategorySelect error: ${e}`)
			})
      .finally(() => setIsLoading(false));
	}
      
    return(
        <div className="flex flex-col" style={{ fontFamily: 'Poppins, sans-serif', fontSize:"16px", zIndex: 9999, fontWeight: 500}}>
            {t('Categorías')}
            <Select
                id={'product_categories'}
                isMulti={true}
                value={""}
                options={selectorOptions}
                styles={categories_select_styles}
                className={'w-80'}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                onChange={(e) => {
                handleChangeOption(e);
                }}
                components={{
                    Option
                }}
                isClearable={false}
            />
        </div>
    )
}



