import Service_icon from "../img/Service_icon.svg";
import Transactions_icon from "../img/Transactions_icon.svg";
import Graphs_icon from "../img/Graphs_icon.svg";
import Reviews_icon from "../img/Reviews_icon.svg";
import Loyalty_icon from "../img/Loyalty_icon.svg";
import Lightbulb_icon from "../img/Lightbulb_icon.svg";
import Logout_icon from "../img/Logout_icon.svg";
import carta from '../img/carta.svg';
import Catalog_icon from "../img/Catalog_icon.svg";
import { Icon, IconType } from "common/Icon";

export const waiter_routes = (t, tabs) => [
    {
        icon: <img src={Transactions_icon} className="h-6 w-6" alt='payment_and_orders'/>,
        title: t('Payments and orders'),
        // path: '/payments',
        children: [
            {
                title: t('Payments'),
                path: '/payments',
            },
            {
                title: t('Orders'),
                path: '/admin/orders',
            }
        ]
    },
    {
        icon: <img src={Service_icon} className="h-6 w-6" alt='service'/>,
        title: t('Service'),
        children: [
            {
                title: t('tables_and_sectors'),
                path: '/settings/tables/sectors',
            },
            {
                title: t('Table_statuses'),
                path: '/settings/tables',
            }
        ],
    },
    {
        icon: <img src={carta} className="h-6 w-6" alt='menu'/>,
        title: t('menu'),
        // path: '/admin/order-and-pay',
        children: [
            {
                title: t('link_and_pdf'),
                path: '/menu',
                route_group: 'menu',
            },
            {
                title: t('products_and_categories'),
                path: '/admin/order-and-pay',
                route_group: 'menu',
            },
            {
                title: t('modifiers'),
                path: '/admin/modifiers',
                route_group: 'menu',
            },
            {
                title: t('highlights_banners'),
                path: '/admin/product-highlight',
                route_group: 'menu',
            },
            {
                title: t('discounts'),
                path: '/discounts',
                route_group: 'menu',
            },
            ...(tabs ? [{
                title: t('translations'),
                route_group: 'menu',
                children: [...tabs.map((tab, index) => {
                    return {
                        title: t(tab.name),
                        path: `/translations/${tab.name}`,
                        level: 3
                    }
                })]
            }]
            :
            []),
        ],
    },
    {
        icon: <img src={Reviews_icon} className="h-6 w-6" alt='ratings'/>,
        title: t('Ratings_and_reviews'),
        // path: '/ratings',
        children: [
            {
                title: t('metrics'),
                path: '/ratings'
            },
            {
                title: t('Reviews'),
                path: '/ratings/reviews'
            }
        ]
    },
    {
        icon: <img src={Loyalty_icon} className="h-6 w-6" alt='Loyalty'/>,
        title: t('Loyalty'),
        // path: '/loyalty',
        children: [
            {
                title: t('Dashboard'),
                path: '/loyalty',
            },
            {
                title: t('Cashback'),
                path: '/loyalty/programs',
            },
            {
                title: t('Crm'),
                path: '/loyalty/mails',
            }
        ],
    },
    {
        icon: <img src={Lightbulb_icon} className="h-6 w-6" alt='solution'/>,
        title: t('my_solution'),
        children: [
            {
                title: t('Customization'),
                path: '/settings/customization',
            },
            {
                title: t('QR_Codes'),
                path: '/qr',
            },
        ],
    }
];


export const bottomRoutes = ({ handleLogout, t }) => [

    {
        icon: <img src={Catalog_icon} className="h-6 w-6" alt='catalog'/>,
        title: t('Restaurant'),
        path: '/settings'
    },
    {
        icon: <Icon type={IconType.VIDEO}/>,
        title: t('tutorials'),
        path: '/tutorials',
    },
    {
        icon: <img src={Logout_icon} className="h-6 w-6" alt='logout'/>,
        title: t('Logout'),
        path: '/logout',
        onClick: () => { handleLogout() }
    },
]
