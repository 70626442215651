import React, { useState, useEffect } from "react";
import { FolderDownloadIcon, EyeIcon, ClockIcon } from '@heroicons/react/outline';
import { useTranslation } from "react-i18next";
import Switch from 'react-switch';
import {  baseAuth } from "logic/api";
import { centsToAmountString, toDecimalString } from "logic/functions";
import { PhotographIcon, StarIcon } from '@heroicons/react/outline'

const bgShades = ['bg-white','bg-white', 'bg-gray-100', 'bg-gray-100', 'bg-gray-200', 'bg-gray-200', 'bg-gray-300', 'bg-gray-300']
const borderShades = ['border-gray-200','border-gray-200', 'border-gray-200', 'border-gray-200', 'border-gray-100', 'border-gray-100', 'border-gray-1200', 'border-gray-1200']

const rowStyle = () => ({
	userSelect: "none",
	display: 'grid',
	alignContent: 'center',
	gridTemplateColumns: '.1fr .5fr 1.3fr 1fr 1fr 1fr 1fr 1fr',
	width: '100%'

});

const TopProductRow = ({ product, indent, totalProducts, totalAmount, page}) => {
  const { t } = useTranslation();
  const [imgError, setImgError] = useState(false);

  indent++;
  
  useEffect(() => {
    setImgError(false);
  }, [page]);

  return (
    <table className={'w-full'}>
      <tr
        className={`${bgShades[indent]} ${borderShades[indent]} border-b-2 border-gray-200 px-1`}
        style={rowStyle()}
      >
          <td className={`h-full text-center text-xm font-normal py-2 items-center flex justify-center `}>
            {product.highlighted && 
              <div>
              <StarIcon fill={"#ffe349"} height={15}/>
            </div>}
          </td>  
          <td style={{fontWeight: 600}} className={`h-full text-center text-xm font-normal py-2 items-center flex justify-center `}>
          {product.image && !imgError ?
            <img style={{borderRadius: "10px", width: "64px", height: "64px", objectFit: 'cover'}} src={`${product.image}`} onError={() => setImgError(true)} alt='product_photo'/>
          :
          <div>
            <PhotographIcon height={64}/>
          </div>
      }
          </td>
          <td style={{fontWeight: 600, padding: "0px 15px"}} className={`h-full text-center text-xm font-normal py-2 items-center flex justify-center `}>
            {product.tpv_name}
          </td>
          <td className={`h-full text-center text-xm font-normal py-2 items-center flex justify-center `}>
          {product.tpv_price_cents > 0 ? 
          centsToAmountString(product.tpv_price_cents, true, true) + "€" : "-"}
          </td>
          <td className={`h-full text-center text-xm font-normal py-2 items-center flex justify-center `}>
            {product.quantity}
          </td>
          <td className={`h-full text-center text-xm font-normal py-2 items-center flex justify-center `}>
          {totalProducts > 0 ? 
            toDecimalString(product.quantity / totalProducts * 100) + "%" : "-"}
          </td>
          <td className={`h-full text-center text-xm font-normal py-2 items-center flex justify-center `}>
          {product.tpv_price_cents > 0 ? 
          centsToAmountString((product.tpv_price_cents * product.quantity), true, true) + "€": "-"}
          </td>
          <td className={`h-full text-center text-xm font-normal py-2 items-center flex justify-center `}>
          {totalAmount > 0 && product.tpv_price_cents > 0 ? 
            toDecimalString(((product.tpv_price_cents * product.quantity) / totalAmount) * 100) + "%" : "-"}
          </td>
  
      </tr>
    </table>
  )
};

export default TopProductRow