import FormError from 'common/FormError';
import XButton from 'common/XButton';
import useUpdateTableData from 'components/hooks/useUpdateTableData';
import OpeningHours from 'components/yumminn/OpeningHours';
import useRestaurant from 'components/yumminn/useRestaurant';
import { Formik } from 'formik';
import { apiAuth } from 'logic/api';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import { useLazyAxios } from 'use-axios-client';
import * as Yup from 'yup';



// construccion del objecto yup de validacion del cuestionario
let obligado = {
  name: Yup.string().required('obligatorio'),
};
const validacion = Yup.object().shape(obligado); 

const initialValues = {
  name: '',
  email: '',
};

const SettingsGeneralPage = ({ children }) => {
  const { t } = useTranslation();
  const selected =
  JSON.parse(localStorage.getItem('yumminn-selectedRestaurant')) 
  const [tagsList, setTagsList] = React.useState([]);
  const [restaurantTypesList, setRestaurantTypesList] = React.useState([]);
  //const [data, setData] = useState([])
  const { seleccionado } = useRestaurant();

  const [getData, { data, error, loading }] = useLazyAxios({
    axiosInstance: apiAuth,
    url: `/restaurants/${selected[0]?.value}`
  });

  const { updateData, error: updatedError } = useUpdateTableData({
    entidad: 'restaurants',
    id: selected[0]?.value,
  });

  const getTags = React.useCallback(async () => {
    const tags_data = await apiAuth.get('/tags/');
    const tags_list = tags_data.data.results.map((t) => ({
      name: t.name,
      id: t.id,
    }));
    setTagsList(tags_list);
  }, []);

  const getResturantTypes = React.useCallback(async () => {
    const tags_data = await apiAuth.get('/restaurant-types/');
    const tags_list = tags_data.data.results.map((t) => ({
      name: t.name,
      id: t.id,
    }));
    setRestaurantTypesList(tags_list);
  }, []);

  React.useEffect(() => {
      getTags();
      getResturantTypes()
      getData()
  }, [getTags, getResturantTypes, getData])


  const handleCreateTag = async (name, tags, setFieldValue) => {
    // crear nuevo tag aqui y añadirlo a tags
    try {
      const tag_created = await apiAuth.post('/tags/', { name });
      setFieldValue('tags', [...tags, tag_created.data]);
      //getTags();
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  const handleTypeChange = (e, values, setFieldValue) => {
    for (const tipo of restaurantTypesList) {
      const estoy = values.types.find((t) => t.name === tipo.name);
      if (!estoy) {
        if (e.target.name === tipo.name && e.target.checked === true) {
          setFieldValue('types', [...values.types, tipo]);
        }
      } else {
        //
        if (e.target.name === tipo.name && e.target.checked === false) {
          setFieldValue(
            'types',
            values.types.filter((t) => t.name !== e.target.name)
          );
        }
      }
    }
  };

  const onSubmit = async (values, { setSubmitting }) => {
    //
    const enviaremos = beforeSubmit(values);

    console.group('Enviamos');
    console.log('values :>> ', values);
    console.log(enviaremos);
    console.groupEnd();

    await updateData(enviaremos);
    toast.success(`${t('update_ok')}`);
    getData();
  };

  const beforeSubmit = (values) => {
    const after = { ...values };

    delete after.groups;
    delete after.opening_hours;
    delete after.owner;
    delete after.profile_photos;
    delete after.sectors;
    delete after.chef_photo;
    delete after.logo;
    delete after.menu;
    delete after.home_image;
    delete after.adyen_config;
    delete after.manager;
    delete after.home_image_order_and_pay_or_collect;
    delete after.adyen_account_holder_id;
    delete after.adyen_account_id;
    delete after.adyen_account_tips_id;
    delete after.tpv;
    delete after.tpv_key;
    delete after.tpv_secret;
    delete after.tpv_wildcard_product_name;
    delete after.tpv_wildcard_product_code;
    delete after.tpv_local_id;

    // delete after.tags;
    after.tags = values.tags.map((t) => t.id);
    // delete after.types;
    after.types = values.types.map((t) => t.id);

    return after;
  };

  /*if (loading) {
    return <div>Loading...</div>;
  }*/

  /*if (error || updatedError) {
    return <pre>{JSON.stringify(error, null, 4)}</pre>;
  }*/

  if (!data) return null;

  if (data) {
    return (
      <div className={'flex flex-row w-full my-4 space-x-5 justify-between'}>
        <Formik
          enableReinitialize={true}
          initialValues={data || initialValues}
          validationSchema={validacion}
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit} autoComplete='off' className='w-2/3'>
              {/* <pre>{JSON.stringify(errors, null, 4)}</pre> */}
              {/* <pre>{JSON.stringify(updated, null, 4)}</pre> */}
              <div className='flex flex-row space-x-5 w-full'>
                {/* col1 */}
                <div className='w-full flex flex-col'>
                  {/* // name */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-48 text-[14px] text-texto text-right'
                      htmlFor='name'
                    >
                      {t('Business_Name')}*
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='text'
                        placeholder={t('Business_Name')}
                        className='yumminn-input'
                        value={values.name || ''}
                        name='name'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                      />
                      <FormError message={errors.name} />
                    </div>
                  </div>
                  {/* // tags */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-48 text-[14px] text-texto text-right'
                      htmlFor='name'
                    >
                      {t('TAG_type_of_food')}
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <CreatableSelect
                        isMulti
                        placeholder={t('TAG_type_of_food')}
                        className=' w-full my-0 rounded-lg pt-0 py-0 w-48 text-[12px]'
                        value={values.tags || []}
                        options={tagsList}
                        getOptionLabel={(o) => o['name']}
                        getOptionValue={(o) => o['id']}
                        name='tags'
                        onCreateOption={(input) =>
                          handleCreateTag(input, values.tags, setFieldValue)
                        }
                        formatCreateLabel={(o) => {
                          return `Nuevo TAG... ${o}`;
                        }}
                        isValidNewOption={(
                          inputValue,
                          selectValue,
                          selectOptions
                        ) => {
                          const estoy = selectOptions.find((item) =>
                            item['name']
                              .toUpperCase()
                              .startsWith(inputValue.toUpperCase())
                          );
                          return !estoy;
                        }}
                        getNewOptionData={(inputValue, optionLabel) => {
                          return { name: inputValue, id: optionLabel };
                        }}
                        onChange={(v) => {
                          setFieldValue('tags', [...v]);
                        }}
                      />
                      <FormError message={errors.tags} />
                    </div>
                  </div>
                  {/* // email */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-48 text-[14px] text-texto text-right'
                      htmlFor='email'
                    >
                      {t('Email')}
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='email'
                        placeholder={t('Email')}
                        className='yumminn-input'
                        value={values.email || ''}
                        name='email'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                      />
                      <FormError message={errors.email} />
                    </div>
                  </div>
                  {/* // phone */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-48 text-[14px] text-texto text-right'
                      htmlFor='phone'
                    >
                      {t('Phone')}
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='text'
                        placeholder={t('Phone')}
                        className='yumminn-input'
                        value={values.phone || ''}
                        name='phone'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                      />
                      <FormError message={errors.phone} />
                    </div>
                  </div>
                  {/* // address */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-48 text-[14px] text-texto text-right'
                      htmlFor='address'
                    >
                      {t('Address')}
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='text'
                        placeholder={t('Address')}
                        className='yumminn-input'
                        value={values.address || ''}
                        name='address'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                      />
                      <FormError message={errors.address} />
                    </div>
                  </div>
                  {/* // zip_code */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-48 text-[14px] text-texto text-right'
                      htmlFor='zip_code'
                    >
                      {t('Zip_code')}
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='text'
                        placeholder={t('Zip_code')}
                        className='yumminn-input'
                        value={values.zip_code || ''}
                        name='zip_code'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                      />
                      <FormError message={errors.zip_code} />
                    </div>
                  </div>
                  {/* // city */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-48 text-[14px] text-texto text-right'
                      htmlFor='city'
                    >
                      {t('City')}
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='text'
                        placeholder={t('City')}
                        className='yumminn-input'
                        value={values.city || ''}
                        name='city'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                      />
                      <FormError message={errors.city} />
                    </div>
                  </div>
                  {/* // state */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-48 text-[14px] text-texto text-right'
                      htmlFor='state'
                    >
                      {t('State')}
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='text'
                        placeholder={t('State')}
                        className='yumminn-input'
                        value={values.state || ''}
                        name='state'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                      />
                      <FormError message={errors.state} />
                    </div>
                  </div>
                  {/* // country */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-48 text-[14px] text-texto text-right'
                      htmlFor='country'
                    >
                      {t('Country')}
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='text'
                        placeholder={t('Country')}
                        className='yumminn-input'
                        value={values.country || ''}
                        name='country'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                      />
                      <FormError message={errors.country} />
                    </div>
                  </div>
                </div>
                {/* END col1 */}

                {/* col2 */}
                <div className='w-full flex flex-col'>
                  {/* // link */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-48 text-[14px] text-texto text-right'
                      htmlFor='link'
                    >
                      {t('Website_link')}
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='text'
                        placeholder={t('Website_link')}
                        className='yumminn-input'
                        value={values.link || ''}
                        name='link'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                      />
                      <FormError message={errors.link} />
                    </div>
                  </div>
                  {/* // instagram */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-48 text-[14px] text-texto text-right'
                      htmlFor='instagram'
                    >
                      {t('Instagram_link')}
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='text'
                        placeholder={t('Instagram_link')}
                        className='yumminn-input'
                        value={values.instagram || ''}
                        name='instagram'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                      />
                      <FormError message={errors.instagram} />
                    </div>
                  </div>
                  {/* // facebook */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-48 text-[14px] text-texto text-right'
                      htmlFor='facebook'
                    >
                      {t('Facebook_link')}
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='text'
                        placeholder={t('Facebook_link')}
                        className='yumminn-input'
                        value={values.facebook || ''}
                        name='facebook'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                      />
                      <FormError message={errors.facebook} />
                    </div>
                  </div>{' '}
                </div>

                {/* END col1 */}
              </div>

              <div className='self-end my-5 w-full'>
                <XButton
                  className='btn btn-primary rounded-full px-12'
                  type='button'
                  doClick={handleSubmit}
                >
                  {t('save')}
                </XButton>
              </div>
            </form>
          )}
        </Formik>

        {/* col3 */}
        <div className='flex flex-col w-1/3'>
          <div className='font-bold text-[18px] mb-5'>{t('Opening_hours')}</div>
          <OpeningHours restaurant={seleccionado[0]?.value} />
        </div>
        {/* END col3 */}
      </div>
    );
  }
};

export default SettingsGeneralPage;
