import {
  addDays,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  subDays,
  isAfter,
  startOfDay,
  endOfDay,
} from "date-fns";
import { useState, useContext, useEffect } from "react";
import "./DateRangeSelect.css";
import "rsuite/dist/rsuite.css";
import { Context } from "data/authContext";
import { useTranslation } from "react-i18next";
import { DateInput } from "common/Input";
import styled from "styled-components";

export const getTodayDates = () => {
  const todayStart = startOfDay(new Date());
  const todayEnd = endOfDay(todayStart);
  // Don't use the return value of setHours(), instead use the date object itself
  // setHours() modifies the date object but then returns an integer
  return { todayStart, todayEnd };
}

const predefinedRanges = ( t ) => {
  const { todayStart, todayEnd } = getTodayDates();
  const user = JSON.parse(localStorage.getItem("yumminn-user"));

  const response = [
    {
      label: t("today"),
      value: [todayStart, todayEnd],
      placement: "bottom",
    },
    {
      label: t("yesterday"),
      value: [addDays(todayStart, -1), addDays(todayEnd, -1)],
      placement: "bottom",
    },
    {
      label: t("this_week"),
      value: [startOfWeek(new Date()), new Date()],
      placement: "bottom",
    },
    {
      label: t("last_week"),
      value: [subDays(startOfWeek(new Date()), 7), subDays(startOfWeek(new Date()), 1)],
      placement: "bottom",
    },
    {
      label: t("last_7_days"),
      value: [subDays(new Date(), 6), new Date()],
      placement: "bottom",
    },
    {
      label: t("last_30_days"),
      value: [subDays(new Date(), 29), new Date()],
      placement: "bottom",
    },
    {
      label: t("this_month"),
      value: [startOfMonth(new Date()), new Date()],
      placement: "bottom",
    },
    {
      label: t("last_month"),
      value: [
        startOfMonth(addMonths(new Date(), -1)),
        endOfMonth(addMonths(new Date(), -1)),
      ],
      placement: "bottom",
    },
    {
      label: t("This year"),
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: "bottom",
    },
    {
      label: t("Last year"),
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
      placement: "bottom",
    }
  ];

  if (user.is_superuser) {
    response.push({
      label: t("All time"),
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
      placement: "bottom",
    });
  }

  return response;
};

function isAfterDay(date1, date2) {
  if (typeof date1 != 'object'){
    date1 = new Date(date1)
  }
  return isAfter(new Date(date1.getFullYear(), date1.getMonth(), date1.getDate()), new Date(date2.getFullYear(), date2.getMonth(), date2.getDate()));
}

const afterToday = (afterDate) =>{
  if (afterDate === void 0) {
    afterDate = new Date();
  }

  return function (date) {
    if (isAfterDay(date, new Date(afterDate))) {
      return true;
    }

    return false;
  };
}

export default function DateRangeSelect({hourFormat=true, defaultRange='yesterday', allowAfterToday=false, title="period"}) {
  const { t } = useTranslation();
  const path = window.location.pathname;
  const placement = (path === '/admin/orders' || path === '/payments') ? "bottomStart" : "auto";

  const {
    setSelectedDate,
    setSelectedDateTo,
  } = useContext(Context);

  const predefinedRangesValue = predefinedRanges(t);

  const [dateRange, setDateRange] = useState(predefinedRangesValue[1].value);
  
  const changeDates = (dates) => {
    if(dates){
      setDates(dates);
    }
  };

  const setDates = (dates) => {
    dates[0] = startOfDay(dates[0]);
    dates[1] = endOfDay(dates[1]);

    setDateRange(dates);
    setSelectedDate(dates[0]);
    setSelectedDateTo(dates[1]);
  };

  useEffect(() => {
  const defaultRangeValue = predefinedRangesValue.find(range => range.label === t(defaultRange))?.value || predefinedRangesValue[1].value;
  
  setDates(defaultRangeValue);
  }, [defaultRange]);

  return (
    <SmallDateInput
      label={title}
      value={dateRange}
      onChange={changeDates}
      ranges={predefinedRanges(t)}
      format={hourFormat ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd"}
      defaultCalendarValue={dateRange}
      showWeekNumbers
      shouldDisableDate={allowAfterToday ? "" : afterToday()}
      placement={placement}
      preventOverflow
      cleanable={false}
    />
  );
};

const SmallDateInput = styled(DateInput)`
  .date_input {
    height: 42px;
  }
`;
