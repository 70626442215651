import React, { useEffect, useContext, useState, useRef } from 'react';
import americanexpressIcon from '../../../img/americanexpress.png';
import bizumPng from '../../../img/Bizum.png';
import DateRangeSelect from 'components/yumminn/DateRangeSelect.jsx';
import DetailPaymentMobile from './DetailsPaymentMobile';
import eye from '../../../img/eye.svg';
import Loading from "../../../common/Loading";
import mastercardIcon from '../../../img/mastercard.png';
import SelectSectors from './SelectSectors';
import SelectTables from './SelectTables';
import Spinner from "common/Spinner";
import StatusBadge from '../../admin/pages/Orders/StatusBadge';
import visaIcon from '../../../img/visa.png';
import useModal from 'components/hooks/useModal';
import useRefundModal from 'components/hooks/useRefundModal.jsx';
import useRefundRedsysModal from 'components/hooks/useRefundRedsysModal.jsx';
import { apiAuth, paymentAuth } from 'logic/api';
import { centsToAmountString } from "../../../logic/functions";
import { useTranslation } from 'react-i18next';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { Trans } from 'react-i18next';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { toast } from "react-toastify";
import { sayError } from "../../../logic/defaults";
import { calculateCents } from "services/payments";
import { XIcon } from '@heroicons/react/outline';
import { PaymentRow } from './PaymentRow.jsx';
import { PaymentListOverview } from './PaymentListOverview.jsx';
import { Context } from 'data/authContext.js';
import { NavigatorContext } from 'data/navigatorContext';
import RoundPagination from 'common/RoundPagination';

const PaymentsList = () => {

  const { t } = useTranslation();
  const {
    state: { selectedDate, selectedDateTo, selectedRestaurant },
  } = useContext(Context);

  const { state: { open } } = useContext(NavigatorContext);
  const medida = 20;
  const user = JSON.parse(localStorage.getItem('yumminn-user'));
  const isRowBased = useMediaQuery("(max-width: 760px)");
  let componentMounted = false;

  const [sum, setSum] = useState({});
  const [individualPayment, setIndividualPayment] = useState([]);

  const tableRef                = useRef('');
  const paginaRef               = useRef('');
  const sectorRef               = useRef('');
  const restaurantListRef       = useRef([]);
  const selectedDateRef         = useRef('');
  const selectedDateToRef       = useRef('');
  const refundModalOpenRef      = useRef('');

  const [loadingPayments, setLoadingPayments] = useState(true);
  const [loadingSectors, setLoadingSectors] = useState(false);
  const [loadingTables, setLoadingTables] = useState(false);

  const [payments, setPayments] = useState(null);
  const [sectors, setSectors] = useState([]);
  const [tables, setTables] = useState([]);

  const [sector, setSector] = useState('');
  const [table, setTable] = useState('');
  
  const [count, setCount] = useState(0);
  const [pagina, setPagina] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [detailPayment, setDetailPayment] = useState(null);
  const [refundModalOpen, setRefundModalOpen] = useState(false);
  const showSpinner = React.useRef(null);

  const recursivelyRefreshPayments = () => {
    setTimeout(() => {
      if (componentMounted) {
        if (refundModalOpenRef.current) {
          recursivelyRefreshPayments();
        } else {
          const callback = () => {
            recursivelyRefreshPayments();
          };
          refreshPayments(callback);
        }
      }
    }, 60000);
  };

  useEffect(() => {
    componentMounted = true;
    recursivelyRefreshPayments();
    return () => {
      componentMounted = false;
    };
  }, []);

  useEffect(() => restaurantListRef.current      = selectedRestaurant, [selectedRestaurant]);
  useEffect(() => sectorRef.current          = sector,             [sector]);
  useEffect(() => tableRef.current           = table,              [table]);
  useEffect(() => paginaRef.current          = pagina,             [pagina]);
  useEffect(() => selectedDateRef.current    = selectedDate,       [selectedDate]);
  useEffect(() => selectedDateToRef.current  = selectedDateTo,     [selectedDateTo]);
  useEffect(() => refundModalOpenRef.current = refundModalOpen,    [refundModalOpen]);

  useEffect(() => {
    setSector('');
    refreshSectors();
  }, [selectedRestaurant]);

  useEffect(() => {
    setTable('');
    if (sector?.id) {
      refreshSectorTables();
    } else if (selectedRestaurant.length) {
      refreshRestaurantTables();
    }
  }, [selectedRestaurant, sector]);

  useEffect(() => {
    refreshPayments();
  }, [selectedRestaurant, sector, table, selectedDate, selectedDateTo, pagina]);

  const refreshPayments = (callback=()=>{}) => {
    setPayments(null);
    if (tableRef.current?.id) {
      refreshTablePayments(callback);
    } else if (sectorRef.current?.id) {
      refreshSectorPayments(callback);
    } else if (restaurantListRef.current.length) {
      refreshRestaurantPayments(callback);
    }
  };

  const refreshSectors = _ => {
    if (!restaurantListRef.current.length) return;

    try {
      const endpoint = `/list/sectors`;
      setLoadingSectors(true);
      apiAuth
        .post(endpoint, {restaurants: restaurantListRef.current.map(restaurant => restaurant.value)})
        .then(response => {
          const newSectors = response.data;
          setSectors(newSectors);
        })
        .catch(err => console.log(`PaymentsList.jsx:refreshSector ~ endpoint=${endpoint} catch: ${err}`))
        .finally(_ => setLoadingSectors(false));
    } catch(e) {
      console.log(`PaymentsList.jsx:refreshSectors exception: ${e}`);
    }
  };

  const refreshTables = (endpoint, body = {}) => {
    setLoadingTables(true);
    apiAuth
      .post(endpoint, body)
      .then(response => {
        const newTables = response.data;
        setTables(newTables);
      })
      .catch(err => console.log(`PaymentsList.jsx:refreshSectorTables ~ endpoint=${endpoint} catch: ${err}`))
      .finally(() => setLoadingTables(false));
  }

  const refreshSectorTables = () => {
    if (!sector?.id) return;
    
    try {
      const endpoint = `/list/sector_tables/${sector.id}`;
      refreshTables(endpoint);
    } catch(e) {
      console.log(`PaymentsList.jsx:refreshSectorTables exception: ${e}`);
    }
  };

  const refreshRestaurantTables = () => {
    if (!restaurantListRef.current.length) return;

    try {
      const endpoint = `/list/restaurant_tables`;
      const body = {restaurants: restaurantListRef.current.map(restaurant => restaurant.value)};

      refreshTables(endpoint, body);
    } catch(e) {
      console.log(`PaymentsList.jsx:refreshRestaurantTables exception: ${e}`);
    }
  };

  const retrieveAndSetPayments = (endpoint, callback) => {
    if (!restaurantListRef.current.length) return;

    const body = {
      sector: sectorRef.current?.id || null,
      table: tableRef.current?.id || null,
      restaurants: restaurantListRef.current?.map(restaurant => restaurant.value) || [],
      from: selectedDateRef.current,
      to: selectedDateToRef.current,
      refunded: true,
      page: paginaRef.current,
    };

    setLoadingPayments(true);
    
    apiAuth
      .post(endpoint, body)
      .then(response => {
        setPayments(response.data.data);
        setCount(response.data.data.length);
        setSum(response.data.payments_sum);
        setTotalPages(response.data.total_pages);
        callback();
      })
      .catch(err => console.log(`PaymentsList.jsx:refreshPayments ~ endpoint=${endpoint} catch: ${err}`))
      .finally(() => setLoadingPayments(false));
  };

  const refreshTablePayments = callback => {
    if (!tableRef.current?.id) return;

    const endpoint = `/list/table_payments/${tableRef.current.id}`;
    retrieveAndSetPayments(endpoint, callback);
  };

  const refreshSectorPayments = callback => {
    if (!sectorRef.current?.id) return;

    const endpoint = `/list/sector_payments/${sectorRef.current.id}`;
    retrieveAndSetPayments(endpoint, callback);
  };

  const refreshRestaurantPayments = callback => {
    if (!restaurantListRef.current.length) return;

    const endpoint = `/list/restaurant_payments`;
    retrieveAndSetPayments(endpoint, callback);
  };

  const openDetailsModal = (paymentItem) => {
    detailModal.openModal(paymentItem);
    setIndividualPayment(paymentItem);
  };

  const handleRefund = async (payment, valueToRefund, customer_name, customer_email) => {
    showSpinner.current = true;
    try {
      const amountToBeRefunded = Number(calculateCents(valueToRefund));
      const partialRefund = amountToBeRefunded < ((payment.base_amount_cents - payment.general_discount_cents - payment.product_discount_cents - payment.loyalty_discount_cents - payment.master_c2p_promotion_cents - payment.code_coupon_cents - payment.refunded_amount) + payment.tip_cents);
      
      if (payment.payment_method !== 'cash') {
        const obj = {
          date: payment.date,
          merchantAccount: process.env.REACT_APP_MERCHANT_ACCOUNT,
          modificationAmount: {value: amountToBeRefunded, currency: "EUR"},
          paymentId: payment.id,
          restaurantId: payment.restaurantId,
          restaurant_name: payment.restaurantName,
          customer_name: customer_name,
          customer_email: customer_email
        };
        await paymentAuth.post(`/refund-payment`, obj);
      }

      const refundedAmount = payment.refunded_amount + amountToBeRefunded
      try {
        await apiAuth.patch(
          `/payments/${payment.id}`,
          { refunded: partialRefund ? false : true,
            refunded_amount: refundedAmount },
        );
      } catch (error) {
        toast.error(sayError(error.response.data));
        console.log(`error`, error);
      }
    } catch (error) {
      toast.error(sayError(error.response.data));
      console.log(`error`, error);
    } finally {
      showSpinner.current = false;
    }
  };

  const doPaginateClick = (direction) => {
    if (direction === 'first') {
      setPagina(0);
    }
    if (direction === 'last') {
      setPagina(totalPages - 1);
    }
    if (direction === 'prev' && pagina !== 0) {
      setPagina(pagina - 1);
    }
    if (direction === 'next' && pagina < count / medida - 1) {
      setPagina(pagina + 1);
    }
  };

  const openRefundModal = payment => {
    setRefundModalOpen(true);
    if (payment.payment_method !== 'cash' && payment.payment_gateway === "redsys") {
      refundRedsysModal.openModal(payment);
    } else {
      refundModal.openModal(payment);
    }
  };
  

  const LoadingOverlay = () => {
    const { t } = useTranslation();
    return (
      <>
        <div className="h-full w-full top-0 left-0 fixed z-9999 flex flex-col justify-center items-center">
          <Spinner>{t("Loading")}</Spinner>
        </div>
        <div className="bg-black opacity-20 top-0 left-0 h-full w-full fixed z-9999"/>
      </>
    )
  }

  const PaymentDetail = () => {
    const { t } = useTranslation();
    return (
      <div style={{height: "100%", width: "100%", position: "absolute", zIndex: 2}}>
        <div style={{borderWidth: '5px', borderColor: 'gray', left: '40%', right: '40%', height: "500px", width: "350px", backgroundColor: 'white', position: "absolute"}}>
          <div style={{margin: '2%', width: '100%', display: 'flex', justifyContent: 'space-between', paddingTop: '2px', fontWeight: 600}}>
            {t('Products')}
            <XIcon onClick={() => setDetailPayment(null)} style={{cursor: 'pointer', width: "30px", height: "30px", marginRight: "20px"}}/>
          </div>
          <div style={{width: '100%', height: '88%', overflow: 'scroll'}}>
            <div style={{fontSize: "13px", marginLeft: '5px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '10px 0'}}>
              <div style={{width: "10%", textAlign: 'center'}}></div>
              <div style={{width: "20%", textAlign: 'center', fontWeight: 600}}>Cantidad</div>
              <div style={{width: "50%", textAlign: 'center', fontWeight: 600}}>Nombre</div>
              <div style={{width: "20%", textAlign: 'center', fontWeight: 600}}>Precio</div>
            </div>
            {detailPayment.items.map((item, i) => {
              return (
                <div key={i}>
                  <div style={{fontSize: "13px", marginLeft: '5px', display: 'flex', justifyContent: 'center', flexDirection: 'row', alignItems: 'center', padding: '5px 0'}}>
                        <div style={{width: "10%", textAlign: 'center',}}>{i+1}</div>
                        <div style={{width: "20%", textAlign: 'center', fontWeight: 600}}>{item.amount}</div>
                        <div style={{width: "50%", textAlign: 'center', fontWeight: 600}}>{item.name}</div>
                        <div style={{width: "20%", textAlign: 'center', fontWeight: 600}}>{(item.price)}€</div>
                  </div>
                  {item.modifiers?.map((item) => {
                    return (
                      <div style={{fontSize: "11px", marginLeft: '5px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: '1px 0'}}>
                        <div style={{padding: '0 10px'}}></div>
                        <div style={{padding: '0 10px'}}></div>
                        <div style={{padding: '0 10px'}}>- {item.name}</div>
                        <div style={{padding: '0 10px'}}>{(item.total_price/100)}€</div>
                        </div>
                      )
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    )
  }

  const ItemsBadge = ({ payment }) => {
      // onClick={() => infoModal.openModal(payment)}
      return <div onClick={() => setDetailPayment(payment)} className='p-1 group relative hover:font-bold cursor-pointer text-blue-500 bg-blue-100 flex flex-row items-center justify-center gap-2  rounded-md '>
        <InformationCircleIcon className='h-4 w-4' />
      </div>
  }

  const CardType = ({ cardType, size, paymentMethod }) => {
    if (paymentMethod === 'bizum') return <img style={{ height: `${size+5}px` }} src={bizumPng} alt="bizum" />
    if (cardType === 'visa') return <img style={{ height: `${size}px` }} src={visaIcon} alt="visa" />
    if (cardType === 'amex') return <img style={{ height: `${size}px` }} src={americanexpressIcon} alt="amex" />
    if (cardType === 'mc') return <img style={{ height: `${size}px` }} src={mastercardIcon} alt="mc" />
    return <></>
  }

  const infoModal = useModal();
  const detailModal = useModal();
  const refundModal = useRefundModal(handleRefund, 0, setRefundModalOpen);
  const refundRedsysModal = useRefundRedsysModal();

  return (
    <>
      {showSpinner.current && <LoadingOverlay/>}
      {detailPayment != null && <PaymentDetail/>}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DateRangeSelect open={open} defaultRange='today'/>
        <div className={'flex pb-4'}>
          <SelectSectors sectors={sectors} sector={sector} setSector={setSector} loadingSectors={loadingSectors} />
          <SelectTables tables={tables} table={table} setTable={setTable} loadingTables={loadingTables} />
        </div>
      </div>
      {loadingPayments ? (<Loading />) : (
        <>
          <PaymentListOverview sum={sum} />
          {totalPages > 0 && (
            <div className={'mb-4'}>
              <RoundPagination
                page={pagina}
                numberOfPages={totalPages}
                setPage={setPagina}
                total={count}
                elementNamePlural={t("payments")}
                elementNameSingular={t("payment")}
              />
            </div>
            )}
          {!isRowBased ?
            <div className='overflow-auto bg-white rounded-lg border-gray-200 border-2'>
              <table className='table-auto text-xs w-full'>
                <thead className='text-gray-700 border-b-2 border-gray-200'>
                  <th className='py-4 px-3'>{t('Table')}</th>
                  <th className='py-4 px-3 whitespace-nowrap'>{t('Order')}</th>
                  {!isRowBased && <th className='py-4 px-3 '>{t('Method')}</th>}
                  <th className='py-4 px-3 whitespace-nowrap'>{t('Date and hour')}</th>
                  <th className='py-4 px-3 whitespace-nowrap'>{t('base_amount')}</th>
                  <th className='py-4 px-3 whitespace-nowrap'>{t('loyalty')}</th>
                  <th className='py-4 px-3 whitespace-nowrap'>{t('promos')}</th>
                  <th className='py-4 px-3 whitespace-nowrap'>{t('Subtotal')}</th>
                  <th className='py-4 px-3 whitespace-nowrap'>{t('Tip')}</th>
                  <th className='py-4 px-3 whitespace-nowrap'>{t('Refunded amount')}</th>
                  <th className='py-4 px-3 whitespace-nowrap'>{t('Total')}</th>
                  <th className='py-4 px-3 whitespace-nowrap'>{t('Status')}</th>
                  <th className='py-4 px-3 whitespace-nowrap'>{t('Items')}</th>
                  {user.rol !== 'waiter' && <th className='py-4 px-3 whitespace-nowrap'>{t('Refund')}</th>}
                </thead>
                <tbody>
                  {payments?.length === 0 &&
                    <tr>
                      <td colSpan="10" className={'pb-8 pt-8 text-[14px] text-center'}>
                        {t('No payments')}
                      </td>
                    </tr>
                  }
                  {payments?.map((pay) =>
                    <PaymentRow
                      key={'payment-' + pay.id}
                      pay={pay}
                      CardType={CardType}
                      ItemsBadge={ItemsBadge}
                      openRefundModal={openRefundModal}
                    />
                  )}
                </tbody>
              </table>
            </div>
            :
            <>
              {payments?.length === 0 &&
                <tr>
                  <td colspan="10" className={'pb-8 pt-8 text-[14px] text-center'}>
                    {t('No payments')}
                  </td>
                </tr>
              }
              {payments?.map((pay, index) => {
                return <div key={index} className={`shadow-lg rounded-md grid grid-cols-2 p-2 border-2 border-gray-200 ${index % 2 === 1 ? 'bg-gray-100' : 'bg-white'}`}>
                  <div className='text-left p-2'>
                    <div className={' font-bold text-xs whitespace-nowrap'}>{pay?.table?.name}</div>
                    <div>{new Date(pay.date).toLocaleString('es-ES')}</div>
                    <div>{t('Tips')}: {centsToAmountString(pay.tip_cents) + '€'}</div>
                    <div>{t('total')}: {centsToAmountString((pay.base_amount_cents - pay.general_discount_cents - pay.loyalty_discount_cents) + pay.tip_cents) + '€'}</div>
                  </div>
                  <div className='p-1 py-3 flex flex-col items-end justify-between'>
                    <StatusBadge status={pay.status} refunded={pay.refunded} payment={pay} onClick={() => openDetailsModal(pay)} />
                    <div onClick={() => openDetailsModal(pay)} >
                      <img src={eye} style={{ width: '26px', height: '26px' }} alt='details' />
                    </div>
                  </div>
                </div>
              })}
            </>
          }
          <refundModal.Modal mensaje={t('Do_Refund')} title={t("Proceed")} />
          <refundRedsysModal.Modal/>
          <infoModal.Modal>
            <Trans i18nKey={'Error_POS_explanation'}>The payment was successful <strong>but didn't get registered in the POS due to an unfortunate error</strong>. Please enter the payment manually in the POS.</Trans>
          </infoModal.Modal>
          <detailModal.Modal>
            <DetailPaymentMobile detailsPayment={individualPayment}>
              {individualPayment.card_brand && <div className=' flex flex-row items-center justify-end'>
                <CardType cardType={individualPayment.card_brand} size={10} />
                <div className='ml-2 whitespace-nowrap'>* {individualPayment.card_last_4}</div>
              </div>}
            </DetailPaymentMobile>
          </detailModal.Modal>
        </>
      )}
    </>
  )
}

export default PaymentsList;
