import { centsToAmountString } from 'logic/functions';
import React from 'react';
import OrderModifierRow from './OrderModifierRow';
import OrderProductNotes from './OrderProductNotes';
import { useTranslation } from 'react-i18next';

const OrderProductRow = ({product, product_modifiers, modifiers_modifiers}) => {
  const { t } = useTranslation();

  return (
    <div style={{backgroundColor: product.is_free_product ? '#FFF8DE' : 'transparent', borderRadius: '99px'}}>
      <div style={{display: "flex", justifyContent: 'space-between', alignItems: 'center'}}>
        <div style={{display: "flex", alignItems: 'center', gap: "10px", fontSize: "14px", height: "100%"}}>
          <div style={{display: "flex", minWidth: "24px", height: "24px", justifyContent: 'center', alignItems: 'center', color: product.is_free_product ? '#FFFFFF' : "#212121", backgroundColor: product.is_free_product ? '#094553' : "#F0F0F0", borderRadius: "999px"}}>
            {product.quantity}
          </div>
          <div style={{fontSize: "16px"}}> 
            {product.name}
          </div>
        </div>
        {!product.is_free_product &&
          <div style={{fontSize: "16px", fontWeight: 600}}>
            {centsToAmountString(product.unit_price_cents * product.quantity)}€
          </div>
        }
        {product.is_free_product &&
          <div style={{fontSize: "14px", fontWeight: 600, lineHeight: 1, textTransform: 'uppercase', color: '#094553', border: '1px solid currentColor', borderRadius: '99px', maxHeight: '24px', padding: '4px 8px' }}>
            {t('gift')}
          </div>
        }
      </div>
      {product_modifiers?.map((product_modifier, index) =>
        <OrderModifierRow key={'modifier_' + index} modifier={product_modifier} modifier_modifiers={modifiers_modifiers.filter(p_m => p_m.order_modifier == product_modifier.id)} is_modifier_modifier={false}/>
      )}
      {product.comment &&
        <OrderProductNotes note={product.comment}/>
      }
    </div>
  );
};

export default OrderProductRow;
