import React from 'react';
import RestaurantSelect from 'components/yumminn/RestaurantSelect';
import { CategorySelect } from 'components/yumminn/CategorySelect';
import DateRangeSelect from "components/yumminn/DateRangeSelect";
import SideBar from 'layouts/MenuContainer';
import {useMediaQuery} from '../../hooks/useMediaQuery';
import MenuHeader from 'pages/home/components/overview/components/MenuHeader';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const MenuContainer = ({ title, route_group, children }) => {

  const isRowBased = useMediaQuery("(max-width: 760px)");
  const history = useHistory();

  const defaultRange = history.location.pathname === '/admin/catalog-dashboard' ? 'last_30_days' : 'yesterday';
  
  return (
    <div id="menuContainer" className={`flex flex-col bg-gray-100 h-full w-full max-w-full ${isRowBased?'flex-col':'flex-row'}`}>
      <SideBar style={{flex:' 0 1 auto',position:'fixed',overflow: 'hidden'}}/>
      <div className={`${isRowBased ? 'flex flex-col pl-1 mt-16 inset-0' : 'flex flex-col w-[calc(100%-85px)]'}`}>
        
        <MenuHeader title={title} route_group={route_group}/>
          
        <div className={`${isRowBased && 'bg-white'}`} >
          <div className='flex items-center gap-5 bg-white py-4'>
            <div className='px-[32px] flex items-center gap-5'>
              <RestaurantSelect />
              {['general_metrics', 'product_metrics'].includes(title) &&
                <DateRangeSelect hourFormat={false} defaultRange={defaultRange}/>
              }
              {title === 'product_metrics' && <CategorySelect />}
            </div>
          </div>
          <div className={`px-[32px] mt-14 ${history.location.pathname === '/admin/dashboard' ? 'relative' : ''}`}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default MenuContainer;
