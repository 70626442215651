import React from "react";

export const YumminnIconWithName = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="137"
      height="37"
      viewBox="0 0 117 27"
      fill="none"
    >
      <path
        d="M25.8729 19.8848L25.8731 19.8767V19.8686V7.05633C25.8731 3.87206 23.2968 1.31225 20.1049 1.31225H7.23187C4.03998 1.31225 1.46362 3.87206 1.46362 7.05633V19.8686C1.46362 22.9897 4.04573 25.5438 7.23187 25.5438H20.1049C23.2307 25.5438 25.7951 22.9843 25.8729 19.8848Z"
        fill="#5BB994"
        stroke="white"
        strokeWidth="1.29347"
      />
      <path
        d="M13.7372 8.02088C14.5017 8.02088 15.1214 7.43492 15.1214 6.7121C15.1214 5.98928 14.5017 5.40332 13.7372 5.40332C12.9728 5.40332 12.353 5.98928 12.353 6.7121C12.353 7.43492 12.9728 8.02088 13.7372 8.02088Z"
        fill="white"
      />
      <path
        d="M12.5611 21.7286C11.5921 22.0042 10.554 21.522 10.2079 20.6265L7.78561 13.6693C7.43956 12.7738 7.99324 11.8095 9.03138 11.4651C10.0003 11.1895 11.0385 11.6717 11.3845 12.5672L13.8068 19.5244C14.0837 20.4199 13.53 21.3842 12.5611 21.7286Z"
        fill="url(#paint0_radial_1976_18792)"
        stroke="url(#paint1_linear_1976_18792)"
        strokeWidth="0.323368"
        strokeMiterlimit="10"
      />
      <path
        d="M15.1214 21.7286C16.0904 22.0042 17.1285 21.522 17.4054 20.6265L19.8277 13.6693C20.1045 12.7738 19.6201 11.8095 18.6511 11.4651C17.6822 11.1895 16.6441 11.6717 16.3672 12.5672L13.9449 19.5244C13.5988 20.4199 14.1525 21.3842 15.1214 21.7286Z"
        fill="url(#paint2_radial_1976_18792)"
        stroke="url(#paint3_linear_1976_18792)"
        strokeWidth="0.323368"
        strokeMiterlimit="10"
      />
      <path
        d="M13.877 21.7966C12.4236 21.7966 11.2471 20.6255 11.2471 19.179V11.7396C11.2471 10.2931 12.4236 9.12207 13.877 9.12207C15.3304 9.12207 16.507 10.2931 16.507 11.7396V19.179C16.507 20.6255 15.2612 21.7966 13.877 21.7966Z"
        fill="white"
        stroke="url(#paint4_linear_1976_18792)"
        strokeWidth="0.323368"
        strokeMiterlimit="10"
      />
      <path
        d="M19.1359 9.94916C19.6711 9.94916 20.1049 9.54824 20.1049 9.05368C20.1049 8.55912 19.6711 8.1582 19.1359 8.1582C18.6008 8.1582 18.167 8.55912 18.167 9.05368C18.167 9.54824 18.6008 9.94916 19.1359 9.94916Z"
        fill="white"
      />
      <path
        d="M8.54755 9.94916C9.08268 9.94916 9.51648 9.54824 9.51648 9.05368C9.51648 8.55912 9.08268 8.1582 8.54755 8.1582C8.01242 8.1582 7.57861 8.55912 7.57861 9.05368C7.57861 9.54824 8.01242 9.94916 8.54755 9.94916Z"
        fill="white"
      />
      <path
        d="M34.8594 24.7747V23.017C34.8594 22.4545 35.1356 19.4312 34.1689 19.15C33.8927 19.0796 33.2713 18.7281 32.9951 18.4469C32.4427 18.025 32.0284 17.5328 31.7522 16.9001C31.476 16.2673 31.3379 15.6345 31.3379 14.9314V10.3613C31.3379 9.65818 31.8903 9.0957 32.5808 9.0957H32.7879C33.4784 9.0957 34.0308 9.65818 34.0308 10.3613V14.9314C34.0308 15.3532 34.0998 15.7048 34.307 16.0563C34.5141 16.4079 34.7903 16.6891 35.0665 16.8297C35.4118 17.0407 35.757 17.111 36.1713 17.111C36.5856 17.111 36.9308 17.0407 37.2761 16.8297C37.6213 16.6188 37.8975 16.3376 38.0356 16.0563C38.2428 15.7048 38.3118 15.3532 38.3118 14.9314V10.3613C38.3118 9.65818 38.8642 9.0957 39.5547 9.0957H39.7618C40.4523 9.0957 41.0047 9.65818 41.0047 10.3613V14.9314C41.0047 15.6345 40.8666 16.2673 40.5904 16.9001C40.3142 17.5328 39.8999 18.025 39.4166 18.4469C39.0714 18.7281 38.6571 18.939 38.2428 19.15C37.207 19.5718 37.4832 22.5248 37.4832 23.017V24.7747C37.4832 25.4778 36.9308 26.0403 36.2404 26.0403H36.0332C35.4118 26.0403 34.8594 25.4778 34.8594 24.7747Z"
        fill="white"
      />
      <path
        d="M47.7026 19.5015C46.6668 19.5015 45.8382 19.2906 45.0787 18.8687C44.3192 18.4469 43.7668 17.8844 43.4215 17.1813C43.0072 16.4782 42.8691 15.7048 42.8691 14.8611V10.3613C42.8691 9.58787 43.4906 9.02539 44.1811 9.02539C44.9406 9.02539 45.493 9.65818 45.493 10.3613V14.9314C45.493 15.3532 45.562 15.7048 45.7692 16.0563C45.9763 16.4079 46.2525 16.6891 46.5287 16.8297C46.874 17.0407 47.2192 17.111 47.6335 17.111C48.0478 17.111 48.393 17.0407 48.7383 16.8297C49.0835 16.6188 49.3597 16.3376 49.4978 16.0563C49.705 15.7048 49.774 15.3532 49.774 14.9314V10.4316C49.774 9.65818 50.3955 9.0957 51.0859 9.0957C51.8455 9.0957 52.3979 9.72849 52.3979 10.4316V14.9314C52.3979 15.7751 52.1907 16.5485 51.8455 17.2516C51.4312 17.9547 50.8788 18.5172 50.1883 18.939C49.5669 19.2906 48.7383 19.5015 47.7026 19.5015Z"
        fill="white"
      />
      <path
        d="M55.642 19.2203C54.8825 19.2203 54.3301 18.5875 54.3301 17.8844V13.1736C54.3301 12.4002 54.5372 11.6268 54.8825 10.994C55.2277 10.3613 55.7801 9.79879 56.5396 9.37693C57.2992 8.95507 58.1278 8.81445 59.0944 8.81445C59.5778 8.81445 59.9921 8.88476 60.4064 8.95507C60.8207 9.09569 61.1659 9.23631 61.5802 9.44724C61.9254 9.65817 62.2707 9.9394 62.4778 10.2206C62.754 9.9394 63.0993 9.65817 63.4445 9.44724C63.7898 9.23631 64.204 9.09569 64.6183 8.95507C65.0326 8.81445 65.516 8.81445 65.9993 8.81445C67.035 8.81445 67.8636 9.02538 68.5541 9.37693C69.2446 9.79879 69.797 10.291 70.2113 10.994C70.5565 11.6268 70.7637 12.4002 70.7637 13.1736V17.8844C70.7637 18.6578 70.1422 19.2203 69.4518 19.2203C68.6922 19.2203 68.1398 18.5875 68.1398 17.8844V13.1736C68.1398 12.8221 68.0708 12.4705 67.8636 12.1893C67.6565 11.9081 67.4493 11.6268 67.1041 11.4159C66.7589 11.205 66.4136 11.1347 66.0684 11.1347C65.7231 11.1347 65.3088 11.205 65.0326 11.4159C64.6874 11.6268 64.4802 11.8378 64.2731 12.1893C64.066 12.4705 63.9969 12.8221 63.9969 13.1736V17.8844C63.9969 18.6578 63.3755 19.2203 62.685 19.2203C61.9254 19.2203 61.373 18.5875 61.373 17.8844V13.1736C61.373 12.8221 61.304 12.4705 61.0969 12.1893C60.8897 11.9081 60.6826 11.6268 60.3373 11.4159C59.9921 11.205 59.6468 11.1347 59.3016 11.1347C58.9563 11.1347 58.542 11.205 58.2659 11.4159C57.9206 11.6268 57.7135 11.8378 57.5063 12.1893C57.2992 12.4705 57.2301 12.8221 57.2301 13.1736V17.8844C56.9539 18.6578 56.3325 19.2203 55.642 19.2203Z"
        fill="white"
      />
      <path
        d="M73.8031 19.2203C73.0436 19.2203 72.4912 18.5875 72.4912 17.8844V13.1736C72.4912 12.4002 72.6984 11.6268 73.0436 10.994C73.3888 10.3613 73.9412 9.79879 74.7008 9.37693C75.4603 8.95507 76.2889 8.81445 77.2556 8.81445C77.7389 8.81445 78.1532 8.88476 78.5675 8.95507C78.9818 9.09569 79.327 9.23631 79.7413 9.44724C80.0866 9.65817 80.4318 9.9394 80.639 10.2206C80.9152 9.9394 81.2604 9.65817 81.6056 9.44724C81.9509 9.23631 82.3652 9.09569 82.7795 8.95507C83.1938 8.81445 83.6771 8.81445 84.1604 8.81445C85.1962 8.81445 86.0248 9.02538 86.7152 9.37693C87.4057 9.79879 87.9581 10.291 88.3724 10.994C88.7177 11.6268 88.9248 12.4002 88.9248 13.1736V17.8844C88.9248 18.6578 88.3034 19.2203 87.6129 19.2203C86.8533 19.2203 86.301 18.5875 86.301 17.8844V13.1736C86.301 12.8221 86.2319 12.4705 86.0248 12.1893C85.8176 11.9081 85.6105 11.6268 85.2652 11.4159C84.92 11.205 84.5747 11.1347 84.2295 11.1347C83.8843 11.1347 83.47 11.205 83.1938 11.4159C82.8485 11.6268 82.6414 11.8378 82.4342 12.1893C82.2271 12.4705 82.158 12.8221 82.158 13.1736V17.8844C82.158 18.6578 81.5366 19.2203 80.8461 19.2203C80.0866 19.2203 79.5342 18.5875 79.5342 17.8844V13.1736C79.5342 12.8221 79.4651 12.4705 79.258 12.1893C79.0508 11.9081 78.8437 11.6268 78.4984 11.4159C78.1532 11.205 77.808 11.1347 77.4627 11.1347C77.1175 11.1347 76.7032 11.205 76.427 11.4159C76.0817 11.6268 75.8746 11.8378 75.6675 12.1893C75.4603 12.4705 75.3913 12.8221 75.3913 13.1736V17.8844C75.1151 18.6578 74.4936 19.2203 73.8031 19.2203Z"
        fill="white"
      />
      <path
        d="M90.9272 17.8844V9.86911C90.9272 9.44725 91.2725 9.0957 91.6868 9.0957H92.8606C93.2749 9.0957 93.6201 9.44725 93.6201 9.86911V17.8844C93.6201 18.6578 92.9987 19.2203 92.3082 19.2203C91.5487 19.2203 90.9272 18.6578 90.9272 17.8844Z"
        fill="white"
      />
      <path
        d="M97.0033 19.2202C96.2438 19.2202 95.6914 18.5875 95.6914 17.8844V13.3846C95.6914 12.5409 95.8986 11.7674 96.2438 11.0644C96.6581 10.3613 97.2105 9.79878 97.901 9.37693C98.6605 8.95507 99.4891 8.74414 100.525 8.74414C101.561 8.74414 102.458 8.95507 103.149 9.37693C103.908 9.79878 104.461 10.3613 104.806 11.0644C105.22 11.7674 105.358 12.5409 105.358 13.3846V17.8844C105.358 18.6578 104.737 19.2202 104.046 19.2202C103.287 19.2202 102.734 18.5875 102.734 17.8844V13.3846C102.734 12.9627 102.665 12.6112 102.458 12.2596C102.251 11.9081 101.975 11.6268 101.699 11.4862C101.353 11.2753 101.008 11.205 100.594 11.205C100.18 11.205 99.8343 11.2753 99.4891 11.4862C99.1438 11.6971 98.8677 11.9784 98.7296 12.2596C98.5224 12.6112 98.4534 12.9627 98.4534 13.3846V17.8844C98.3843 18.6578 97.7629 19.2202 97.0033 19.2202Z"
        fill="white"
      />
      <path
        d="M108.535 19.3609C107.775 19.3609 107.223 18.7281 107.223 18.025V13.5252C107.223 12.6815 107.43 11.9081 107.775 11.205C108.189 10.5019 108.742 9.93941 109.432 9.51755C110.192 9.09569 111.02 8.88477 112.056 8.88477C113.092 8.88477 113.989 9.09569 114.68 9.51755C115.439 9.93941 115.992 10.5019 116.337 11.205C116.751 11.9081 116.889 12.6815 116.889 13.5252V18.025C116.889 18.7984 116.268 19.3609 115.578 19.3609C114.818 19.3609 114.266 18.7281 114.266 18.025V13.5252C114.266 13.1033 114.197 12.7518 113.989 12.4002C113.782 12.0487 113.506 11.7675 113.23 11.6268C112.885 11.4159 112.539 11.3456 112.125 11.3456C111.711 11.3456 111.366 11.4159 111.02 11.6268C110.675 11.8378 110.399 12.119 110.261 12.4002C110.054 12.7518 109.985 13.1033 109.985 13.5252V18.025C109.847 18.7281 109.225 19.3609 108.535 19.3609Z"
        fill="white"
      />
      <path
        d="M92.3076 5.79087C91.8242 5.79087 91.479 5.65025 91.1337 5.2987C90.7885 4.94716 90.6504 4.5253 90.6504 4.10344C90.6504 3.61128 90.7885 3.25973 91.1337 2.90818C91.479 2.55663 91.8933 2.41602 92.3076 2.41602C92.7909 2.41602 93.1361 2.55663 93.4814 2.90818C93.8266 3.25973 93.9647 3.68159 93.9647 4.10344C93.9647 4.59561 93.8266 4.94716 93.4814 5.2987C93.1361 5.65025 92.7218 5.79087 92.3076 5.79087Z"
        fill="white"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1976_18792"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14.2342 19.0761) rotate(179.509) scale(4.73904 4.6053)"
        >
          <stop offset="0.2807" stopColor="white" stopOpacity="0" />
          <stop offset="0.9239" stopColor="white" stopOpacity="0.9" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_1976_18792"
          x1="14.038"
          y1="16.5674"
          x2="7.47567"
          y2="16.5093"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <radialGradient
          id="paint2_radial_1976_18792"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.4668 19.1032) scale(4.61428 4.59251)"
        >
          <stop offset="0.2807" stopColor="white" stopOpacity="0" />
          <stop offset="0.9239" stopColor="white" stopOpacity="0.9" />
        </radialGradient>
        <linearGradient
          id="paint3_linear_1976_18792"
          x1="13.6795"
          y1="16.5999"
          x2="20.0703"
          y2="16.5999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1976_18792"
          x1="11.0383"
          y1="15.4669"
          x2="16.6485"
          y2="15.4669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};
