import RestaurantSelect from 'components/yumminn/RestaurantSelect';
import MenuHeader from 'pages/home/components/overview/components/MenuHeader';
import React from 'react';
import { useModifiers } from './useModifiers';
import { ModifiersContainer } from './ModifiersContainers';
import { ModifiersTable } from './ModifiersTable';
import { ModifierCategories } from './ModifierCategories';

const Modifiers = () => {
  const {
    modifierCategories,
    modifiers,
    tpv,
    selectedCategory,
    setSelectedCategory,
    mutateCat,
    mutateMod,
    selectedRestaurantId,
  } = useModifiers();

  return (
    <ModifiersContainer>
      <MenuHeader title={'modifiers'} route_group={'menu'}/>
      <header>
        <RestaurantSelect filter={'show_order_and_pay_tab_on_backoffice'}/>
      </header>
      <ModifierCategories
        modifierCategories={modifierCategories}
        selectedRestaurantId={selectedRestaurantId}
        selectedCategory={selectedCategory}
        modifiers={modifiers}
        tpv={tpv}
        setSelectedCategory={setSelectedCategory}
        mutateCat={mutateCat}
      />
      <ModifiersTable
        modifiers={modifiers}
        selectedCategory={selectedCategory}
        tpv={tpv}
        mutateMod={mutateMod}
      />
    </ModifiersContainer>
  );
};

export default Modifiers;