import React from 'react';
import { awareDatetimeString } from 'logic/functions';
import { useTranslation } from "react-i18next";

const OrderStatusHandler = ({order, changeStatus, statusLoading}) => {

  const { t } = useTranslation();
  const table_type = order.type === "table"

  const getButtonLabel = (status, type) => {
	switch (status) {
		case "received":
			switch (type) {
				case "take_away":
					return ["ready_to_pick_up", t("ready_to_pick_up")];
				case "delivery":
					return ["closed", t("deliver_order")];
				default:
		  			return ["closed", t("delivered")];	
			};
		case "error_sending_info_to_pos":
			switch (type) {
				case "take_away":
					return ["ready_to_pick_up", t("ready_to_pick_up")];
				case "delivery":
					return ["closed", t("deliver_order")];
				default:
		  			return ["closed", t("delivered")];	
			};
		case "ready_to_pick_up":
			return ["closed", t("deliver_order")];
		default:
			return ["closed", t("delivered")];
	  }
	};

  const handleChange = (order, cancel) => {
	let new_state = null
	if (cancel) {
		new_state = order.type !== "take_away" ? "received" : "ready_to_pick_up"
	} else {
		new_state = getButtonLabel(order.status, order.type)[0]
	}
	changeStatus(order.id, new_state)
  }

  return (
    <div style={{display: 'flex', marginTop: "15px", justifyContent: 'space-between', alignItems: 'center'}}>
		{order.status !== "pending_cash" &&
		order.status !== "closed" ? 
		<div style={{textAlign: 'center', justifyContent: 'center', display: 'flex', backgroundColor: table_type ? "#5FB894" : "#094553", color:"#FFFFFF", padding: '10px 0px', width: "250px", borderRadius: "99999px", fontSize: '14px', fontWeight: 700, userSelect: 'none', cursor: 'pointer'}} onClick={() => handleChange(order, false)}>
			{statusLoading ? 
			    <div className="loader-wheel" style={{height: '21px', width: '21px', margin: 0}}></div>
			: 
			getButtonLabel(order.status, order.type)[1]
			}
		</div>
  		: 
		<div style={{textDecoration: "underline", fontSize: '14px', fontWeight: 500, userSelect: 'none', cursor: 'pointer'}} onClick={() => handleChange(order, true)}>{t("cancel_delivery")}</div>
		}
		{order.ready_at && order.type !== "table" && 
		<div style={{fontWeight: 500}}>
			<div style={{fontSize: "13px"}}>{t("promised_delivery")}</div>
			<div style={{fontSize: "15px"}}>🕚 {awareDatetimeString(order.ready_at, "Europe/Madrid", true)} - {awareDatetimeString(order.max_ready_at, "Europe/Madrid", true)}</div>
		</div>}
	</div>
  );
};
export default OrderStatusHandler;
