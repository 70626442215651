import React from 'react';

const OrderProductNotes = ({note}) => {

  return (
    <div style={{marginTop: "15px", fontWeight: 400, border: "1px solid #D4D4D4", padding: "10px", borderRadius: "10px", backgroundColor: "#FBFBFB", marginLeft: "20px"}}>
      💬 <span style={{fontStyle: "italic", fontWeight: 600}}>{note}</span>
	  </div>
  );
};
export default OrderProductNotes;
