import { useState, useEffect } from 'react';
import { baseAuth, creditUrl } from 'logic/api';
import { format } from "date-fns";

export const UseRestaurantTopUpInfo = (restaurantId) => {
    const [content, setContent] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!restaurantId) return;

        const fetchData = async () => {
            setLoading(true);
            try {
                const { data } = await baseAuth(`/credit/list_credits/${restaurantId}/`, { method: 'GET' });
                setContent(data);
            } catch (error) {
                console.error('Error fetching top-up info:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [restaurantId]);

    return { content, loading };
};

export const createCreditInfo = async (params) => {

    let loading = true;
    let errorMsg = null;
    let updated = false;
    const obj = {
        ...(params.restaurant_id && { restaurant_id: params.restaurant_id }),
        ...(params.top_up_values && { top_up_values: params.top_up_values })
    }
    try {
        loading = true;
        const response = await creditUrl.post(`/create_restaurant_credit_and_credit`, obj);
        updated = response.data;
    } catch (error) {
        errorMsg = error
    } finally {
        loading = false
    }

    return { updated, loading, errorMsg };

}

export const updateCreditInfo = async (params) => {

    let loading = true;
    let errorMsg = null;
    let updated = false;
    const obj = {
        ...(params.top_up_values && { top_up_values: params.top_up_values })
    }
    try {
        loading = true;
        const response = await creditUrl.patch(`/update_restaurant_credit_and_credit`, obj);
        updated = response.data;
    } catch (error) {
        errorMsg = error
    } finally {
        loading = false
    }

    return { updated, loading, errorMsg };

}

export const UseUsersWalletInfo = (restaurantId, selectedDate, selectedDateTo, page, recommendationFilters) => {
    const [content, setContent] = useState([]);
    const [loading, setLoading] = useState(true);
  
    let endpoint = `/credit/credit_users_list/${restaurantId}`;

    const client_emails = [];
    const recharge_ids = [];   
  
    recommendationFilters.forEach(recommendation => {
      if (!recommendation || !recommendation.type || !recommendation.value) return;
  
      const type = recommendation.type;
      const value = recommendation.value;

      if (type === 'client_email') {
        client_emails.push(value);
      } else if (type === 'recharge_ids') {
        recharge_ids.push(value);
      }
    });

    if(selectedDate && selectedDateTo){
        const from = format(selectedDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
        const to = format(selectedDateTo, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");  
        endpoint += `?date_from=${from}&date_to=${to}&page=${page}`;
    }
    if (client_emails.length > 0) {
      endpoint += `&client_emails=${JSON.stringify(client_emails)}`;
    }
    if (recharge_ids.length > 0) {
      endpoint += `&recharge_ids=${JSON.stringify(recharge_ids)}`;
    }


    useEffect(() => {
        if (!restaurantId || !endpoint || !selectedDate || !selectedDateTo) return;

        const fetchData = async () => {
            setLoading(true);
            try {
                const { data } = await baseAuth(endpoint, { method: 'GET' });
                setContent(data);
            } catch (error) {
                console.error('Error fetching users wallet info:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [restaurantId, selectedDate, selectedDateTo, endpoint, recommendationFilters]);

    return { content, loading };
};


export const UseHistoryOfRecharges = (selectedDate, selectedDateTo, clientEmail, isOpenHistory) => {
    const [content, setContent] = useState([]);
    const [loading, setLoading] = useState(true);

    let endpoint = `/credit/history_of_recharges?client_email=${clientEmail}`

    if(selectedDate && selectedDateTo){
        const from = format(selectedDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
        const to = format(selectedDateTo, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");  
        endpoint += `&date_from=${from}&date_to=${to}`;
    }

    useEffect(() => {
        if (!selectedDate || !selectedDateTo || !clientEmail || !isOpenHistory) return;

        const fetchData = async () => {
            setLoading(true);
            try {
                const { data } = await baseAuth(endpoint, { method: 'GET' });
                setContent(data);
            } catch (error) {
                console.error('Error fetching history of recharges:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [selectedDate, selectedDateTo, endpoint, clientEmail, isOpenHistory]);

    return { content, loading };
}

