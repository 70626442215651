import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const Pagination = ({
  page = 0,
  numOfPages = 0,
  numOfEntries = 0,
  entryName = 'entry',
  className = '',
  disabled = false,
  setPage
}) => {
  const { t } = useTranslation();

  const onClick = event => !disabled && setPage(Number(event.target.innerText));
  const next = () => !disabled && page < numOfPages && setPage(prev => prev + 1);
  const prev = () => !disabled && page > 1 && setPage(prev => prev - 1);

  return (
    <PaginationContainer className={`${disabled ? 'disabled' : ''} ${className}`}>
      <button disabled={page < 2} onClick={prev}>
        <ChevronLeftIcon/>
      </button>
      {page > 2 &&
        <button onClick={onClick}>
          {1}
        </button>
      }
      {page > 3 &&
        <span>...</span>
      }
      {page > 1 &&
        <button onClick={onClick}>
          {page - 1}
        </button>
      }
      <button className='current'>
        {page}
      </button>
      {page < numOfPages &&
        <button onClick={onClick}>
          {page + 1}
        </button>
      }
      {page < numOfPages - 2 &&
        <span>...</span>
      }
      {page < numOfPages - 1 &&
        <button onClick={onClick}>
          {numOfPages}
        </button>
      }
      <button disabled={page >= numOfPages} onClick={next}>
        <ChevronRightIcon/>
      </button>
      <span>{numOfEntries} {t(entryName, {count: numOfEntries})}</span>
    </PaginationContainer>
  );
};

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  button {
    --color: #094553;
    --bg_color: #FFF;
    --border_color: #09455326;

    &:hover {
      --bg_color: #F3FFF9;
      --border_color: #4ED9B7
    }

    &.current {
      --color: #FFF;
      --bg_color: #094553;
      --border_color: #094553;
      
      pointer-events: none;
    }

    &:disabled {
      --color: #BFBFBF;
      --bg_color: #F6F6F6;
      --border_color: #D1D1D1;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.875rem;
    min-width: 1.875rem;
    font-size: 1rem;
    font-weight: 600;
    color: var(--color);
    background-color: var(--bg_color);
    border: 1px solid var(--border_color);
    border-radius: 1rem;
    padding: 4px;
    transition: 0.15s ease-in-out;
    transition-property: color, background-color, border-color, translate, box-shadow;

    &:focus {
      outline: none;
    }

    &:not(:disabled):hover, &:focus-visible {
      translate: 0 -2px;
      box-shadow: 0 2px 0 #0002;
    }

    &:focus-visible {
      outline: 2px solid #094553;
      outline-offset: 2px;
    }

    &:not(:disabled):active {
      translate: 0 2px;
      box-shadow: 0 0 0 #0002, inset 0 2px 0 #0002;
    }

    svg {
      height: 1.25em;

      path {
        stroke-width: 0.15em
      };
    }
  }

  span {
    font-size: 1rem;
    font-weight: 600;
  }
`;