import React, { useState, useEffect, useRef } from "react";
import Updates from "../Updates";
import { NewFeatures } from "../NewFeatures";
import { orderAndPay } from "logic/api";
import Spinner from "common/Spinner";
import { useTranslation } from "react-i18next";
import { Context as AuthContext } from '../../../../data/authContext';

export const NewsHome = () => {

    const { t } = useTranslation();
    const {
		state: { selectedRestaurant, selectedRestaurantId }
	} = React.useContext(AuthContext);
    const isMounted = useRef(true);
    
    const [loading, setLoading] = useState(true);
    const [imagePercentage, setImagePercentage] = useState(0);
    const [functionalities, setFunctionalities] = useState([]);
	
    useEffect(() => {
        isMounted.current = true;
    
        const getData = async () => {
            if (isMounted.current) {
                try {
                    const response = await orderAndPay.post(`/calculate_product_image_percentage`, { restaurant_id: selectedRestaurantId });
                    const image_percentage = response.data.image_percentage;
                    const restaurantFunctionalities = response.data.restaurant_functionalities;
                    setImagePercentage(image_percentage);
                    setFunctionalities(restaurantFunctionalities);
                } catch (e) {
                    console.log(`Error in NewsHome: ${e}`);
                }
            }
    
            if (isMounted.current) {
                setLoading(false);
            }
        };
    
        //console.log(selectedRestaurantId, 'NewsHome:useEffect[selectedRestaurantId] ~ selectedRestaurantId');

        selectedRestaurantId && getData();
    
        return () => {
            isMounted.current = false;
        };
    }, [selectedRestaurantId]);

	return(
		<>
        {loading || !selectedRestaurantId ?
            <>
                <div className="h-full w-full top-0 left-0 absolute z-9999 flex flex-col justify-center items-center">
                    <Spinner>{t("Loading")}</Spinner>
                </div>
                <div className="bg-black opacity-20 top-0 left-0 fixed overflow-y-auto h-full w-full z-9999 flex flex-col justify-center items-center"/>
            </> :
            <>
                <Updates/>
                <NewFeatures imagePercentage={imagePercentage} functionalities={functionalities} isOC={selectedRestaurant[0]?.show_order_and_pay_tab_on_backoffice} selectedRestaurantId={selectedRestaurantId}/>
            </>
        }
		</>
        
	)
}