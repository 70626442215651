const info = {
  "info_new": "Registered users",
  "info_recurrent": "Registered users who have made more than one payment",
  "info_users_who_paid": "Registered users who have made one or more payments",
  "info_payment_rates_per_user": "Payout percentages per user",
  "info_cashback_granted": "Total cashback generated",
  "info_cashback_redeemed": "Cashback used in payments",
  "info_points_granted": "Total points generated",
  "info_total_reviews": "According to the selected period",
  "info_average_stars": "Average rating of all restaurants",
  "info_today": "Total payouts of",
  "info_last_week": "Total payouts in the current week",
  "info_last_month": "Total payouts in the current month",
  "info_accumulated": "Total payments with Yumminn",
  "info_period_payments": "Total payments from {{from}} to {{to}}. Tips included.",
  "info_period_tips": "Total tips from {{from}} to {{to}}",
  "info_period_ratings": "Ratings {{from}} to {{to}}}",
  "info_period_restaurants": "Restaurants from {{from}} to {{to}}",
  "info_period_loyalty": "Loyalty from {{from}} to {{to}}",
  "info_active_users": "Records of registered users who have paid",
  "info_restaurants_with_reviews": "See details in the list of restaurants or in the reviews section.",
  "info_restaurants_with_cashback": "Restaurants with active loyalty",
  "info_avg_cashback_per_user": "Average current cashback per user",
  "info_avg_points_per_user": "Average current points per user",
  "info_with_payments": "Restaurants with payments in relation to the total database",
  "info_payments_per_restaurant": "Average payouts per restaurant",
  "info_avg_payments_with_cashback": "Average payments using cashback",
  "info_new_users": "New users",
  "info_recurrent_users": "Recurrent users",
  "info_payments_with_review": "Number of reviews after payment",
  "info_registered_users": "New registered users and recurrent",
  "info_restaurant_group_visibility": "Visibility of the restaurant on the group's home screen",
  "info_locker_counter": "Use the counter as an alternative to lockers if necessary (if you do not have lockers available or if an order does not fit in the locker). The counter can hold as many orders as you need.",
  "info_locker_active_disabled": "You cannot deactivate a locker with orders.",
  "info_cross_sell_products": "There should be as many products as possible, with a minimum of 4 products. The first 4 products will be displayed, excluding the diner's selection.",
  "info_default_language": "We will use this language to show the letter when there are no translations available in other languages.",
  "info_available_languages": "Are the languages that the users will have available in the webapp",
  info_kitchen_saturated: "Inform your diners of the delay or temporarily disable orders from here. <1>More info in this tutorial</1>.",
  info_kitchen_saturated_delay: "Set delay time: {{minutes}} minutes.",
  info_kitchen_saturated_pause: "The diner will be able to see the menu, but will not be able to place an order during this time.",
  info_pause_undefined: "Orders will be paused indefinitely. You will have to activate them manually.",
  info_modifier_group: "<0>Create groups</0> of modifiers to <2>organise your product options</2>. For example: <4>'Meat point'</4> or <6>'Extras'</6>.",
  info_modifier_list: "Create modifiers to <1>customise products</1> and <3>promote additional sales</3>.",
  "available_languages": "Available languages",
  "available_languages_saved": "Available languages updated",
  "available_languages_error": "Error while updating available languages",
  "not_remove_default_language": "Is not possible to remove the default language",
  "ai_translation_error": "Something went wrong while translating the menu",
  "default_language_saved": "Default language saved",
};

export const englishTranslationObject = {
  ...info,
  January: "January",
  February: "February",
  March: "March",
  April: "April",
  May: "May",
  June: "June",
  July: "July",
  August: "August",
  September: "September",
  October: "October",
  November: "November",
  December: "December",
  New_restaurant: "New Restaurant",
  New_company: "New Company",
  Restaurant_Name: "Restaurant Name",
  Company_Name: "Company Name",
  Adyen_account_holder_code: "Adyen account holder code",
  Country_code: "Country code",
  Currency_code: "Currency code",
  id: "ID",
  Owner: "Owner",
  Company: "Company",
  Options: "Options",
  save: "Save",
  edit: "Edit",
  delete: "Delete",
  Merchant_Account: "Merchant account",
  Merchant_category_code: "Merchant category code",
  restaurant_deleted: "Restaurant deleted",
  company_deleted: "Company deleted",
  Adyen_config: "Adyen configuration",
  Avg_Rating_Mobile: "Avg rating",
  tpv_info: "tpv info",
  tpv: "Tpv",
  Dashboard: "Dashboard",
  "Avg": "Avg.",
  "Previous avg": "Previous avg.",
  "Previous period": "Previous period",
  "Current period": "Current period",
  save_group_first: "Save group first!",
  paid: "paid",
  "payment experience": "Payment experience",
  'days of the month': "Days of the current month",
  "previous day": "previous day",
  "day": "day",
  "previous day average": "Previous day average",
  "Months": "Months",
  "Date": "Date",
  "Todos": "All",
  "Payments with reviews": "payments with review",
  "Payments with tips": "payments with tips",
  "Is not admin or investor": "The user is not admin or investor",
  "Invalid sent data": "Wrong user or password",
  Value: "Payment volume",
  "Start_Date": "Start date",
  "End_Date:": "End date",
  "Error_POS_explanation": "The payment was successful <1>but didn't get registered in the POS due to an unfortunate error</1>. Please enter the payment manually in the POS.",
  "Variation": "Variation",
  Avg_Ticket: "Avg ticket",
  "Create new company": "Create new company",
  "Create new restaurant": "Create new restaurant",
  Last_30_days: "Last 30 days",
  Last_7_days: "Last 7 days",
  "tips vs payment": "tips vs. payments",
  Restaurants: "Restaurants",
  Tips: "Tips",
  Payments: "Payments",
  Yumminn_Payments: "Payment summary",
  Today: "Today",
  whole_catalog_discount: 'All catalog discount',
  code_coupon_discount: 'Discount code coupon',
  no_discount_added: "No discount added",
  product_promotion: 'Product discount',
  "Data today": "Daily data",
  "Loyalty total granted": "Total loyalty credits granted",
  "Loyalty used": "Loyalty credits used",
  "Loyalty unused": "Loyalty credits unused",
  "loyalty program information": "Loyalty program information",
  "Minimum to grant": "Minimum euros to grant credits",
  "Minimum to redeem": "Minimum euros to redeem credits",
  "Earning percentage": "Percentage to earn credits",
  "Loyalty program enabled": "Loyalty program enabled",
  "Loyalty program disabled": "Loyalty program disabled",
  Add_new_menu: "Add new menu",
  "Reports email": "Reports email",
  Transactions: "Transactions",
  "Search table": "Search table",
  "Search sector": "Search sector",
  "No payments": "No transactions found for this search",
  Status: "Status",
  "Date and hour": "Date and time",
  "Visibility schedule": "Visibility schedule",
  "Always visible": "Always visible",
  "Modified": "Modified",
  "Active": "Active",
  "Back": "Back",
  "No menus added yet": "No menus added yet",
  "Internal_menus": "Internal menus",
  "External_menu": "External menus",
  "New_menu": "New menu",
  "Upload PDF file with menu": "Upload PDF file with menu",
  Revenue: "Revenue",
  "excluding tips": "excluding tips",
  "All day": "All day",
  to: "to",
  "Everyday": "Everyday",
  Delete_schedule: "Delete schedule",
  Delete_time_range: "Delete time range",
  "Select days": "Select days",
  "Select time range": "Select time range",
  "Type": "Type",
  "Add time range": "Add time range",
  "Add new schedule": "Add new schedule",
  "Add a menu name": "Add a menu name",
  Daily: "24 hours",
  Commission: "Commission",
  "About enable or disable": "Enable or disable 'about'",
  "You cannot add a new menu schedule": "You cannot add a new menu schedule without selecting at least one day in the schedule you are already editing!",
  About: "About",
  Business_Name: "Business name",
  "Loyalty": "Loyalty Program",
  Ratings_and_reviews: "Ratings",
  Reviews: "Reviews",
  Export_Csv: "Export CSV",
  Next: "Next",
  Previous: "Previous",
  "Top_restaurants": "Top restaurants",
  "hours": "hours",
  "Days": "Days",
  "days": "days",
  "weeks": "weeks",
  "months": "months",
  "Years": "Years",
  "avg_tips_explanation": "This is the total amount of tips divided by the total number of tips for the chosen period.",
  "payments_explanation": "It is the sum of the amount of payments including tips for the chosen period.",
  "avg_payments_explanation": "It is the division of the total sum of all payments of the chosen period by the total days of said period.",
  'avg_ticket_explanation': "It is the division of the total sum of all the payments of the chosen period by the sum of transactions of said period.",
  'tips_explanation': "This is the sum of the total amount of tips for the chosen period.",
  'transactions_explanation': "This is the sum of the number of transactions made during the chosen period.",
  'avg_transactions_explanation': "It is the sum of the number of transactions carried out during the chosen period divided by the number of days of said period. If the period is 'Months', it is the average per month. If the period is 'Years', it is the average per year, and if it is the '24 hours' period, it is the average per hour",
  'reviews_explanation': "It is the sum of the number of reviews received during the chosen period.",
  'avg_reviews_explanation': "It is the sum of the number of reviews received during the chosen period divided by the days of said period.",
  'avg_rating_reviews_explanation': "This is the sum of the ratings received during the chosen period divided by the total number of ratings received during that period.",
  'payments_with_reviews_explanation': "It is the percentage of payments over the total of the chosen period with which they have left an assessment (review).",
  'payments_with_tips_explanation': "It is the percentage of payments over the total payments of the chosen period that include tips.",
  'tips_vs_payments_explanation': "It is the percentage of a payment that corresponds to the tip.",
  'users_explanation': "This is the total number of registered users during the chosen period.",
  'email_users_explanation': "This is the total number of users registered via email during the chosen period.",
  'google_users_explanation': "It is the total number of users registered via google during the chosen period.",
  'facebook_users_explanation': "It is the total number of users registered via facebook during the chosen period.",
  "year": "Year",
  Total: "Total",
  Loading: "Loading...",
  "Table status": "Table status",
  Product: "Product",
  Products: "Products",
  "Total bill": "Total bill",
  "Remaining": "Remaining",
  "Scan qr": "Scan the QR code to access the table feed from your device.",
  "pos error": "Not registered on POS",
  "has paid": "paid",
  "no tables": "No tables with this status",
  "Reports": "Reports",
  From: "From",
  Crm: "CRM",
  Invoices: "Invoices",
  "name": "Name",
  "surname": "Surname",
  "email": "email",
  "email_plural": "emails",
  Email_short: "Email",
  "phone": "Phone",
  "date register": "Date registered",
  "last visited": "Last visited",
  "accumulated tips": "Accumulated tips",
  "generated loyalty": "Generated loyalty",
  "used loyalty": "Loyalty used",
  "loyalty left": "Loyalty left",
  "total": "Total",
  Companies: "Companies",
  "Payments with client": "payments with user",
  "Payments with review": "payments with reviews",
  "payments_with_clients_explanation": "It is the total number of payments made with a registered and logged user",
  "clients_registered_after_payment": "users registered after payment",
  "clients_registered_after_payment_explanation": "It is the percentage of users of the total registered users that have registered just after making a payment",
  "users_registered_by_restaurant": "users registered in restaurant",
  "users_registered_by_restaurant_explanation": "It is the percentage of registered users per restaurant with respect to the total number of Yumminn users",
  "Registered users": "Registered users",
  "users_with_loyalty": 'Users with loyalty',
  "loyalty_granted": 'Loyalty granted',
  "loyalty_used": 'Loyalty used',
  "loyalty_used_explanation": "Is is the total amount of loyalty points used",
  "loyalty_granted_explanation": "Is is the total amount of loyalty points granted to users",
  "users_with_loyalty_explanation": "It is the number of users with an amount of loyalty",
  "prev_used": "Used Previous Period",
  "actual_used": "Actual Used",
  "prev_granted": "Granted Previous Period",
  "actual_granted": "Actual Granted",
  Order_and_pay: "Order and pay",
  Synchronize: "Synchronize",
  Create_category: "Create Category",
  Edit_category: "Edit Category",
  "confirm_synchronize_text_1": "If you have made product or category changes on your POS since the last synchronisation,",
  "confirm_synchronize_text_2": "they will be applied on your Yumminn menu.",
  "confirm_synchronize_text_3": "We always keep the last changes, regardless of whether they have been made on the POS or on Yumminn.",
  "TPV name": "TPV name",
  Saved_category: "Category has been created successfully!",
  Error_saving_category: "There was a problem creating your category!",
  Error_synchronize: "There was a problem synchronizing!",
  "tpv_name": "Tpv Name",
  "default": "default",
  "tpv_category": "Tpv Category",
  Description: "Description",
  Price: "Price",
  Photo: "Photo",
  "visibility_schedule": "Visibility Schedule",
  "visible": "Visible",
  "not_visible": "Not Visible",
  Visibility: "Visibility",
  Modifier: "Modifier",
  "Modifier Category": "Modifier Category",
  'payments with reviews': "Payments with reviews",
  'payments with user': "Payments with user",
  'Show all restaurants': 'Show all restaurants',
  "This restaurant has no data to collect yet": "This restaurant has no data to show yet",
  'assign': 'Assign',
  'assigned': 'Assigned',
  'confirm manager': '¿Are you sure you want to be the account manager of this restaurant?',
  'confirm change manager': '¿Do you want to put yourself as "account manager"?',
  'loyalty': 'Loyalty',
  'no restaurants with payments': 'No restaurants with payments in this period',
  "events": "Events",
  "Create_event": "Create new event",
  "amount": "Amount",
  "event_date": "Event date",
  "No_events": "No events created",
  "detail": "Detail",
  "created_event_date": "Date created",
  "Status_payment": "Payment status",
  "Hour": "Hour",
  "Concept": "Concept",
  "Occasion": "Occasion",
  "Pending": "Pending",
  Upload_Product_photos: "Upload product photo",
  "Suelta tu imagen aquí o haz clic para": "Drag your photo here or click here to",
  "Prod_max": "Maximum products",
  "Prod_min": "Minimum products",
  "Optional": "Optional",
  "No Limit": "No Limit",
  "ultima sincronizacion": "Last synchronization:",
  "Error_saving_product": "There was an error saving product",
  "Saved_product": "Product saved",
  "empty_field": "All the required fields aren't used",
  "Error_deleting_time_range": "There was a problem deleting the time range",
  "Deleted_time_range": "Deleted time range",
  "Updated_time_range": "Updated time range",
  "Error_updating_time_range": "There was a problem updating the time range",
  "Deleted_schedule": "Deleted schedule",
  "Error_deleting_schedule": "There was a problem deleting schedule",
  "saving": "Saving",
  "Updated_modifier": "Updated modifier",
  "Edit_event": "Edit event",
  "check_link": "Check link",
  confirm: "Confirm",
  Delete_main_image: "Delete main image",
  "Upload Business logo": "Upload logo",
  "forgot_password": "Forgot your password?",
  "send_order_now": "Send the order right now to the POS",
  "send_order_again": "Send the order again to the POS",
  "reprint_yumminn": "Print via Yumminn Print",
  "Print": "Print",
  delivery_address: "Delivery address",
  created: "Created",
  received: "Received",
  "ready_to_pick_up": "Ready to pick up",
  "pending_cash": "Pending cash",
  "deliver_order": "Deliver order",
  "delivered": "Entregado",
  "cancel_delivery": "Cancel delivery",
  "promised_delivery": "Promised delivery",
  finalized: "Finalized",
  Finalized: "Finalized",
  "POS error": "POS error",
  "error_sending_info_to_pos": "Error sending info to POS",
  "waiting_send_info_to_pos": "Pending to send to POS",
  closed: "Closed",
  error_pos: "POS error",
  comments: "Order general notes",
  "total_bill": "Bill total",
  "paid_with": "Paid with",
  "refund_successful": "Refund successfull",
  "adyen_refund_payment except: Cannot refund payments after 1 hour of being done.": "Cannot refund payments after 1 hour of being done",
  "Performance": "Performance",
  "Error_deploy_version": "Your Back Office version wasn't up to date, try again!",
  "eggs": "Eggs",
  "lupin": "Lupin",
  "gluten": "Gluten",
  "sesame": "Sesame",
  "mustard": "Mustard",
  "fish": "Fish",
  "celery": "Celery",
  "crustaceans": "Crustaceans",
  "peanuts": "Peanuts",
  "molluscs": "Molluscs",
  "soy": "Soy",
  "dried fruit": "Dried fruit",
  "sulphites": "Sulphites",
  "milk": "Milk",
  "Top Waiters": "Top Waiters",
  "Waiter": "Waiter",
  "All": "All",
  Main_Image_OP: "Main image Order & Pay",
  Main_Image: "Main image",
  Created_allergens: "Allergens saved",
  Error_creating_allergens: "Error saving allergens",
  Error_deleting_allergens: "Erreur deleting allergens",
  Deleted_allergens: "Allergens deleted",
  "Select one option": "Select one option",
  filtering_by: 'Filtering by',
  "catalog": "Catalog",
  languages: "Languages",
  modifier_name: "Modifier name",
  Modifier: "Modifier",
  modifier_name: "Modifier name",
  modifier_description: "Modifier description",
  modifier_category_name: "Modifier category",
  product_name: "Product name",
  product_description: "Product description",
  product_category_name: "Product category",
  category_group_name: "Secondary categories",
  search_by: "Search by",
  Client_name: "Client name",
  Address: "Address",
  order_search_input_placeholder: "#Order, Phone, Address, Email or Name",
  error_performing_search: "Error performing search",
  no_matches_found: "No matches found",
  supergroup_name: "Main categories",
  "click to sync message": "<0>Synchronize</0> your catalog <2>with just one click</2>",
  "Create shifts to be able to configure the stocks": "<0>Create shifts</0> to be able to configure the stocks",
  "Edit_group": "Edit Group",
  "Error_saving_image": "Error saving image",
  "Error deleting image": "Error deleting image",
  "Updated_category": "Category updated",
  update_ok: "Updated successfully",
  delete_ok: "Deleted successfully",
  "Updated_group": "Group updated",
  "Swap to visibility_schedule": "Confirm if you want to change visibility to 'Visibility Schedule' to use your schedules",
  "Created_time_range": "Created time schedule",
  "Error_creating_time_range": "Error creating time range",
  "Updated_schedule": "Updated time schedule",
  "Error_updating_schedule": "Error updating time range",
  translations: "Translations",
  "product_highlight": "Product highlight",
  "highlights_banners": "Highlights and banners",
  "cross_selling": "Cross selling",
  product: "Product",
  "spanish": "Spanish",
  "catalan": "Catalan",
  "english": "English",
  "french": "French",
  "add language": "Add language",
  "select language": "Select language",
  "yes": "Yes",
  "no": "No",
  "Program info": "Program information",
  "Mail list": "Mail list",
  "New time range": "New time range",
  "enabled": "enabled",
  "disabled": "disabled",
  "create": "create",
  "Delete program": "Delete program",
  "% redeem": "Credits percentage",
  "min to redeem": "Minimum consumption to redeem credits",
  "min to earn": "Minimum consumption to earn credits",
  "days and hours": "Days and hours",
  "Current": "Current",
  "total_payments": "Total payments",
  "payments": "payments",
  "loyalty_payments": "Payments with loyalty",
  "percent_loyalty_payments": "% loyalty value / total=%",
  "transactions": "transactions",
  "total_transactions": "Total transactions",
  "loyalty_transactions": "Loyalty transactions",
  "percent_loyalty_transactions": "% loyalty value / total=%",
  "transactions_with_loyalty_per_user": "# transactions by users with loyalty",
  "users_who_repeated": "# repeat users",
  "avg_ticket": "Average ticket",
  "granted_credits": "Total credits granted",
  "used_credits": "Credits used",
  "unused_credits": "Credits not used",
  "an_error_has_occurred": "An error has occurred",
  "Customization": "Customization",
  "button_color": "Button color",
  "accent_coler": "Accent color",
  "background_color": "Cover color",
  "show only unfinished translations": "Unfinished translations",
  "no_translations_message": "Please go to the Catalog and save a name or description to translate them",
  "show more": "Show more",
  "tpv_description": "POS description",
  "tpv_value_placeholder": "Not applicable - Category created in Yumminn",
  "refunded": "Refunded",
  "see here": "See here",
  "Sector": "Sector",
  "Method": "Method",
  "register payment on tpv": "Register payment on pos",
  "pos": "point of service",
  "payment charged": "Payment charged",
  "UE Commission": "EU Commission",
  "Not UE Commission": "Not EU Commission",
  "latitude": "Latitude",
  "longitude": "Longitude",
  "invalid_value": "Invalid value",
  "logo_size_changed": "Logo size changed",
  "color_changed": "Color changed",
  "logo_size": "Relative logo size",
  "optional": "optional",
  "Orders activated": "Webapp products are available for customer orders",
  "Orders deactivated": "The products are not available to order by the customer, through the webapp",
  "Toggle orders with click": "{{toggle}} orders with one click",
  "Activate": "Activate",
  "Deactivate": "Deactivate",
  "activate": "Activate",
  "deactivate": "deactivate",
  "Apple users": "Apple users",
  "Enabled": "Enabled",
  "Disabled": "Disabled",
  prev_clients_who_repeated: "Previous recurrences",
  prev_clients_who_payed_just_once: "New previous",
  clients_who_repeated: "Recurrent",
  clients_who_payed_just_once: "New",
  clients_who_repeated_actual: "Recurrent",
  clients_who_repeated_prev: "Previous Recurrent",
  clients_who_payed_just_once_prev: "Previous New",
  hide_difference: "Hide difference",
  view_difference: "View difference",
  "Do_Refund": "Please, write the amount to refund",
  home_customization: "Home customization",
  preview: "Preview",
  logo: "Logo",
  size: "Size",
  home_image: "Background photo or gif",
  overlay: "Overlay",
  color_button: "Button color",
  color_secondary: "Secondary color",
  color_cover: "Cover screen color",
  suggestion_logo: "Suggestions: White and without background (png)",
  opcional: "optional",
  hidden: "Hidden",
  cover: "Cover",
  home: "Home",
  changes_applied: "Changes applied",
  menu_button_label: "Check the menu",
  pay_button_label: "View account and pay",
  table: "Table",
  at_table: "At table",
  take_away: "Take away",
  delivery: "Delivery",
  menu: "Menu",
  payment: "Payment",
  reward: "Reward",
  contrast_good: "Correct contrast",
  contrast_minimum: "Minimum contrast",
  contrast_bad: "Insufficient contrast",
  "average": "Average",
  "tips": "tips",
  "users": "users",
  "active_users": "active users",
  "new": "New",
  "recurrent": "Returning users",
  "no_recurrent": "Non-reminders",
  "users_who_paid": "Paid Users",
  "payment_rates_per_user": "Percentage payments with user",
  "cashback_granted": "Cashback granted",
  "cashback_redeemed": "Cashback redeemed",
  "points_granted": "Points granted",
  "total_reviews": "No. of total reviews",
  "num_reviews": "No. of reviews",
  "average_stars": "Average in restaurants",
  "average_ticket": "Average ticket",
  "average_tip": "Average Tips",
  "accumulated": "Accumulated",
  "today": "Today",
  "last_week": "Current week",
  "last_month": "Current month",
  "payments_period": "Payments of period",
  "total_period": "Total of period",
  "last_period": "Last period",
  "payments_with_tip": "Payments with tip",
  "tip_on_payment": "Tips over payments",
  "show_percentages": "Show percentages",
  "show_totals": "Show totals",
  "current_week": "Current <1>Week</1>",
  "current_month": "Current <1>Month</1>",
  "current_year": "Current <1>Year</1>",
  "total_week": "Weekly total",
  "total_month": "Monthly total",
  "total_year": "Yearly total",
  "historic_total": "Historic total",
  "restaurants": "restaurants",
  "ratings": "ratings",
  "without_payments": "Without payments",
  "restaurants_with_reviews": "Restaurants with activated ratings",
  "restaurants_with_cashback": "Restaurants with cashback",
  "avg_cashback_per_user": "Average current cashback per user",
  "avg_points_per_user": "Average points per user",
  "info": "Info",
  discount_info_box: "You will soon be able to apply discounts on products!",
  "SUPER_GROUP": "Main categories",
  "CATEGORY_GROUP": "Secondary categories",
  "CATEGORY": "Product categories",
  "Create_SUPER_GROUP": "New main category",
  "Create_CATEGORY_GROUP": "New secondary category",
  "Create_CATEGORY": "New product category",
  "Edit_SUPER_GROUP": "Edit main category",
  "Edit_CATEGORY_GROUP": "Edit secondary category",
  "Edit_CATEGORY": "Edit product category",
  "Info_SUPER_GROUP": "Create up to 3 main categories, to facilitate the navigation of the diner",
  "Info_CATEGORY_GROUP": "Create secondary categories, to group your products",
  "Info_CATEGORY": "Create categories, to group your products",
  "name_SUPER_GROUP": "Primary category name",
  "name_CATEGORY_GROUP": "Secondary category name",
  "name_CATEGORY": "Product category name",
  "name_schedule": "Timetable name",
  "select_children_SUPER_GROUP": "Select secondary categories",
  "select_children_CATEGORY_GROUP": "Select product categories",
  "select_children_CATEGORY": "Select the products",
  "web_preview": "Web preview",
  "define_schedule": "Define schedule",
  "every_day": "Every day",
  "weekend": "Weekend",
  "mon": "Mon",
  "tue": "Tue",
  "wed": "Wed",
  "thu": "Thu",
  "fri": "Fri",
  "sat": "Sat",
  "sun": "Sun",
  "from": "from",
  "from_(short)": "from",
  "select_days": "Select days",
  "add_time_range": "Add another time slot",
  "you_have_unsaved_changes": "You have unsaved changes",
  "warning_message_unsaved_changes": "If you close this window, you will lose your changes.",
  "keep_editing": "Continue editing",
  "close": "Close",
  "group": "Group",
  "category": "Category",
  "schedule": "Schedule",
  "specs": "Specifications",
  "visualize": "Visualize",
  "schedules": "Visibility and opening hours",
  "explanation_schedules_active": "All time ranges (day/time) of all active schedules will be <1>complemented</1>. If you want to hide a time range, make sure that it doesn't appear in any active schedule.",
  "explanation_schedules_inactive": "The category <1>is not visible</1> at the moment. To make it visible to your customers, activate it at the top right of this panel.",
  "explanation_schedule_SUPER_GROUP": "<b>By default</b>, this schedule <b>will apply to all secondary categories and product categories</b> within this main category. You can modify the <b>exceptions</b> by editing the corresponding category.",
  "explanation_schedule_CATEGORY_GROUP": "<b>By default</b>, this schedule <b>will apply to all product categories</b> within this secondary category. You can modify the <b>exceptions</b> by editing the corresponding category.",
  "explanation_schedule_CATEGORY": "<b>By default</b>, this schedule <b>will apply to all products</b> within this category. You can modify the <b>exceptions</b> by editing the corresponding product.",
  "explanation_disabled_select_SUPER_GROUP": "<0>To add or remove secondary categories</0> to this main category, <2>drag them from the</2> <4>catalogue overview</4>.",
  "explanation_disabled_select_CATEGORY_GROUP": "<0>To add or remove product categories</0> to this secondary category, <2>drag them from the</2> <4>catalogue overview</4>.",
  "title_empty_schedules_active": "The category will be <1>visible</1> during your entire opening hours",
  "title_empty_schedules_inactive": "The category <1>is not visible</1> at the moment",
  "explanation_empty_schedules_active": "If you want to customise the visibility, you can <1>define a schedule or disable the category</1> at the top right.",
  "explanation_empty_schedules_inactive": "To make it visible to your customers, <1>activate</1> it at the top right of this panel.",
  "current": "current",
  "previous": "previous",
  "users_with_payments": "users with payments",
  forgot_password_text: "Retrieve password",
  forgot_email_sent: "Check your email for instructions",
  forgot_password_explanation: "Enter the address used to register, and if it's in our system, we will send an email with the steps to follow.",
  send_reset_link: "Send reset link.",
  change_password_text: "Choose your new password",
  client: "Client",
  total_clients_registered: "Total clientes registrados",
  "Total registered on restaurant": "Total registered on restaurant",
  avg_payments_with_cashback: 'Avg. payments with cashback',
  new_users: 'New users',
  recurrent_users: "Recurrent users",
  payments_with_review: 'Payments with review',
  registered_users: 'Registered users',
  tables_and_sectors: 'Tables and sectors',
  categories_and_products: 'Products and categories',
  my_solution: 'My solution',
  title_discount: 'Catalog banner title',
  name_discount: 'Discount name on payment details',
  payment_details_text: 'Payment details text',
  metrics: 'Metrics',
  'You dont have this functionality message': "Improve <0>efficiency</0>, your average ticket and the experience of your diners with the <1>Order</1> functionality!",
  Contact_our_team: "Contact our team",
  'Payments and orders': 'Payments and orders',
  Restaurant: "Restaurant",
  synchronize: "Synchronize",
  Logout: "Logout",
  QR_Codes: "QR Codes",
  Table_statuses: "Table statuses",
  Shifts: "Shifts",
  Orders: "Orders",
  Booking: "Booking",
  Bookings: "Bookins",
  booking: "booking",
  bookings: "bookings",
  diner: "diner",
  creation_datetime: "Creation datetime",
  booking_period: "Booking period",
  creation_period: "Creation period",
  booking_datetime: "Booking datetime",
  payments_per_restaurant: "Payments per restaurant",
  with_payments: "With payments",
  customer_name: "Customer name",
  email_address: "Email address",
  pay_with_qr: "Pay with QR",
  Highlight_products: "Highlight products",
  discounts: "Discounts",
  "granted": "granted",
  "redeemed": "redeemed",
  promotions: "Promotions",
  coupons: "Coupons",
  add: "Add",
  promo_explanation_title: "How do the promotions work?",
  example_discount_name: "5% general discount",
  example_general_discount_marquee_text: "-5% OFF THE ENTIRE MENU!",
  example_general_discount_payment_details_text: "5% general discount",
  marquee_text: "Menu marquee text",
  discount_name: "Discount name",
  general_discount: "general discount",
  code_coupon: "code coupon",
  texts: "Texts",
  typology: "Discount typology",
  discount_value: "Discount value",
  applied_for: "Applied for",
  base_amount: "Base amount",
  promos: "Promos",
  coupon: "Coupon",
  total_base_amount: "Total base amount",
  total_amount: "Volume of payments",
  payment_amount: "Number of payments",
  do_you_have_a_code_coupon: "Do you have a code coupon?",
  remove_category: "Remove category",
  are_you_sure_to_remove: "Are you sure you want to remove this category?",
  "no, cancel": "No, cancel",
  "yes, remove": "Yes, remove",
  do_you_want_to_remove_this_category: "Do you want to remove this category?",
  how_to_remove_category: "In order to remove this category, make sure you have moved your products to other categories first",
  alright: "Alright",
  add_new: "Add new",
  add_new_m: "Add new",
  add_new_f: "Add new",
  see_photo: "See photo",
  Updates: "New Features",
  "your week in yumminn": "Your <1>week</1> in Yumminn",
  "maximize your solution": "<0>Maximize</0> your solution",
  see_all: "See all",
  time_saved_for_waiters: "Time saved for waiters",
  time_saved_for_diners: "Time saved for diners",
  total_payments_of_the_last_30_days: "Total payments of the last 30 days",
  new_users_of_the_last_30_days: "New users of the last 30 days",
  new_reviews_of_the_last_30_days: "New reviews of the last 30 days",
  general_metrics: "General dashboard",
  product_metrics: "Product dashboard",
  stock_item_search_input_placeholder: "#Name",
  edit_menu: "Edit menu",
  see_dashboard: "See general dashboard",
  last_30_days_with_yumminn: "Last 30 days with your Yumminn solution",
  products_list: "List of products",
  add_new_product: "Add new product",
  title_empty_category: "This category is empty",
  message_empty_category: "Create a new product or add from other categories",
  Create_product: "Create product",
  Deleted_supergroup: "Main category eliminated",
  Deleted_group: "Secondary category removed",
  Deleted_category: "Product category removed",
  Deleted_product: "Product removed",
  Deleted_modifier: "Modifier removed",
  remove_product: "Remove product",
  are_you_sure_to_remove_product: "Are you sure you want to remove this product?",
  info_visibility_switch_disabled: "Empty categories will not appear in the menu",
  remove_category_not_allowed: "This category cannot be deleted",
  title_empty_menu: "This menu is empty",
  message_empty_menu: "Start by creating a main category to make it easier for the customer to navigate.",
  creating_product_title: "Creating product",
  creating_product_message: "Soon your customers will enjoy this new gastronomic proposal",
  theme: 'Theme',
  light: 'Light',
  cream: 'Cream',
  dark: 'Dark',
  blue: 'Blue',
  blue_linear: 'Linear blue',
  superdark: 'Black',
  superdark_linear: 'Linear black',
  your_logo: 'Your logo',
  copy_button_color: 'Copy button color',
  creating_catalog_title: 'Creating catalogue',
  creating_catalog_message: 'We are creating the first categories for your products',
  no_orders_yet: "You still have no orders",
  create_discounts_in_your_menu: "Create discounts in your menu!",
  content: "Content",
  highlight_name: "Feature product name",
  create_new_featured_product: "Create new featured product",
  create_advertising_banner: "Create advertising banner",
  increase_your_average_ticket: "<0>Increase your average ticket<1>by featuring</1></0> your products!",
  example_minimum_redeem_amount: "E.g: 25 €",
  minimum_redeem_amount: "Minimum amount to apply discount",
  cancel_booking: "Cancel booking",
  cancel_booking_confirmation: "Are you sure you want to cancel this booking?",
  cancel_booking_message: "If you wish to inform the customer of the reason for cancellation, <1>type it here</1> and we will add it to the cancellation email we will send you.",
  cancel_reason: "Reason for cancellation",
  no_keep: "No, keep",
  yes_cancel: "Yes, cancel",
  Order_scheduling: "Order scheduling",
  diners_amount: "Diners amount",
  date: "date",
  hour: "hour",
  compulsory_fields: "Compulsory fields",
  compulsory_name_error: "Please, introduce a name",
  compulsory_email_error: "Please, introduce a valid email address",
  compulsory_date_error: "Please, select a valid date",
  compulsory_hour_error: "Please, choose hour among the options",
  compulsory_diners_error: "Please, select the number of diners",
  lockers: "lockers",
  add_locker: "Create locker",
  select_order: "Select order",
  create_locker: "Create locker",
  edit_locker: "Edit locker",
  locker: "Locker",
  counter: "Counter",
  key: "Password",
  no_key: "No key",
  create_locker_error: "An error occurred while creating the locker",
  edit_locker_error: "An error occurred while updating the locker",
  delete_locker_error: "An error occurred while deleting the locker",
  order_locker_error: "An error occurred while assigning the order",
  confirm_cancel_locker_title: "Do you want to discard the changes?",
  changes_will_be_lost: "You will lose all the changes you have made",
  confirm_delete_locker_title: "Do you want to delete the locker?",
  this_cannot_be_undone: "This action cannot be undone",
  with_key: "With password",
  without_key: "Without password",
  order_management: "Order management",
  see_order_management: "See order management",
  orders_in_lockers: "Orders in lockers",
  orders_in_counter: "Orders at the counter",
  new_orders: "New orders",
  empty_lockers: "Empty lockers",
  active_lockers: "Active lockers",
  shift_time_left: "remaining of the shift",
  orders_assignables: "Assignable orders",
  orders_received: "Orders received",
  orders_in_locker: "Orders in lockers",
  assign_to_locker: "Assign to locker",
  see_and_manage: "View details and manage",
  promised_delivery_time: "Promised delivery",
  products: "Products",
  comment: "Comment",
  no_comments: "No Comments",
  total_account: "Account total",
  no_active_shift: "There are currently no active shifts",
  manage_order_title: "What do you want to do<1/><2>with the order {{code}}</2>?",
  confirm_pickup_title: "Has the previous order<1/><2>already been withdrawn</2>?",
  select_an_option: "Select an option",
  withdrawn: "Already withdrawn",
  to_locker: "Place in locker",
  to_counter: "Take to {{counter_name}}",
  to_kitchen: "Return to kitchen",
  remove_from_counter: "Remove from {{counter_name}}",
  yes_withdrawn: "Yes, already removed",
  no_to_counter: "No, take to {{counter_name}}",
  no_to_kithcen: "No, return to kitchen",
  send_reminder: "Send reminder to customer",
  error_manage_order: "An error occurred while handling the order",
  clear: "empty",
  see_order: "view order",
  sort_by: "order by",
  newest: "Newest",
  closer: "Closer",
  farthest: "Farthest",
  oldest: "Oldest",
  pickup_time: "pick up time",
  all_slots: "All slots",
  clear_locker: "Empty locker",
  clear_all_lockers: "Empty all lockers",
  clear_all_lockers_title: "Empty all lockers",
  clear_all_lockers_message: "Continuing will <1>empty all lockers</1> and mark all orders as delivered. Are you sure you want to continue?",
  replace_order: "Replace order",
  pickup_success_locker: "You are done! The order has been removed from the locker",
  pickup_success_counter: "You are done! The order has been removed from the counter",
  move_order_success: "You're done! Changes have been made to the locker",
  move_to_locker_success: "You're done! Order has been placed in {{locker}}",
  email_reminder_success: "Done! A reminder has been sent to the customer",
  email_reminder_message: "And a reminder email has been sent to the customer:",
  ok_close: "Ok, exit",
  yes_continue: "Yes, continue",
  no_cancel: "No, cancel",
  cross_sell_title: "Setting up your cross-selling",
  select_products: "Select products",
  at_least_four_products: "⚠️ Select at least 4 products",
  order_products_dnd: "✏️ Sort the products by dragging and dropping them in order",
  client_sees_products_before_payment: "The customer will see the cross-sale just <1>before completing their basket</1>.",
  see_in_app: "<0>Display</0> on the webapp",
  cross_sell_no_products : "No product has been found for cross-selling. <1/> You must synchronise the menu.",
  update: "Update",
  tutorials: "tutorials",
  connect_to_our_wifi: "Connect to our wifi",
  copy: "Copy",
  text_copied: "Copied text",
  wifi_pass_config_message: "Go to <1>⚙️ wifi settings</1> and paste the password to connect.",
  wifi_config_message: "Go to <1>⚙️ wifi settings</1> to connect to our network.",
  wifi_name: "Network name",
  wifi_pass: "Network password",
  wifi_name_placeholder: "Enter the network name",
  wifi_pass_placeholder: "Enter the network password",
  wifi_preview_message: "If the diner has <1>a weak connection</1>, he/she will automatically see the wifi data when scanning the QR.<3/><4/>In addition, he/she will be able to access it manually from the top left icon.",
  delete_wifi_title: "Do you want to delete the wifi network?",
  delete_wifi_message: "The warning will not be displayed when the connection is weak and the icon to view the network will not appear.",
  wifi_save_success: "Wifi network saved",
  wifi_save_error: "An error occurred while saving the network",
  credit_cents: "Credit discount",
  manage: "manage",
  "kitchen_saturated?": "Saturated kitchen?",
  kitchen_saturated: "Saturated kitchen",
  select_and_save: "Select an option and save",
  how_long_delay: "How long is the delay?",
  how_long_report: "How long will we inform the diner?",
  how_long_pause: "How long do you want to pause orders?",
  orders_paused: "The diner will be able to see the menu, but will not be able to place the order during this time.",
  clients_will_be_inform_in_webapp: "We will inform the diner through the webapp",
  report_delay: "<0>Report</0> delay",
  pause_orders: "<0>Pause</0> orders",
  minutes: "minutes",
  undefined: "undefined",
  select_restaurant: "Select a restaurant",
  active_saturation_restaurants: "<0>{{count}}</0> Restaurant with <2>active saturated kitchen management</2>",
  active_saturation_restaurants_plural: "<0>{{count}}</0> Restaurants with <2>active saturated kitchen management</2>",
  "Delivery and takeaway": "Delivery & takeaway",
  "ETA_explanation": "It is the <1>waiting time</1> we <br>will communicate to the customer. It includes <br><3>order preparation and shipping.</3>",
  radius: "Radius",
  geojson: "Geojson",
  delivery_time: "Delivery time",
  "delivery area": "Delivery zone", 
  "shipping cost": "Shipping cost",
  "delivery fee": "delivery fee",
  minimum_shipping_cost: "Minimum shipping cost",
  cost_per_km: "Cost per kilometer",
  estimated_delivery_time: "Estimated delivery time",
  type_of_delivery_area: "Type of delivery zone",
  radius_in_meters: "Radius in meters",
  upload_geojson: "Upload geojson file",
  upload_file: "Upload file",
  map_linked_successfully: "Map linked successfully",
  modify_file: "Modify file",
  how_to_create_geojson: "How to create a geojson?",
  how_to_obtain_coordinates: "How do I get the coordinates <br>of my restaurant address?",
  error_updating_delivery: "Error updating delivery data",
  "takeaway": "Takeaway",
  delete_file: "Delete file",
  "No file selected": "No file selected",
  "Invalid file format. Please select a .geojson file.": "Invalid file format. Please select a .geojson file.",
  saturation_updated: "Kitchen status updated",
  saturation_error: "An error occurred while updating the kitchen status.",
  saturation_end_delay: "Delay message <1>disabled</1><2> in {{restaurant}}</2>",
  saturation_end_pause: "Re-orders <1>active</1><2> at {{restaurant}}</2>",
  time_finished: "Time Ended",
  Purchase: 'Purchase',
  purchase: 'purchase',
  purchase_plural: 'purchases',
  ticket: 'ticket',
  ticket_plural: 'tickets',
  validated: 'Validated',
  validate: 'validate',
  cancel_validation: 'cancel validation',
  event: 'Event',
  filter_by_status: 'Filter by status',
  tickets_purchased: '<0>{{count}} Ticket</0> purchased',
  tickets_purchased_plural: '<0>{{count}} Tickets</0> purchased',
  tickets_to_validate: 'Tickets to validate',
  tickets_to_validate_plural: 'Tickets to validate',
  validated_tickets: 'Validated ticket',
  validated_tickets_plural: 'Validated tickets',
  entry: 'entry',
  entry_plural: 'entries',
  error_updating_validation: 'An error occurred while validating the ticket',
  searching: 'searching',
  no_results: 'no results',
  mail: 'email',
  '#purchase': '#Purchase',
  '#ticket': '#Ticket',
  placeholder_search_tickets: '#Purchase, #Ticket, Phone, Email, or Name',
  shop: 'shop',
  no_tickets_yet: "<0You have no tickets>/0> to manage yet",
  no_events_yet: "<0You have no events>/0> to manage yet",
  ticket_validation: "Ticket validation",
  create_events: "Create events",
  "name of the event": "Event name",
  "create_your_tickets": "Create the tickets of your event",
  add_ticket: "Add ticket",
  create_event: "Create event",
  photo_suggestions: "<0>Suggestions:</0> png/jpg or gif of 2MB weight max.",
  "write here": "Write here",
  "translate full catalog": "Translate full catalog",
  "default language": "Default language",
  edit_event: "Edit event",
  do_not_forget_to_add_tickets: "Don't forget to add the tickets!",
  creating_event: "Creating event",
  create_ticket: "Create ticket",
  explanation_ticket_image: "<0>By default</0>, you will see in the webapp<2>an icon ticket</2>, but you can add another picture here.",
  suggestions_ticket: "<0>Suggestions</0>: white colored and without background (png) of 2MB máx.",
  "name of the ticket": "Ticket name",
  price: "Price",
  event_created: "Event created",
  ticket_created: "Ticket created",
  event_updated: "Event updated",
  event_deleted: "Event deleted",
  ticket_updated: "Ticket updated",
  ticket_deleted: "Ticket deleted",
  'Error_creating_event': "Error creating event",
  'Error_updating_event': "Error updating event",
  'Error_deleting_event': "Error deleting event",
  'Error_saving_ticket': "Error saving ticket",
  'Error_updating_ticket':"Error updating ticket",
  'Error_deleting_ticket': "Error deleting ticket",
  drag_or_click_here: "Drag or click here",
  to_upload_image: "to upload an image",
  edit_ticket: "Edit ticket",
  see_in_webapp: "See in webapp",
  yes_delete: "Yes, delete",
  delete_event: "Delete event",
  delete_event_question: "Are you sure you want to delete this event?",
  delete_ticket: "Delete ticket",
  delete_ticket_question: "Are you sure you want to delete this ticket?",
  you_cannot_remove_this_event: "You cannot delete this event",
  you_cannot_remove_this_ticket: "You cannot delete this ticket",
  not_deletable_event_explanation: "Tickets were already sold for this event. You can deactivate it so it does not show in webapp.",
  not_deletable_ticket_explanation: "Ces tickets ont déjà été vendus. Vous pouvez les désactiver afin qu'ils ne s'affichent pas dans l'application web.",
  german: 'German',
  italian: 'Italian',
  portuguese: 'Portuguese',
  download_qr: 'Download QR',
  see_event_at_webapp: "See event at webapp",
  scan_or_copy_link: "Scan or copy link",
  explanation_event_qr: "<0>View all the events created</0> in the webapp by copying the link or scanning the QR.",
  create_your_first_event: "<0>Create new events</0><br> and sell tickets via Yumminn.",
  creating_ticket: "Creating ticket",
  wait_some_seconds: "Wait for a few seconds",
  desactivated_event: "Desactivated event", 
  desactivated_ticket: "Desactivated ticket", 
  not_visible_at_webapp: "Not visible for the customers anymore.",
  allergens_and_tags: 'Allergens and tags',
  tags: 'Tags',
  tags_explanation: 'Apply labels to your products so that your diners can quickly distinguish their preferences.',
  Vegetarian: "Vegetarian",
  Vegan: "Vegan",
  GlutenFree: "Gluten free",
  Kosher: "Kosher",
  Halal: "Halal",
  Spicy1: "Spicy",
  Spicy2: "Spicy",
  Spicy3: "Spicy",
  no_description: "Non description",
  copied: 'Copied',
  "revision mode": "Revision mode",
  "confirm and update": "Confirm and update",
  "cancel all changes": "Cancel all changes",
  "auto_translation": "Automatic translations",
  "manual_translation": "Manual translations",
  "remove_translations_in": "Remove translations in",
  "warning_message_remove_translations_in": "Are you sure that you want to remove the catalog translations in",
  "translations done!": "Great! Your translations are ready",
  "translations_done_message": "You can review and adjust them manually. You must confirm the changes so that they are reflected in the webapp.",
  "translations_done": "translations done",
  "checking_translations_dimensions": "We are computing the number of translations to do...",
  "we_are_on_it_message": "We are on it, wait a few seconds...",
  "Modifier": "Modifier",
  modifier_group: 'Modifier group',
  modifier_list: 'Modifier list',
  add_modifier: 'Add a modifier',
  understood: "Understood",
  delete_modifier_category: "Remove modifier group",
  delete_modifier_category_explanation: "To remove a modifier group, you must first remove its modifiers.",
  confirm_delete_modifier_category: "Are you sure you want to remove this group?",
  delete_modifier: "Remove modifier",
  confirm_delete_modifier: "Are you sure you want to remove this modifier?",
  modifiers_empty_state: "<0>Create modifiers</0> and make your products customisable!",
  creating_modifier_title: "Creating modifier",
  creating_modifier_message: "Soon your customers will enjoy fully customised products",
  product_modifiers: "Product modifiers",
  add_discount: "Add discount",
  what_type_of_discount_you_want_to_create: "What type of discount do you want to create?",
  write_here_your_code: "Write your code here",
  "discount in products": "Discount on products",
  "discount in all menu (promotion)": "Discount on the whole menu (promotion)",
  coupon_discount: "Coupon discount",
  what_type_of_highlight_you_wanna_create: "What type of highlight do you want to create?",  
  product_preview: "Product preview",
  create_discount_in_products: "Create discount for products",
  "select one or several products": "Select one or several products",
  select: "Select",
  discount_in_products: "Discount on products",
  product_discount: "Discont on product/s",
  discount_already_applied: "Your discount has already been applied and you can see it in your menu!",  
  scan_the_qr: "Scan the QR",
  products_and_specs: "Products and specifications",
  "discountTitlePercentage": "-{{value}}% in {{count}} products",
  "discountTitleAmount": "-{{value}}€ in {{count}} products",
  "Applied in" : "Applied on",
  delete_discount_question: "Are you sure you want to delete this discount?",
  delete_discount: "Delete discount",
  general_discount_already_active_message: "You already have a general discount active (promotion). Disactivate it to be able to create a discount on some products.",
  product_discount_already_active_message: "You already have a product discount active. Disactivate it to be able to create a general discount for your whole catalog",
  not_possible_to_change_status: "It is not possible to change status",
  should_desactivate_product_discount: "You must desactivate the products discount to be able to activate this promotion.",
  should_desactivate_general_discount: "You must desactivate the general discount on your whole catalog to be able to activate this promotion.",
  discount_deleted: "Discount deleted",
  disactivated_discount: "Discount deactivated",
  activated_discount: "Discount activated",
  visible_at_webapp: "Visible for the customers",
  "General discount": "Discount on the whole catalog",
  wallet: "Wallet",
  settings: "Settings",
  topups: "Top-ups",
  wallet_settings: "Wallet settings",
  edit_amounts_clicking_on_inputs: "Edit amounts by clicking on the input boxes",
  general_incentive: "General incentive",
  incentive_explanation: "The incentive percentage will be applied as an <1>increase on the top-up amount.</1>",
  incentive_example: "Example: <1>If the incentive is 10%, a top-up of 10€ will generate an extra 1€ in wallet credits.</1>",
  first_recharge_example: "E.g. 10€",
  second_recharge_example: "E.g. 15€",
  third_recharge_example: "E.g. 20€",
  fourth_recharge_example: "E.g. 25€",
  fifth_recharge_example: "E.g. 50€",
  sixth_recharge_example: "E.g. 100€",
  "topup_your_wallet": "Top up your wallet",
  "top up your wallet in order to pay for products in this restaurant": "Top up your wallet in order to pay for products in {{restaurantName}}",
  "and you will obtain": "and you will obtain {{extraCredits}} extra",
  'of topup': "of topup",
  topups_updated_successfully: "Wallet updated successfully",
  no_topups_yet: "There are no topups yet",
  "Amount payed": "Amount payed",
  "Amount topped up": "Amount topped up",
  "wallet_search_input_placeholder": "#ID",
  "ID topup": "ID topup",
  "recharge_ids": "ID topup",
  "you cannot save amounts with a value of zero": "You cannot store 0 value as a recharge. Edit the amount.",
  add_photo: "Upload photo",
  swap_photo: "Change photo",
  select_modifier_category: "Select a group",
  edit_modifier_category: "Modifier group settings",
  min_selectable_products: "Minimum number of options to select",
  max_selectable_products: "Maximum number of options to select",
  individual_max_selectable_products: "Selection limit per modifier",
  "0_optional": "0 (optional)",
  no_limit: "No limit",
  img_suggestions: "<0>Suggestions:</0> png/jpg ou gif 400x400 pixels, entre 1MB et 2MB.",
  remove_modifier_category: "Remove modifier group",
  confirm_remove_modifier_category: "Are you sure you want to remove the group {{name}} from {{parentName}}?",
  custom_shchedules: 'Customized schedules',
  selected: 'Selected',
  unselected: 'Not selected',
  modifier_category_updaetd: 'Modifier group updated',
  modifier_category_deleted: 'Modifier group deleted',
  totalActiveCredits: "Total active credits",
  totalCreditUsers: "Total of users",
  totalCreditsUsed: "Total of used credits", 
  totalInvestedInIncentives: "Total invested in incentives",
  yes_remove: 'Yes, remove',
  translation_progress_message: "The process will take around <1>{{minutes}}</1> approximately. In the meantime, you can continue working in the Back Office.",
  go_home: "Go to home",
  cancel_process: "Cancel process",
  no_users_with_credit_yet: "You have no users with credits yet",
  "wallet_users_search_input_placeholder": "Look for #email #ID",
  see_history: "See history",
  get_out: "Close",
  history_of_recharges: "History of recharges",
  totalCreditUsersSubtitle: "with <1>active</1> credit",
  totalActiveCreditsSubtitle: "An average of <1>{{average}}</1> per user",
  total_users_active: "active users",
  total_incentive: "total incentive",
  total_credits: "total credits",
  visibility_and_schedules: 'Visibility and opening hours',
  use_limits: 'Limit of use',
  all_day: 'all day',
  no_limit: 'no limit',
  use: 'use',
  use_plural: 'usage',
  discount_uses: 'discount usage',
  discount_uses_plural: 'discount uses',
  discount_cents_redeemed: 'used in this discount',
  discount_cents_redeemed_plural: 'used in this discount',
  operative: 'operational',
  period_limit_reached: 'Period limit reached',
  uses_limit_reached: 'Limit of uses reached',
  cents_limit_reached: 'Limit amount reached',
  validity_limits: 'Validity limits',
  create_general_discount: 'Create discount on the entire menu',
  edit_general_discount: 'Edit discount on the entire menu',
  create_product_discount: 'Create discount on some products',
  edit_product_discount: 'Edit discount on some products',
  create_code_coupon: 'Create discount coupon',
  edit_code_coupon: 'Edit discount coupon',
  name_schedule_placeholder: 'For example: ‘Weekly schedule’.',
  discount_updated: 'Discount updated',
  discount_created: 'Discount created',
  visualize_discount_message: 'Your {{type}} is already on the chart. <1>Scan or copy and paste to view it</1>.',
  done: 'done',
  code: 'code',
  nutritional_table: 'nutritional table',
  ingredients: 'ingredients',
  energy: 'energy',
  serving_size: 'serving size',
  fats: 'fat',
  carbohydrates: 'carbohydrates',
  proteins: 'protein',
  saturated_fats: 'saturated fat',
  fiber: 'fiber',
  sugars: 'sugars',
  sodium: 'sodium',
  product_details: 'product details',
  nutritional_info_explanation: 'Place a <1>value equal to or greater than 0 (zero)</1> in the boxes to make the information visible. Empty boxes will be hidden in the webapp.',
  not_specified: 'not specified',
  cash: 'cash',
  access: 'Webapp access',
  access_management: 'Access management',
  email_list: 'email list',
  domain_list: 'domain list',
  access_type: 'type of access',
  open_access: 'open access',
  login_required: 'mandatory log in',
  restricted_access: 'restricted access',
  download_sheet: 'download template',
  write_to_search: 'type to search',
  download_list: 'download list',
  add_email: 'add email',
  add_domain: 'add domain',
  allowed_domains: 'authorised domains',
  domain: 'domain',
  domain_plural: 'domains',
  created_at: 'creation date',
  modified_at: 'modification date',
  access_email_placeholder: 'Enter an email here',
  access_domain_placeholder: 'Enter a domain here',
  invalid_email: 'Invalid email',
  invalid_domain: 'Invalid domain',
  search_by_email: 'Search by email',
  search_by_domain: 'Search by domain',
  open_access_active: 'Open access active',
  login_required_active: 'Mandatory log in active',
  open_access_message: 'If you want to <b>limit access</b> to the webapp, use the <b>Mandatory Log in</b> or <b>Restricted Access</b> option.',
  login_required_message: 'To <b>restrict access to specific diners</b>, use the <b>Restricted Access</b> option. If you want to <b>free up access</b>, use the <b>Open Access</b> option.',
  email_whitelist_empty_state: 'You do not have any emails on the restricted access list yet',
  domain_whitelist_empty_state: 'You do not have any domains on the list yet',
  open_access_tooltip:  'Registration <b>will not be required</b> to access the webapp.',
  login_required_tooltip: '<b>Registration/Log in will be required</b> to access the webapp.',
  restricted_access_tooltip: 'To access the webapp, the diner <b>must be registered with an email that is on the list or that belongs to an enabled domain</b>.',
  get_access_type_error: 'An error occurred while loading the access type.',
  update_access_type_error: 'An error occurred while updating the access type.',
  access_management_email_error: 'An error occurred while loading the email list.',
  access_management_domain_error: 'An error occurred while loading the domain list.',
  update_whitelist_email_error: 'An error occurred while updating the email',
  update_whitelist_domain_error: 'An error occurred while updating the domain',
  delete_whitelist_email_error: 'An error occurred while deleting the email',
  delete_whitelist_domain_error: 'An error occurred while deleting the domain',
  import_whitelist_error: 'An error occurred while uploading the file',
  export_whitelist_emails_error: 'An error occurred while downloading the list',
  info_access_import_file: 'Valid formats: CSV, XLSX.',
  your_loyalty_program: 'Minimum time to redeem cashback',
  access: 'Webapp access',
  access_management: 'Access management',
  email_list: 'email list',
  domain_list: 'domain list',
  access_type: 'type of access',
  open_access: 'open access',
  login_required: 'mandatory log in',
  restricted_access: 'restricted access',
  download_sheet: 'download template',
  write_to_search: 'type to search',
  download_list: 'download list',
  add_email: 'add email',
  add_domain: 'add domain',
  allowed_domains: 'authorised domains',
  domain: 'domain',
  domain_plural: 'domains',
  created_at: 'creation date',
  modified_at: 'modification date',
  access_email_placeholder: 'Enter an email here',
  access_domain_placeholder: 'Enter a domain here',
  invalid_email: 'Invalid email',
  invalid_domain: 'Invalid domain',
  search_by_email: 'Search by email',
  search_by_domain: 'Search by domain',
  open_access_active: 'Open access active',
  login_required_active: 'Mandatory log in active',
  open_access_message: 'If you want to <b>limit access</b> to the webapp, use the <b>Mandatory Log in</b> or <b>Restricted Access</b> option.',
  login_required_message: 'To <b>restrict access to specific diners</b>, use the <b>Restricted Access</b> option. If you want to <b>free up access</b>, use the <b>Open Access</b> option.',
  email_whitelist_empty_state: 'You do not have any emails on the restricted access list yet',
  domain_whitelist_empty_state: 'You do not have any domains on the list yet',
  open_access_tooltip:  'Registration <b>will not be required</b> to access the webapp.',
  login_required_tooltip: '<b>Registration/Log in will be required</b> to access the webapp.',
  restricted_access_tooltip: 'To access the webapp, the diner <b>must be registered with an email that is on the list or that belongs to an enabled domain</b>.',
  get_access_type_error: 'An error occurred while loading the access type.',
  update_access_type_error: 'An error occurred while updating the access type.',
  access_management_email_error: 'An error occurred while loading the email list.',
  access_management_domain_error: 'An error occurred while loading the domain list.',
  update_whitelist_email_error: 'An error occurred while updating the email',
  update_whitelist_domain_error: 'An error occurred while updating the domain',
  delete_whitelist_email_error: 'An error occurred while deleting the email',
  delete_whitelist_domain_error: 'An error occurred while deleting the domain',
  import_whitelist_error: 'An error occurred while uploading the file',
  export_whitelist_emails_error: 'An error occurred while downloading the list',
  info_access_import_file: 'Valid formats: CSV, XLSX.',
  email_added: 'Email added',
  domain_added: 'Domain added',
  subsidies: 'subsidies',
  preselected: 'default selection',
  subsidy_email_placeholder: 'Write a mail here',
  subsidies_empty_state: 'You have no mails in the list yet',
  subsidies_error: 'An error occurred while loading the mailing list',
  subsidies_coupons_error: 'An error occurred while loading the list of coupons',
  update_subsidy_error: 'An error occurred while updating the mailing list',
  delete_subsidy_error: 'An error occurred while deleting mail',
  toggle_all_preselected_error: 'An error occurred while updating the coupon list',
  import_subsidies_error: 'An error occurred while importing the file',
  export_subsidies_error: 'An error occurred while exporting the list',
  coupon_data: 'Coupon data',
  type: 'type',
  discount_value: 'discount value',
  select_coupon: 'Select a coupon.',
  select_coupon_message: 'Create more coupons from the discount section.',
  manage_coupons: 'manage coupons',
  delivery_fee: 'Delivery',
  gift: 'gift',
};