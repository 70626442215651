import { BUTTON_COLORS, BUTTON_SHAPES, BUTTON_SIZES, Button } from 'common/Button';
import { Icon, IconType, SquareIcon } from 'common/Icon';
import { InfoTooltip } from 'common/InfoTooltip';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { KitchenSaturationCard, KitchenSaturationToast } from './KitchenSaturationContainers';
import { useCountDown } from 'hooks/useCountDown';
import { baseAuth } from 'logic/api';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Context as AuthContext } from '../../../../data/authContext';

export const RestaurantSaturationCard = ({ saturation, as = 'div', openManage, mutate }) => {
  const { state: { selectedRestaurant }, setSelectedRestaurant } = useContext(AuthContext);
  
  const { t } = useTranslation();
  const { timer, millisecLeft } = useCountDown({endTime: saturation?.end_time});
  const history = useHistory();

  const toastRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const returnToOrders = () => {
    history.push('/admin/orders');
    setSelectedRestaurant([selectedRestaurant.find(option => option.value === saturation.restaurant)])
  };

  const toastEnd = time_finished => toast.success(
    <KitchenSaturationToast onClick={returnToOrders}>
      <SquareIcon emoji={'🔥'}>👩🏻‍🍳</SquareIcon>
      <div>
        {time_finished &&
          <h5>{t('time_finished')}</h5>
        }
        <p>
          <Trans i18nKey={`saturation_end_${saturation.type}`}>
            Mensaje de espera <strong>desactivado</strong><b> en {{restaurant: saturation.restaurant__name}}</b>.
          </Trans>
        </p>
      </div>
    </KitchenSaturationToast>,
    {
      toastId: `saturation_end_${saturation.restaurant}`,
      position: 'bottom-right',
      autoClose: false,
      className: 'bordered',
    }
  );

  const stopCounter = async event => {
    event.stopPropagation();

    try {
      await baseAuth.delete(`/restaurant/delete_kitchen_saturation/${saturation.restaurant}`);
      clearTimeout(toastRef.current);
      toastEnd();
      mutate();
    } catch (error) {
      console.log(`🐉 > file: RestaurantSaturationCard.jsx:21 > stopCounter > error:`, error)
      toast.error(t('saturation_error'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (millisecLeft && !toastRef.current) {
      toastRef.current = setTimeout(() => {
        console.log(`🐉 > file: RestaurantSaturationCard.jsx:42 > toastRef.current=setTimeout > setTimeout:`, setTimeout)
        toastEnd(true);
      }, millisecLeft);
    } else if (!millisecLeft) {
      clearTimeout(toastRef.current);
      toastRef.current = null;
    };
    // Do not clear this timeout on cleanup
  }, [millisecLeft]);

  return (
    <KitchenSaturationCard className={saturation?.type || ''} as={as}>
      <div className='row'>
        {as !== 'li' &&
          <SquareIcon emoji={'🔥'}>👩🏻‍🍳</SquareIcon>
        }
          <p>
            {as === 'li' && saturation?.restaurant__name &&
              <b>{saturation.restaurant__name}: </b>
            }
            <Trans i18nKey={`kitchen_saturated${saturation ? '_' + saturation.type : '?'}`}>
              Mensaje de espera <b className='blue'>activo</b>
            </Trans>
            {saturation &&
              <InfoTooltip
                info={t(
                  `info_kitchen_saturated_${saturation.type}`,
                  {minutes: saturation?.delay_minutes},
                )}
                position='top'
              />
            }
            {!saturation &&
              <InfoTooltip position='top' interactive>
                <p>
                  <Trans i18nKey='info_kitchen_saturated'>
                    Informa a tus comensales de la espera o desactiva los pedidos temporalmente desde aquí.
                    <a href='https://guidejar.com/guides/243dee7f-cac5-45a5-b4b3-0e1bcfd26b69' target='_blank'>Mas info en este tutorial.</a>
                  </Trans>
                </p>
              </InfoTooltip>
            }
          </p>
        {saturation &&
          <>
            {saturation.end_time &&
              <div className='timer'>
                <span>{t('remaining')}</span>
                <strong>{timer}</strong>
              </div>
            }
            {!saturation.end_time &&
              <span className='no_timer'>
                <Trans i18nKey='time_undefined'>
                  Tiempo <strong>indefinido</strong>
                </Trans>
              </span>
            }
            <Button
              label={<Icon type={IconType.CLOSE}/>}
              size={BUTTON_SIZES.SM}
              color={BUTTON_COLORS.DANGER}
              shape={BUTTON_SHAPES.CIRCLE}
              onClick={stopCounter}
              loading={isLoading}
            />
          </>
        }
        {!saturation &&
          <Button
            label={'manage'}
            size={BUTTON_SIZES.SM}
            color={BUTTON_COLORS.DANGER}
            onClick={openManage}
          />
        }
      </div>
      {as !== 'li' && saturation &&
        <span>
          <Trans i18nKey={'clients_will_be_inform_in_webapp'}>
            <b>Le informaremos al comensal</b> a través de la webapp
          </Trans>
        </span>
      }
    </KitchenSaturationCard>
  );
};