import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "common/Spinner";
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { Context as AuthContext } from '../../../data/authContext';
import TopProductsTable from "./TopProductsComponents/TopProductsTable";
import { thousandsSeparator, toDecimalString } from 'logic/functions';
import ReactCardFlip from "react-card-flip";
import { InformationCircleIcon } from '@heroicons/react/outline'

export const TopProducts = () => {
	const {
		state: { topProducts, selectedProductCategories }
	} = React.useContext(AuthContext);
	const { t } = useTranslation();
	const [soldProductsData, setSoldProductsData] = useState([]);
	const [notSoldProductsData, setNotSoldProductsData] = useState([]);
	const [productsDataToShow, setProductsDataToShow] = useState([]);
	const [flip, setFlip] = useState(false);
	const soldRate = productsDataToShow.length > 0 ? Math.round((productsDataToShow.filter(pr => pr.quantity > 0).length / productsDataToShow.length) * 100) : 0
	const nonImageProducts = productsDataToShow.length > 0 ? Math.round((productsDataToShow.filter(pr => pr.image != "").length / productsDataToShow.length) * 100) : 0
	const nonDescriptionProducts = productsDataToShow.length > 0 ? Math.round((productsDataToShow.filter(pr => ((pr.description && pr.description != "") || (pr.tpv_description && pr.tpv_description != ""))).length / productsDataToShow.length) * 100) : 0
	const catalogScore = (((soldRate) + (nonImageProducts * 1.3) + (nonDescriptionProducts * 0.7)) / 30)

	useEffect(() => {
		if (!topProducts) return;
		setSoldProductsData(topProducts.sold_products_data);
		setNotSoldProductsData(topProducts.not_sold_products_data);
		filterProductData(topProducts);
	}, [topProducts, selectedProductCategories])

	const filterProductData = (topProducts) => {
		if (selectedProductCategories) {
			const productCategoryIds = selectedProductCategories.map(r_o => r_o.id)
			const soldProductsFiltered = [...topProducts.sold_products_data].filter(pr => productCategoryIds.includes(pr.category))
			const notSoldProductsFiltered = [...topProducts.not_sold_products_data].filter(ns_pr => productCategoryIds.includes(ns_pr.category))
			if (productCategoryIds.length > 0) {
				const resultingArray = soldProductsFiltered.concat(notSoldProductsFiltered)
				setProductsDataToShow(resultingArray)
			} else {
				const resultingArray = soldProductsData.concat(notSoldProductsData)
				setProductsDataToShow(resultingArray)
			}
		}
	};

	
	const productsQuantitySum = productsDataToShow.reduce((total, obj) => obj.quantity + total,0)
	const productsAmountSum = productsDataToShow.reduce((total, obj) => (obj.tpv_price_cents * obj.quantity) + total,0)
	
	return (
		<>
        <div className={`flex-auto flex flex-col items-center w-full bg-gray-100`} >
			{!topProducts || !productsDataToShow ?
				<>
					<div className="h-full w-full top-0 left-0 absolute z-9999 flex flex-col justify-center items-center">
						<Spinner>{t("Loading")}</Spinner>
					</div>
					<div className="bg-black opacity-20 top-0 left-0 fixed overflow-y-auto h-full w-full z-9999 flex flex-col justify-center items-center"/>
				</> :
				<>
				<div style={{display: 'flex', alignSelf: 'flex-start', gap: "2.5%", width: '100%'}}>
					<div style={{display: 'flex', flexDirection: "column", alignSelf: 'flex-start', marginTop: "15px",  width: "16%", height: "110px", alignItems: 'center', justifyContent: 'center', backgroundColor: "#FFFFFF", borderRadius: "10px"}}>
						<div style={{fontSize: "23px", fontWeight: 700, color: '#094553'}}>{thousandsSeparator(productsDataToShow.length)}</div>
						<div style={{fontSize: "12px", fontWeight: 500, color: '#767676', textAlign: 'center'}}>Productos visibles</div>
					</div>
					<div style={{display: 'flex', flexDirection: "column", alignSelf: 'flex-start', marginTop: "15px", width: "16%", height: "110px", alignItems: 'center', justifyContent: 'center', backgroundColor: "#FFFFFF", borderRadius: "10px"}}>
						<div style={{fontSize: "23px", fontWeight: 700, color: nonImageProducts < 50 ? '#B71C1C' : nonImageProducts < 80 ? '#FF8F00' : '#5FB894'}}>{nonImageProducts}%</div>
						<div style={{fontSize: "12px", fontWeight: 500, color: '#767676', textAlign: 'center'}}>Productos visibles<br/>con imagen</div>
					</div>
					<div style={{display: 'flex', flexDirection: "column", alignSelf: 'flex-start', marginTop: "15px", width: "16%", height: "110px", alignItems: 'center', justifyContent: 'center', backgroundColor: "#FFFFFF", borderRadius: "10px"}}>
						<div style={{fontSize: "23px", fontWeight: 700, color: nonDescriptionProducts < 50 ? '#B71C1C' : nonDescriptionProducts < 80 ? '#FF8F00' : '#5FB894'}}>{nonDescriptionProducts}%</div>
						<div style={{fontSize: "12px", fontWeight: 500, color: '#767676', textAlign: 'center'}}>Productos visibles<br/>con descripción</div>
					</div>
					<div style={{display: 'flex', flexDirection: "column", alignSelf: 'flex-start', marginTop: "15px",  width: "16%", height: "110px", alignItems: 'center', justifyContent: 'center', backgroundColor: "#FFFFFF", borderRadius: "10px"}}>
						<div style={{fontSize: "23px", fontWeight: 700, color: '#094553'}}>{thousandsSeparator(productsDataToShow.filter(pr => pr.quantity > 0).length)}</div>
						<div style={{fontSize: "12px", fontWeight: 500, color: '#767676', textAlign: 'center'}}>Productos únicos<br/>vendidos</div>
					</div>
					<div style={{display: 'flex', flexDirection: "column", alignSelf: 'flex-start', marginTop: "15px", width: "16%", height: "110px", alignItems: 'center', justifyContent: 'center', backgroundColor: "#FFFFFF", borderRadius: "10px"}}>
						<div style={{fontSize: "23px", fontWeight: 700, color: soldRate < 50 ? '#B71C1C' : soldRate < 80 ? '#FF8F00' : '#5FB894'}}>{soldRate}%</div>
						<div style={{fontSize: "12px", fontWeight: 500, color: '#767676', textAlign: 'center'}}>Productos visibles<br/>vendidos</div>
					</div>
				</div>
				<div style={{display: 'flex', alignSelf: 'flex-start', gap: "2.5%", width: '100%'}}>
					<div style={{display: 'flex', flexDirection: "column", alignSelf: 'flex-start', marginTop: "15px", width: "16%", height: "110px", alignItems: 'center', justifyContent: 'center', backgroundColor: "#FFFFFF", borderRadius: "10px"}}>
						<div style={{fontSize: "23px", fontWeight: 700, color: '#094553'}}>{thousandsSeparator(productsQuantitySum)}</div>
						<div style={{fontSize: "12px", fontWeight: 500, color: '#767676', textAlign: 'center'}}>Productos vendidos <br/> totales</div>
					</div>
					<div style={{display: 'flex', flexDirection: "column", alignSelf: 'flex-start', margin: "15px 0px",  width: "16%", height: "110px", alignItems: 'center', justifyContent: 'center', backgroundColor: "#FFFFFF", borderRadius: "10px"}}>
						<div style={{fontSize: "23px", fontWeight: 700, color: '#094553'}}>{thousandsSeparator([...new Set(productsDataToShow.filter(pr => pr.highlighted).map(item => item.tpv_id))].length)}</div>
						<div style={{fontSize: "12px", fontWeight: 500, color: '#767676', textAlign: 'center'}}>Productos destacados</div>
					</div>
					<div onMouseOver={() => setFlip(true)} onMouseOut={() => setFlip(false)} style={{position: 'relative', width: "16%", height: "110px"}}>
						<ReactCardFlip isFlipped={flip} flipDirection="vertical">
						<div style={{display: 'flex', flexDirection: "column", alignSelf: 'flex-start', marginTop: "15px",  width: "100%", height: "110px", alignItems: 'center', justifyContent: 'center', backgroundColor: "#FFFFFF", borderRadius: "10px"}}>
							<InformationCircleIcon style={{position: "absolute", top: "15px", right: '15px'}} height={17}/>
							<div style={{fontSize: "23px", fontWeight: 700, color: catalogScore < 5 ? '#B71C1C' : catalogScore < 8 ? '#FF8F00' : '#5FB894'}}>{toDecimalString(catalogScore)}<span style={{color: "black"}}>/10</span></div>
							<div style={{fontSize: "12px", fontWeight: 500, color: '#767676', textAlign: 'center'}}>Nota media</div>
						</div>
						<div style={{display: 'flex', flexDirection: "column", alignSelf: 'flex-start', marginTop: "15px",  width: "100%", height: "110px", alignItems: 'center', justifyContent: 'center', backgroundColor: "#FFFFFF", borderRadius: "10px"}}>
							<div style={{fontSize: "12px", fontWeight: 500, color: '#767676', textAlign: 'center', padding: '10px 20px'}}>Se calcula según el % de productos visibles:<br/>· <b>Con imagen</b> (x1,3) <br/>· <b>Vendidos</b> (x1) <br/>· <b>Con descripción</b> (x0,7)</div>
						</div>
						</ReactCardFlip>
					</div>
				</div>
				<TopProductsTable data={productsDataToShow} productsQuantitySum={productsQuantitySum} productsAmountSum={productsAmountSum} /></>
			}
        </div>
    </>
	)
}

export default TopProducts