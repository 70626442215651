const { default: styled } = require("styled-components");

export const ModifiersContainer = styled.div`
  & > header {
    background-color: #FFF;
    padding: 16px 24px;
  }
  
  & > section {
    background-color: #FFF;
    border: 1px solid #40404026;
    border-radius: 16px;
    padding: 24px;
    margin: 24px;

    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }

    h2 {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 1.125rem;
      height: 1.3em;

      span::first-letter {
        text-transform: capitalize;
      }
    }

    ul {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;
      overflow-x: auto;
      padding: 4px;
      margin: -4px;
      
      li {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.25em;
        white-space: nowrap;
        color: #094553;
        border-radius: 2rem;
        height: 2.75rem;
        cursor: pointer;
        transition: 0.15s ease-in-out;
        
        &:not(:has(input)) {
          padding: 0 16px;
          
          &:hover {
            color: #094553;
            background-color: #D4F9EE;
          }
        }
        
        &.selected {
          background-color: #094553;
          color: #FFF;
          cursor: default;

          &:hover {
            color: #094553;
            background-color: #FFF;
          }
        }

        &.disabled {
          opacity: 0.5;
        }
      }
    }

    & > div {
      border: 1px solid #40404026;
      border-radius: 12px;
      padding: 0 8px;
      
      table {
        width: 100%;
        border-spacing: 0 8px;
        border-collapse: separate;
      }
      
      tr{
        width: 100%;
        user-select: none;
        
        &:last-child td {
          
          &:first-child {
            border-bottom-left-radius: 8px;
          }

          &:last-child {
            border-bottom-right-radius: 8px;
          }
        }

        &:has(td:nth-child(6)) td:nth-child(5) {
          width: 10%;
        }
      }
      
      td, th {
        background-color: #FCFCFC;
        padding: 16px;
        text-align: center;

        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 1rem;

          p {
            font-size: 1.125rem;
            font-weight: 400;
            max-width: 30ch;
            text-wrap: balance;
          }

          b {
            font-weight: 600;
          }
        }
      }
      
      th {
        font-size: 1.125rem;
        font-weight: 500;
        border-bottom: 1px solid #40404026;

        &:first-child {
          border-top-left-radius: 8px;
        }

        &:last-child {
          border-top-right-radius: 8px;
        }
      }

      td {
        font-size: 1rem;
        text-align: center;
        width: 20%;

        &:first-child {
          width: 1rem;
        }

        &:nth-child(2) {
          text-align: start;
          width: 40%;
        }

        &:nth-child(4) {
          text-align: end;
          padding-right: 32px;
        }

        &:nth-child(6) {
          width: 10%;
        }

        & > svg {
          margin: auto;
        }
      }
    }

    .input_wrapper {
      min-width: 200px;
      height: 100%;
    }
  }
`;