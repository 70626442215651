import React, { useState, useEffect } from "react";
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { baseAuth } from "logic/api";
import Switch from 'react-switch';
import {CommercialBannerDetailsSettings} from '../CommercialBannerDetailsSettings'
import {CustomGroupProductsSettings} from '../CustomGroupProductsSettings'
import {CustomGroupColorSettings} from '../CustomGroupColorSettings'
import {CustomGroupFinishedSettings} from '../CustomGroupFinishedSettings'
import {CustomGroupScheduleSettings} from '../CustomGroupScheduleSettings'
import {CustomGroupScheduleTimeRangeSettings} from '../CustomGroupScheduleTimeRangeSettings'
import { TrashIcon, SaveIcon, XIcon } from '@heroicons/react/outline';

const CommercialBannerModal  = ({ catalog, t, custom_group, setCustomGroupToEdit, saveCustomGroup, setCustomGroupToRemove, type, setCurrentStep, currentStep = 1 }) => {
  const [customGroupDraft, setCustomGroupDraft] = useState(custom_group ? { ...custom_group } : null);
  const [step, setStep] = useState(currentStep ? currentStep : 1);
  const [editSchedule, setEditSchedule] = useState(null);
  const step_to_gradient = () => {
    if (step > 2) {
      return `linear-gradient(to right, #5FB894 100%, #CCC 100%)`    
    } else if (step > 1) {
      return `linear-gradient(to right, #5FB894 68%, #CCC 68%)`          
    } else {
      return `linear-gradient(to right, #5FB894 21%, #CCC 21%)`
    } 
    }

  const changeActiveState = () => {
    setCustomGroupDraft((prevCustomDraft) => {
      return {
      ...prevCustomDraft,
      active: !prevCustomDraft.active,
    }})
  }

  const changeTitle = (title) => {
    setCustomGroupDraft((prevCustomDraft) => {
      return {
      ...prevCustomDraft,
      name: title,
    }})
  }

  const changeImage = (image) => {
    setCustomGroupDraft((prevCustomDraft) => {
      return {
      ...prevCustomDraft,
      image: image,
    }})
  }

  const changePosition = (restaurant, l1, l2, l3) => {
    setCustomGroupDraft((prevCustomDraft) => {
      return {
      ...prevCustomDraft,
      restaurant: restaurant,
      l1: l1,
      l2: l2,
      l3: l3
    }})
  }

  const changeVisibilitySchedules = (visibility_schedules) => {
    visibility_schedules.sort((a,b) => a.index - b.index);
    setCustomGroupDraft((prevCustomDraft) => {
      return {
      ...prevCustomDraft,
      visibility_schedules: visibility_schedules
    }})
  }

  const handleNextStep = () => {
    if (step < 3) {
      if (step === 2 && editSchedule) {
        let newVisibilitySchedules = [...customGroupDraft.visibility_schedules]
        newVisibilitySchedules = newVisibilitySchedules.filter(schedule => schedule.index != editSchedule.index)
        newVisibilitySchedules.push(editSchedule)
        changeVisibilitySchedules(newVisibilitySchedules)
        setEditSchedule(null)
      } else {
        if (type === 0 || step === 2) {
          saveCustomGroup(customGroupDraft, 1, false)
        }
        setStep(step + 1)
      }
    } else {
      setCustomGroupToEdit(null)
      setCurrentStep(null)
    }
  }

  const checkDisableNextStep = () => {    
    if (step === 1) {
      return !customGroupDraft.name.length > 0 || !customGroupDraft.image
    } else if (step === 2 && editSchedule) {
      return !(editSchedule.schedule.days.length > 0 && editSchedule.time_range.length > 0)
    } else {
      return false
    }   
  }

  const handleBackStep = () => {
    if (type === 0) {
      setCustomGroupDraft({ ...custom_group })
    }
    setStep(step - 1)
  }
  return (
    <div style={{ zIndex: 99 }} className="fixed top-0 left-0 right-0 bottom-0 items-center">
      <div style={{display: "flex", width: "50%", top: "5%", left: "25%", zIndex: 50, flexDirection: "column", position: "relative", alignItems: "center", overflow: "hidden", backgroundColor: "white", borderRadius: 10, padding: "30px 30px"}}>
        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%"}}>
          <div style={{ fontSize: "14px", fontWeight: 700}}>{type === 0 ? "Editar Banner Publicitario ✨" : "Crear Banner Publicitario"}</div>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "220px", justifyContent: step !== 5 ? 'space-between' : 'flex-end', display: 'flex'}}>
              {step !== 5 &&
              <>
              <div style={{fontSize: "12px", fontWeight: 600}}>Estado</div>
              <div style={{display: "flex", alignItems: "center"}}>
                <Switch
                height={10}
                width={25}
                handleDiameter={15}
                offColor={'#DDD'}
                offHandleColor={'#CCC'}
                onColor={'#C7E6DA'}
                onHandleColor={'#5FB894'}
                activeBoxShadow={'0 0 0 3px #CFEADF'}
                onChange={() => changeActiveState()}
                name='active_custom_group'
                checked={customGroupDraft.active}
                uncheckedIcon={false}
                checkedIcon={false}
                />
              </div>
              <div style={{fontSize: "12px", color: customGroupDraft.active ? '#5FB894' : '#CCC', fontWeight: 600}}>{customGroupDraft.active ? "Activo" : "Inactivo"}</div></>}
              <button style={{margin: "0 15px"}} onClick={() => {setCustomGroupToEdit(null); setCurrentStep(null)}}><XIcon className={`w-[25px] text-gray-700 cursor-pointer`}/></button>
            </div>
        </div>
        <div style={{marginTop: "20px", width: "100%"}}>
          <div style={{display: "flex", flexDirection: "row", width: "100%", fontSize: "13px", alignItems: "center", fontWeight:  550}}>
            <div onClick={() => type === 0 ? setStep(1) : ""} style={{color: step > 0 ? '#5FB894' : '#CCC', cursor: type === 0 ? 'pointer' : 'auto'}}>Detalles</div>
            <ChevronRightIcon style={{margin: "0 5px"}} height={18} color={step > 0 ? '#5FB894' : '#CCC'}/>
            <div onClick={() => type === 0 ? setStep(2) : ""} style={{color: step > 1 ? '#5FB894' : '#CCC', cursor: type === 0 ? 'pointer' : 'auto'}}>Visibilidad y horarios</div>
            <ChevronRightIcon style={{margin: "0 5px"}} height={18} color={step > 1 ? '#5FB894' : '#CCC'}/>
            <div onClick={() => type === 0 ? setStep(3) : ""} style={{color: step > 2 ? '#5FB894' : '#CCC', cursor: type === 0 ? 'pointer' : 'auto'}}>Visualizar</div>
          </div>
          <div style={{marginTop: "10px", width: "350px", height: "2px", background: step_to_gradient()}}></div>
        </div>
        <div style={{marginTop: "15px", display: "flex", height: "400px", width: '100%'}}>
        {step === 1 && <CommercialBannerDetailsSettings catalog={catalog} changeTitle={changeTitle} changePosition={changePosition} customGroupDraft={customGroupDraft} changeImage={changeImage} />}
        {step === 2 && !editSchedule && <CustomGroupScheduleSettings changeVisibilitySchedules={changeVisibilitySchedules} setEditSchedule={setEditSchedule} customGroupDraft={customGroupDraft} />}
        {step === 2 && editSchedule && <CustomGroupScheduleTimeRangeSettings setEditSchedule={setEditSchedule} editSchedule={editSchedule} customGroupDraft={customGroupDraft} />}
        {step === 3 && <CustomGroupFinishedSettings catalog={catalog} customGroupDraft={customGroupDraft} />}
        </div>
        <div style={{marginTop: "30px", width: "610px", height: "2px", background: '#CCC'}}></div>
        <div style={{display: 'flex', flexDirection: 'row', marginTop: '25px', width: "100%", justifyContent: type === 0 ? 'space-between' : 'flex-end'}}>
        {type === 0 && 
        <button
          className={"rounded-full cursor-pointer border-2 border-gray-400 py-2 px-4 m-2 bg-white text-xs text-gray-500 font-bold"}
          onClick={() => setCustomGroupToRemove(custom_group)}><TrashIcon className={`w-[20px] text-gray-700 cursor-pointer`}
          /></button>}
        <div>
          {step > 1 && !editSchedule && 
          <button
            style={{width: '175px'}}
            className={"rounded-full cursor-pointer border-2 border-gray-400 py-2 px-4 m-2 bg-white text-xs text-gray-500 font-bold"}
            onClick={() => handleBackStep()}>{"Volver"}</button>}
          <button
            disabled={checkDisableNextStep()}
            style={{width: '175px', backgroundColor: checkDisableNextStep() ? 'gray' : '#5FB894'}}
            className={"rounded-full cursor-pointer py-2 px-4 m-2 bg-principal text-xs text-white font-bold"}
            onClick={() => handleNextStep()}>{step < 3 ? step === 2 && editSchedule ? "Listo" : type === 0 ? "Guardar y continuar" : step == 2 ? "Crear banner" : "Siguiente" :  "Cerrar"}</button>
          </div>
        </div>
      </div>
      <div className='z-40 overflow-auto left-0 top-0 bottom-0 right-0  h-full fixed bg-black opacity-50'></div>
    </div>
  )
}

export default CommercialBannerModal 