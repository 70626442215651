import React from 'react';
import bizumPng from '../../../../../img/bizum.svg';
import applePay from '../../../../../img/applePay.svg';
import gPay from '../../../../../img/gpay.svg';
import mastercardIcon from '../../../../../img/mastercard.svg';
import visaIcon from '../../../../../img/visa.svg';
import cashIcon from '../../../../../img/cash.svg';
import walletIcon from '../../../../../img/walletIcon.svg';


const OrderPaymentMethodType = ({type, brand}) => {
  if (type === "cash") {
    return <img style={{height: "32px"}} src={cashIcon} alt=''/>
  }
  if (type === "bizum") {
    return <img style={{height: "32px"}} src={bizumPng} alt=''/>
  }
  if (type === "wallet"){
    return <img style={{height: "32px"}} src={walletIcon} alt=''/>
  }
  else if (type === "google_pay") {
    return <img src={gPay} alt=''/>
  } else if (type === "apple_pay") {
    return <img src={applePay} alt=''/>
  } else {
    if (brand === "mc") {
      return <img src={mastercardIcon} alt=''/>
    } else if (brand === "visa"){
      return <img style={{height: "12px"}} src={visaIcon} alt=''/>
    }
  }
};

export default OrderPaymentMethodType;