import { Button } from 'common/Button';
import { Icon, IconType, SquareIcon } from 'common/Icon';
import { InfoTooltip } from 'common/InfoTooltip';
import React, { useState } from 'react';
import { calculateEuros } from 'services/payments';
import { EditModifierModal } from '../OrderAndPay/Modals/EditModifierModal';
import { ToggleSwitch } from 'common/ToggleSwitch';
import { PlusCircleIcon } from '@heroicons/react/solid';
import { TPVS } from '../DragAndDrop/constants';
import { Trans, useTranslation } from 'react-i18next';
import { ObjectTypes, deleteObject, patchObject } from '../OrderAndPay/api-OrderAndPay';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { DeletePopup } from './DeletePopup';

export const ModifiersTable = ({ modifiers, selectedCategory, tpv, mutateMod }) => {
  const { t } = useTranslation();

	const [modifierToEdit, setModifierToEdit] = useState(null);
	const [modifierToDelete, setModifierToDelete] = useState(null);

  const newMod = {
    category: selectedCategory,
  };
  
  const isTpvYumminn = tpv.includes(TPVS.YUMMINN);

  const onModSave = mod => {
    const newModifiers = [...modifiers];

    const index = newModifiers.findIndex(m => m.id === mod.id);
  
    if (index !== -1) {
      newModifiers[index] = mod;
    } else {
      newModifiers.push(mod);
    }

    mutateMod({ modifiers: newModifiers });
  };

  const toggleModVisibility = async (modifier) => {
    try {
      const newVisibility = modifier.visibility ? 0 : 1;

      const object = {
        id: modifier.id,
        visibility: newVisibility,
      };
      
      const res = await patchObject({ objectType: ObjectTypes.MODIFIER, object, t });
      
      if (res.status === 200) {
        const newModifiers = [...modifiers];
        const index = newModifiers.findIndex(m => m.id === modifier.id);

        if (index !== -1) {
          newModifiers[index].visibility = newVisibility;
  
          mutateMod({modifiers: newModifiers});
        } else {
          mutateMod();
        };
      };
    } catch (error) {
      console.log("🚀 ~ file: ModifiersTable.jsx:39 ~ toggleModVisibility ~ error:", error);
    }
  }

  const changeOrder = async result => {
    const fromIndex = result.source?.index;
    const toIndex = result.destination?.index;

    if (!result.destination || fromIndex === toIndex) return;
    
    const object = {
      id: Number(result.draggableId),
      order: toIndex,
    };

    const res = await patchObject({ objectType: ObjectTypes.MODIFIER, object, t });

    if (res.status === 200) {
      const newModifiers = [...modifiers];
      const [mod] = newModifiers.splice(fromIndex, 1);
      
      mod.order = toIndex;
      newModifiers.splice(toIndex, 0, mod);

      await mutateMod({ modifiers: newModifiers });
    }
  };

  const deleteMod = async mod => {
    if (!isTpvYumminn) return;

    const object = { id: mod.id };

    const res = await deleteObject({ objectType: ObjectTypes.MODIFIER, object, t });

    if (res.status === 200) {
      const newModifiers = [...modifiers];
      const index = newModifiers.findIndex(m => m.id === mod.id);

      if (index !== -1) {
        newModifiers.splice(index, 1);

        mutateMod({modifiers: newModifiers});
      } else {
        mutateMod();
      };

      setModifierToDelete(null);
    }
  };

  return (
    <section>
      <header>
        <h2>
          <span>{t('modifier_list')}</span>
          <InfoTooltip>
            <p>
              <Trans i18nKey='info_modifier_list'>
                Crea modificadores para <b>personalizar los productos</b> y <b>promover ventas adicionales</b>.
              </Trans>
            </p>
          </InfoTooltip>
        </h2>
        {isTpvYumminn &&
          <Button
            label={'add_modifier'}
            leftIcon={<PlusCircleIcon/>}
            onClick={() => setModifierToEdit(newMod)}
            disabled={!selectedCategory}
          />
        }
      </header>
      <div>
        <table>
          <thead>
            <tr>
              <th colspan='2'>{t('modifier_name')}</th>
              <th>{t('Visibility')}</th>
              <th>{t('price')}</th>
              <th colspan='2'/>
            </tr>
          </thead>
          <DragDropContext onDragEnd={changeOrder}>
            <Droppable droppableId="modifier_list">
              {(provided) =>
                <tbody id='tbody' {...provided.droppableProps} ref={provided.innerRef}>
                  {modifiers.map((mod, index) =>
                    <Draggable key={mod.id} draggableId={`${mod.id}`} index={index}>
                      {(provided) =>
                        <tr {...provided.draggableProps} ref={provided.innerRef}>
                          <td {...provided.dragHandleProps}><Icon type={IconType.DRAG}/></td>
                          <td onClick={() => setModifierToEdit(mod)}>{mod.visible_name || mod.name || mod.tpv_name}</td>
                          <td onClick={e => e.stopPropagation()}>
                            <ToggleSwitch
                              name='active_switch'
                              checked={mod.visibility !== 0}
                              onChange={() => toggleModVisibility(mod)}
                            />
                          </td>
                          <td>{calculateEuros(mod.tpv_price_cents) + '€'}</td>
                          <td>
                            <Icon type={IconType.PENCIL} onClick={() => setModifierToEdit(mod)}/>
                          </td>
                          {isTpvYumminn &&
                            <td>
                              <Icon type={IconType.TRASH} onClick={() => setModifierToDelete(mod)}/>
                            </td>
                          }
                        </tr>
                      }
                    </Draggable>
                  )}
                  {provided.placeholder}
                </tbody>
              }
            </Droppable>
          </DragDropContext>
          {!modifiers.length &&
            <tr>
              <td colSpan='6'>
                <div>
                  <SquareIcon size={48}>🎛</SquareIcon>
                  <p>
                    <Trans i18nKey='modifiers_empty_state'>
                      <b>Crea modificadores</b> y haz que tus productos sean personalizables
                    </Trans>
                  </p>
                </div>
              </td>
            </tr>
          }
        </table>
      </div>
      {modifierToEdit != null &&
        <EditModifierModal
          modifier={modifierToEdit}
          tpv={tpv}
          closeModal={() => setModifierToEdit(null)}
          onSave={onModSave}
        />
      }
      <DeletePopup
        isOpen={!!modifierToDelete}
        title={'delete_modifier'}
        message={'confirm_delete_modifier'}
        onCancel={() => setModifierToDelete(null)}
        onConfirm={() => deleteMod(modifierToDelete)}
      />
    </section>
  );
};
