import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';

export const BUTTON_VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

export const BUTTON_SIZES = {
  LG: 'large',
  MD: 'medium',
  SM: 'small',
  XS: 'tiny'
};

export const BUTTON_COLORS = {
  DEFAULT: 'default',
  WARNING: 'warning',
  DANGER: 'danger',
};

export const BUTTON_SHAPES = {
  PILL: 'pill',
  CIRCLE: 'circle',
};

export const Button = ({
  label,
  loading,
  onClick,
  disabledOnClick, // useful for toasts
  leftIcon,
  rightIcon,
  className = '',
  variant = BUTTON_VARIANTS.PRIMARY,
  size = BUTTON_SIZES.MD,
  color = BUTTON_COLORS.DEFAULT,
  shape = BUTTON_SHAPES.PILL,
  style,
  ...buttonProps
}) => {
  const { t } = useTranslation();

  const handleClick = (buttonProps.disabled || loading) ? disabledOnClick : onClick;

  return (
    <StyledButton
      {...buttonProps}
      onClick={handleClick}
      style={style}
      className={`${variant} ${size} ${color} ${shape} ${loading ? 'loading' : ''} ${className}`}
    >
      {leftIcon}
      {typeof label === 'string' ? <span className={className.includes('px') ? className : ''}>{t(label)}</span> : label}
      {rightIcon}
      {loading &&
        <Spinner className='spinner'/>
      }
    </StyledButton>
  );
};

const StyledButton = styled.button`
  &.default{
    --color: #094553;
    --contrast: #FFFFFF;

    --hover_text_color_primary: var(--color);
    --hover_bg_color_primary: #D4F9EE;
    --hover_border_color_primary: var(--color);

    --hover_text_color_secondary: var(--color);
    --hover_bg_color_secondary: #F3FFF9;
    --hover_border_color_secondary: #4ED9B7;
  }

  &.warning{
    --color: #F8DE6F;
    --contrast: #212121;

    --hover_text_color_primary: var(--contrast);
    --hover_bg_color_primary: #FFF4DD;
    --hover_border_color_primary: var(--contrast);

    --hover_text_color_secondary: var(--contrast);
    --hover_bg_color_secondary: #FFFAEF;
    --hover_border_color_secondary: var(--color);
  }

  &.danger{
    --color: #E35656;
    --contrast: #FFFFFF;

    --hover_text_color_primary: var(--color);
    --hover_bg_color_primary: #fde0df;
    --hover_border_color_primary: var(--color);

    --hover_text_color_secondary: var(--color);
    --hover_bg_color_secondary: #ffece9;
    --hover_border_color_secondary: #F3779E;
  }
  
  &.primary {
    --text_color: var(--contrast);
    --bg_color: var(--color);
    --disabled_color: #FFFFFF;
    --disabled_bg_color: #AAAAAA;
    --hover_text_color: var(--hover_text_color_primary);
    --hover_bg_color: var(--hover_bg_color_primary);
    --hover_border_color: var(--hover_border_color_primary);
  }

  &.secondary {
    --text_color: var(--hover_text_color);
    --bg_color: transparent;
    --disabled_color: #AAAAAA;
    --disabled_bg_color: transparent;
    --hover_text_color: var(--hover_text_color_secondary);
    --hover_bg_color: var(--hover_bg_color_secondary);
    --hover_border_color: var(--hover_border_color_secondary);
  }

  &.large {
    --font_size: 1.125rem;
    --line_height: 1.22em;
    --padding: 0 2.875rem;
    --height: 3rem;
  }
  
  &.medium {
    --font_size: 1rem;
    --line_height: 1.25em;
    --padding: 0 2rem;
    --height: 2.75rem;
  }
  
  &.small {
    --font_size: 0.875rem;
    --line_height: 1.125em;
    --padding: 0 1rem;
    --height: 2.125rem;
  }

  &.tiny {
    --font_size: 8px;
    --line_height: 1rem;
    --padding: 0 1rem;
    --height: 1.5rem;
  }

  &.pill {
    --width: unset;
  }
  
  &.circle {
    --width: var(--height);
    --padding: 0;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  font-family: Poppins, sans-serif;
  font-size: var(--font_size);
  font-weight: 600;
  border: 1px solid var(--color);
  border-radius: 99px;
  color: var(--text_color);
  background-color: var(--bg_color);
  height: var(--height);
  width: var(--width);
  min-width: var(--width);
  max-width: var(--width);
  padding: var(--padding);
  position: relative;
  transition: 0.15s ease-in-out;
  transition-property: color, background-color, border-color, translate, box-shadow;

  & > span {
    line-height: 1;
    
    &::first-letter {
      text-transform: capitalize;
    }
  }

  & > svg {
    height: 1.25em;
    width: 1.25em;
    margin: calc((-1.25em + var(--line_height)) / 2) 0;
    flex-shrink: 0;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 2px solid var(--color);
    outline-offset: 2px;
  }

  &:not(.loading, :disabled):hover, &:focus-visible {
    color: var(--hover_text_color);
    background-color: var(--hover_bg_color);
    border-color: var(--hover_border_color);
    translate: 0 -2px;
    box-shadow: 0 2px 0 #0002;
  }

  &:not(.loading, :disabled):active {
    translate: 0 2px;
    box-shadow: 0 0 0 #0002, inset 0 2px 0 #0002;
  }

  &:disabled {
    color: var(--disabled_color);
    background-color: var(--disabled_bg_color);
    border-color: #AAAAAA;
  }

  &.loading {
    cursor: wait;

    & > *:not(.spinner) {
      opacity: 0.25;
    }
  }
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  position: absolute;
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  flex-shrink: 0;
  margin: calc((-1.5em + var(--line_height)) / 2) 0;
  border-radius: 9999px;
  border: min(4px, 0.25em) solid;
  border-color: #0004;
  border-top-color:  currentColor;
  animation: ${spin} 1s linear infinite;
  -webkit-animation: ${spin} 1s linear infinite;
`;