import React, { forwardRef, useRef } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useState } from "react";
import { XIcon } from "@heroicons/react/outline";
import { setDate, getDefaultDate } from './EventApi';
import DatePicker from "react-datepicker";
import './customDatePickerStyle.css';
import { EventImage } from "./EventImage";
import { getImageUrl } from 'logic/functions';

export const EventSpecs = ({
  eventDraft,
  editEvent
}) => {
  const { t } = useTranslation();

  const [name, setName] = useState(eventDraft.name);
  const [description, setDescription] = useState(eventDraft.description);
  const [charCount, setCharCount] = useState(description ? description?.length : 0);
  const [location, setLocation] = useState(eventDraft.location);

  const maxCharCount = 150;

  const [startDate, setStartDate] = useState(
    setDate(eventDraft.datetime) || getDefaultDate()
  )

  const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input input font-medium input-bordered rounded-[10px] h-12 !w-full border-2 border-[#D4D4D8] placeholder-gray-400"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const handleDescriptionChange = (description) => {
    if (description.length <= maxCharCount) {
      setDescription(description);
      setCharCount(description.length);
      editEvent({ description });
    }
  };

  const handleLocationChange = (location) => {
    setLocation(location);
    editEvent({ location });
  };

  const handleNameChange = (name) => {
    setName(name);
    editEvent({ name });
  };

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    // Trigger the file input dialog
    fileInputRef.current.click();
  };

  const handleFileSelect = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const imageUrl = URL.createObjectURL(selectedFile);
      editEvent({ image: imageUrl, imageFile: selectedFile });
    } 
  };

  const saveImage = () => {
    const imageUrl = getImageUrl(eventDraft.image);
    window.open(imageUrl, '_blank');
  };

  const deleteImage = () => {
    editEvent({ image: "", imageFile: null });
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };
  

  return (
    <div className="flex gap-8 w-full mb-4">
    
      <div className="cursor-pointer flex flex-col items-center mt-4 gap-6 pl-14">

        <div className="flex flex-col justify-between gap-2 items-start w-full text-base ">
          <label className="whitespace-nowrap" htmlFor="name">
            {t("name of the event")}
          </label>
          <input
            id="name"
            className="input font-medium rounded-[10px] input-bordered h-14 w-full border-2 border-[#D4D4D8] placeholder-gray-400"
            value={name}
            placeholder={eventDraft.name || t('Event')}
            onChange={(e) => handleNameChange(e.target.value)}
          />
        </div>

        <div colSpan={1} className='bg-[#EDEDED] rounded-3xl relative min-w-[300px] min-h-[230px] items-center flex flex-col shadow mb-4' onClick={handleButtonClick}>
     
          <EventImage object={eventDraft} type='event'/>

          <div className="flex flex-row gap-4 items-center mt-2 absolute right-4">
            
            <div
              id="download button"
              className="cursor-pointer bg-[#FFF] rounded-full p-3 hover:text-principal"
              onClick={saveImage}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                />
              </svg>
            </div>
            <button
              type='button'
              onClick={deleteImage}
              className='bg-[#FFF] p-2 rounded-full'
            >
              <XIcon className='h-8 w-8 text-[#212121] hover:text-principal' />
            </button>

          </div>

          {/* HIDDEN INPUT NEEDED FOR IMAGE SELECT DIALOGUE */}
          <input
            type="file"
            accept="image/*" // Limit to image files
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleFileSelect}
          />

        </div>
        {/*HIDDEN INPUT NEEDED FOR IMAGE SELECT DIALOGUE */}
        <div style={{textAlign: 'center', padding: "0 20px"}}>
          <Trans className='text-center' i18nkey='photo_suggestions'>
            <b>Sugerencias:</b> png/jpg o gif de 2MB máx de peso.
          </Trans> 
        </div>

      </div>


      <div className="flex flex-col gap-4 w-full py-4 px-14">

        <div className="flex flex-col justify-between gap-2 items-start text-base">
          <label className="whitespace-nowrap" htmlFor="address">
            {t("Address")}
          </label>
          <input
            id="address"
            className="input font-medium rounded-[10px] input-bordered h-14 w-full border-2 border-[#D4D4D8] placeholder-gray-400"
            value={location}
            placeholder={eventDraft.location || t('Address')}
            onChange={(e) => handleLocationChange(e.target.value)}
          />
        </div>
        
        <div className="flex flex-col justify-between gap-2 items-start text-base">
          <label className="whitespace-nowrap" htmlFor="period">
            {t("Date and hour")}
          </label>
          <DatePicker
            selected={startDate}
            onChange={(datetime) => {setStartDate(datetime); editEvent({ datetime })}}
            customInput={<CustomInput />}
            timeIntervals={15}
            showTimeSelect
            minDate={new Date()}
            showIcon
            filterTime={filterPassedTime}
            dateFormat="dd/MM/yy h:mm aa"
          />
        </div>

        <div className="flex flex-col justify-between gap-2 items-start w-full">
          <label
            className="whitespace-nowrap self-start mt-2 text-base"
            htmlFor="description"
          >
            {t("Description")} ({t('optional')})
          </label>
          <textarea
            id="description"
            className="input input-bordered rounded-[10px] border-2 border-[#D4D4D8] font-medium w-full h-[111px]"
            value={description}
            placeholder={eventDraft.description || t('write here')}
            onChange={(e) => handleDescriptionChange(e.target.value)}
          ></textarea>
          <div className="self-end text-sm text-gray-500">
            {charCount}/{maxCharCount}
          </div>
        </div>

      </div>
    </div>
  );
};
