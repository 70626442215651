import styled from "styled-components";

export const InputContainer = styled.div`
  position: relative;
  
  & > label {
    position: relative;
    width: 100%;
  }

  h6 {
    font-size: 1rem;
    font-weight: 300;
    font-weight: 400;
    margin: 0 0 8px;

    &::first-letter {
      text-transform: capitalize;
    }

    &::first-letter {
      text-transform: capitalize;
    }
  }

  .error {
    font-size: 0.75rem;
    line-height: 1;
    color: #C43434;
    display: flex;
    margin: 4px 0 0;
  }

  .input_wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 56px;
    width: 100%;
    font-size: 1rem;
    color: #404040;
    background-color: #FFFFFF;
    border: 2px solid #DDDDDD;
    border-radius: 10px;
    padding: 8px 12px;
    box-sizing: border-box;

    transition: border-color 0.15s ease-in-out;

    & > * {
      flex-shrink: 0;
    }

    &:hover:not(:has(:disabled)), &:hover:not(:has(:read-only)) {
      border-color: #AFDCCA;
    }
  }

  &.select_input:not(:has(:disabled)) div:hover, &.select_input:not(:has(:read-only)) div:hover {
    border-color: #AFDCCA;
  }

  &:has(> .error) .input_wrapper, &.select_input:has(> .error) div, &:has(> .error) textarea {
    border-color: #C43434 !important;
  }

  &:has(input:not(:read-only):focus) .input_wrapper {
    outline: 2px solid #094553;
    outline-offset: 2px;
  }

  &:has(:disabled) .input_wrapper {
    color: #40404090;
    box-shadow: none;
    opacity: 0.65;
    filter: brightness(0.95);
  }
  
  input {
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    color: currentColor;
    background-color: transparent;
    border: none;
    height: 100%;
    margin: 0;
    padding: 0;
    flex: 1;
    width: 0;
    
    &::placeholder {
      color: #40404080;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &[type=number], &[type=tel] {
      text-align: right;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
    }
  }

  textarea {
    display: flex;
    width: 100%;
    color: #404040;
    background-color: #FFFFFF;
    border: 2px solid #DDDDDD;
    border-radius: 10px;
    padding: 8px 12px;
    box-sizing: border-box;
    resize: none;

    &::placeholder {
      color: #40404080;
    }

    &:hover:not(:has(:disabled)), &:hover:not(:has(:read-only)) {
      border-color: #AFDCCA;
    }

    &:focus {
      border-color: #DDDDDD!important;
      outline: 2px solid #094553;
      outline-offset: 2px;
      box-shadow: none;
    }

    &:disabled {
      color: #40404090;
      box-shadow: none;
      opacity: 0.65;
      filter: brightness(0.95);
    }
  }

  button, .euros {
    display: flex;
    justify-content: center;
    align-items: center;
    color: currentColor;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    flex-shrink: 0;
  }

  .date_input {
    //rsuite uses important everywhere 😭
    background-color: #FFFFFF !important;
    border-radius: 10px !important;

    & > div {
      padding: 0!important;
      background-color: transparent !important;
      box-shadow: none !important;
    }

    .rs-stack-item {
      font-size: 1rem;

      & > svg {
        position: static;
        height: 1.5rem;
        width: auto;
        color: #094553;
      }

      & > span {
        position: static;

        & > svg {
          height: 1rem;
          width: 1rem;
        }
      }
    }

    &:hover:not(:has(:disabled)), &:hover:not(:has(:read-only)) {
      border-color: #AFDCCA !important;
    }

    &:hover:not(:has(:disabled)), &:hover:not(:has(:read-only)) {
      border-color: #AFDCCA !important;
    }
  }
`;