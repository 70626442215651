import React from 'react';
import { centsToAmountString } from 'logic/functions';
import { useTranslation } from 'react-i18next';

const WalletUserRow = ({topup, selected, isNotOpened, field}) => {

  const { t } = useTranslation();
  
  return (
    <div style={{border: selected ? "2px solid #5FB894" : "2px solid #FCFCFC", display: 'grid', gridTemplateColumns: "1.25fr 1fr 1fr .5fr 1fr .75fr", textAlign: 'center', width: "100%", backgroundColor: selected ? "#EEF9F5" : isNotOpened ? "#FFF7E2" : "#FCFCFC", justifyContent: 'space-around', fontSize: "16px", fontWeight: 500, alignItems: 'center', borderRadius: '15px'}}>
		<div style={{minWidth: "240px", padding: "10px 0px"}} className='flex flex-col items-center'>
			<div>{topup.payment__client_name}</div>
			<div className='font-semibold'>{topup.email}</div>
		</div>
		<div style={{padding: "10px 0px", minHeight: "80px", alignContent: 'center'}} className={`${field === 'client_credit__balance' ? 'font-bold bg-[#F9F9F9]' : ''}`}>{centsToAmountString(topup.client_credit__balance) + ' €'}</div>
		<div style={{padding: "10px 0px", minHeight: "80px", alignContent: 'center'}} className={`${field === 'total_incentive' ? 'font-bold bg-[#F9F9F9]' : ''}`}>{centsToAmountString(topup.total_incentive) + ' €'}</div>
		<div style={{padding: "10px 0px"}}>{topup.number_of_recharges}</div>
		<div style={{padding: "10px 0px"}} className='flex flex-col items-center'>
			<div className='font-bold'>{`ID: YR-${topup.last_recharge_id}`}</div>
			<span style={{backgroundColor: selected ? "#094553" : "#EEF9F5", color: selected ? "#FFFFFF" : "#404040", border: selected ? "none" : "1px solid #AFDCCA", padding: '5px 10px', borderRadius: "99999px", marginLeft: "8px", whiteSpace: 'nowrap'}}>{t('see_history')}</span>
		</div>
		
		<div style={{padding: "10px 0px"}}>{topup.orders_with_credit}</div>
	</div>

  );
};
export default WalletUserRow;