import Eye_icon from "../img/Eye_icon.svg";
import OfficeBuilding_icon from "../img/OfficeBuilding_icon.svg";
import Home_icon from "../img/Home_icon.svg";
import InboxIn_icon from "../img/InboxIn_icon.svg";
import { LogoutIcon } from '@heroicons/react/outline';
import Catalog_icon from "../img/Catalog_icon.svg";
import { routes } from "./AdminRoutes";
import { Icon, IconType } from "common/Icon";
import access_icon from "../img/access_icon.svg";


export const admin_routes = (t, tabs) => [
    ...routes(t, tabs),
    {
        icon:  <img src={Eye_icon} className='h-6 w-6' alt='agents'/>,
        title: t('Agents'),
        path: '/agents'
    },
    {
        icon:  <img src={OfficeBuilding_icon} className='h-6 w-6 stroke-1' alt='companies'/>,
        title: t('Companies'),
        path: '/admin/companies'
    },
    {
        icon:  <img src={Home_icon} className='h-6 w-6 stroke-1' alt='restaurants'/>,
        title: t('Restaurants'),
        path: '/admin/restaurants'
    },
    {
        icon:  <img src={InboxIn_icon} className='h-6 w-6 stroke-1' alt='sales'/>,
        title: t('Sales'),
        path: '/admin/sales'
    },
];


export const bottomRoutes = ({ handleLogout, t }) => [
    {
        icon: <img src={access_icon} className="h-6 w-6" alt="access"/>,
        title: t('access'),
        children: [
            {
                title: t('email_list'),
                path: '/access/email_list',
                route_group: 'access',
            },
            {
                title: t('domain_list'),
                path: '/access/domain_list',
                route_group: 'access',
            },
        ],
    },
    {
        icon: <img src={Catalog_icon} className="h-6 w-6" alt='catalog'/>,
        title: t('Restaurant'),
        path: '/settings'
    },
    {
        icon: <Icon type={IconType.VIDEO}/>,
        title: t('tutorials'),
        path: '/tutorials',
    },
    {
        icon: <LogoutIcon className="h-6 w-6" />,
        title: t('Logout'),
        path: '/logout',
        onClick: () => { handleLogout() }
    },
]
